import { render, staticRenderFns } from "./RevokePropositionDialog.vue?vue&type=template&id=38f3a07c&scoped=true&v-if=showRevokeDialog"
import script from "./RevokePropositionDialog.vue?vue&type=script&lang=ts"
export * from "./RevokePropositionDialog.vue?vue&type=script&lang=ts"
import style0 from "./RevokePropositionDialog.vue?vue&type=style&index=0&id=38f3a07c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f3a07c",
  null
  
)

export default component.exports