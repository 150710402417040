import * as serviceModule from '@/store/serviceModule'
import * as snackbarModule from '@/store/snackbarModule'
import { Module } from 'vuex'
import IState from '@/store/IState'
import IServiceState from '@/store/serviceModule/IServiceState'
import ISnackbarState from './snackbarModule/ISnackbarState'

type modules = {
  service: Module<IServiceState, IState>
  snackbar: Module<ISnackbarState, IState>
}

export function getModules () : modules {
  return {
    service: serviceModule.module(),
    snackbar: snackbarModule.module()
  }
}

export const NAMESPACES = {
  service: serviceModule.NAMESPACE,
  snackbar: snackbarModule.NAMESPACE
}

export const ACTIONS = {
  service: serviceModule.ACTIONS,
  snackbar: snackbarModule.ACTIONS
}

export const MUTATIONS = {
  service: serviceModule.MUTATIONS,
  snackbar: snackbarModule.MUTATIONS
}

export const STATE = {
  service: serviceModule.STATE,
  snackbar: snackbarModule.STATE
}
