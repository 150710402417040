<template>
  <div>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h1>{{ $t('waitingListPropositionAnalysis.placementPolicy.header') }}</h1>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="header-content">
          <div class="placement-policy">
            <span>{{ $t('waitingListPropositionAnalysis.placementPolicy.description') }}</span>
            <div class="percentages">
              <span class="percentage" v-if="hasFlexiblePlacementPolicy()">{{ $t('waitingListPropositionAnalysis.placementPolicy.flexiblePercentage', { percentageFrom:0, percentageUpToAndIncluding: placementPolicy.flexiblePolicyPercentageUpToAndIncluding }) }}</span>
              <span class="percentage" v-if="hasModeratePlacementPolicy()">{{ $t('waitingListPropositionAnalysis.placementPolicy.moderatePercentage', { percentageFrom: placementPolicy.flexiblePolicyPercentageUpToAndIncluding + 1, percentageUpToAndIncluding: placementPolicy.moderatePolicyPercentageUpToAndIncluding }) }}</span>
              <span class="percentage no-margin-right">{{ $t('waitingListPropositionAnalysis.placementPolicy.strictPercentage', { percentageFrom: getStrictPolicyPercentageFrom(), percentageUpToAndIncluding: 100 }) }}</span>
            </div>
          </div>
          <div class="toddler-group">
            <v-icon>$vuetify.icons.partouInfo</v-icon>
            <span>{{ $t('waitingListPropositionAnalysis.placementPolicy.toddlerGroup') }}</span>
          </div>
          <v-expansion-panel class="placement-policy-row" v-for="(occupancyPercentageAveragesForGroup, groupName) in occupancyPercentageAveragesPerGroup" :key="`${groupName}`">
            <v-expansion-panel-header class="placement-policy-header" :hide-actions="false" :readonly="false">
              <div class="indicator">{{groupName}}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="placement-policy-content">
              <div class="divider"></div>
              <v-row class="day-of-week-row" v-for="orderedDay in orderedDays" :key="`${groupName}-${orderedDay}`">
                <v-col cols="2">
                  <span>{{$t(`days.${orderedDay.toLowerCase()}`)}}</span>
                </v-col>
                <v-col cols="2">
                  <span>{{getPlacementPolicyForGroupAndDayOfWeek(occupancyPercentageAveragesForGroup, orderedDay)}}</span>
                </v-col>
                <v-col cols="2">
                  <span>{{getPercentageForGroupAndDayOfWeek(occupancyPercentageAveragesForGroup, orderedDay)}}%</span>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { DayOfWeek } from '@/models'
import { GroupOccupancyPercentageAverage, PlacementPolicy } from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: { PartouCard, DaysCoverage }
})
export default class WaitingListPropositionAnalysisResultPlacementPolicyTable extends Vue {
  @Prop({ required: true })
  placementPolicy!: PlacementPolicy

  @Prop({ required: true })
  // If Map<string, GroupOccupancyPercentageAverage> is used an runtime error occurs
  occupancyPercentageAveragesPerGroup!: any // eslint-disable-line @typescript-eslint/no-explicit-any

  orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

  getPlacementPolicyForGroupAndDayOfWeek (groupOccupancyPercentageAverage: Array<GroupOccupancyPercentageAverage>, dayOfWeek: DayOfWeek): string {
    const occupancyPercentageAverage = groupOccupancyPercentageAverage.find(x => x.dayOfWeek === dayOfWeek)
    if (!occupancyPercentageAverage || !this.placementPolicy) {
      return '-'
    }

    if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding && occupancyPercentageAverage.percentage <= this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding) {
      return this.$t('pages.ServiceOffer.placementPolicy.flexible').toString()
    }

    if (this.placementPolicy.isModeratePolicyEnabled && this.placementPolicy.moderatePolicyPercentageUpToAndIncluding && occupancyPercentageAverage.percentage <= this.placementPolicy.moderatePolicyPercentageUpToAndIncluding) {
      return this.$t('pages.ServiceOffer.placementPolicy.moderate').toString()
    }

    return this.$t('pages.ServiceOffer.placementPolicy.strict').toString()
  }

  getPercentageForGroupAndDayOfWeek (groupOccupancyPercentageAverage: Array<GroupOccupancyPercentageAverage>, dayOfWeek: DayOfWeek): string {
    const occupancyPercentageAverage = groupOccupancyPercentageAverage.find(x => x.dayOfWeek === dayOfWeek)
    if (!occupancyPercentageAverage) {
      return '-'
    }

    return occupancyPercentageAverage.percentage.toString()
  }

  hasFlexiblePlacementPolicy (): boolean {
    return this.placementPolicy.isModeratePolicyEnabled || this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding !== undefined
  }

  hasModeratePlacementPolicy (): boolean {
    return this.placementPolicy.isModeratePolicyEnabled && this.placementPolicy.moderatePolicyPercentageUpToAndIncluding !== undefined
  }

  getStrictPolicyPercentageFrom (): number {
    if (this.placementPolicy.isModeratePolicyEnabled) {
      if (this.placementPolicy.moderatePolicyPercentageUpToAndIncluding) {
        return this.placementPolicy.moderatePolicyPercentageUpToAndIncluding + 1
      }
      return 0
    } else if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding || this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding === 0) {
      return this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding + 1
    }

    return 0
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .divider {
    width: 100%;
    height: 1px;
    background-color: $partou-primary-salmon-sixty;
  }

  .header-content {
    .placement-policy {
      background-color: $partou-primary-backdrop-white;
      padding: 8px 16px;
      display: table;
      border-radius: 4px;
      margin-bottom: 8px;

      .percentages {
        .percentage {
          border-radius: 4px;
          background-color: $partou-secondary-blue-fourty;
          padding: 0 8px;
          margin-right: 16px;
          font-size: 14px;

          &.no-margin-right {
            margin-right: 0;
          }
        }
      }
    }

    .toddler-group {
      color: $partou-primary-black-seventy;
      fill: $partou-primary-black-seventy;
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .placement-policy-content {
    padding: 0 10px 10px 10px;
    box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);
  }
</style>
