<template>
  <div>
    <PartouCard class="setting-card-container">
        <template slot="card-body">
            <p class="setting-title black-ninety">{{actionTitle}}</p>
            <p v-if="actionInformation" class="setting-information">{{actionInformation}}</p>
            <PartouButton class="setting-button" ref="setting-button" @click="onClick" :loading="isLoading" :disabled="isLoading" :text="buttonText" colour="partou-black-ninety"></PartouButton>
        </template>
    </PartouCard>
    <div class="error-container">
      <p class="error-message" ref="error-message" v-if="errorMessage">{{errorMessage}}</p>
    </div>
  </div>
  </template>

<script lang="ts">
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component({
  components: { PartouCard, PartouButton }
})
export default class SettingCard extends Vue {
  @Prop({ required: true })
  actionTitle!: string

  @Prop({ required: false })
  actionInformation?: string

  @Prop({ required: true })
  buttonText!: string

  @Prop({ required: false })
  errorMessage?: string

  @Prop({ required: true })
  isLoading!: boolean

  @Emit('action')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick () : void {}
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

.setting-card-container {
  width: 365px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px !important;
  }
}

.setting-button {
  width: 100%;

  button {
    border: 1px solid $partou-primary-black !important;
    width: 100%;

    .v-btn__content > span {
      font-family: $heading-font-family !important;
      color: $partou-primary-black !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.setting-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 20.7px;
  font-family: $heading-font-family;
  color: #000000 !important;
}

.setting-information {
  line-height: 16.1px;
}

.error-container {
  width: 100%;
  text-align: center;
  margin-top: 12px;
  height: 16px;
}

.error-message {
  color: $partou-primary-salmon !important;
  line-height: 12.65px;
}

.v-btn__content > span {
  font-family: $heading-font-family !important;
  color: $partou-primary-black !important;
}
</style>
