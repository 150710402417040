<template>
  <v-simple-table v-if="value.length > 0">
    <template v-slot:default>
      <thead>
        <th id="childNameHeader" width="156px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.childName') }}</th>
        <th id="childNumberHeader" width="48px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.childNumber') }}</th>
        <th id="dateOfBirthHeader" width="48px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.dateOfBirth') }}</th>
        <th id="morningHeader" width="24px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.morning') }}</th>
        <th id="afternoonHeader" width="24px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.afternoon') }}</th>
      </thead>
      <tbody>
        <tr v-for="child in orderedItems" :key="child.id">
          <td class="text-left">{{ getChildName(child) }}</td>
          <td class="text-left">{{ child.number }}</td>
          <td class="text-left">{{ parseDateOfBirth(child.dateOfBirth) }}</td>
          <td><v-icon v-if="child.morning" class="partou-check-icon">$vuetify.icons.partouCheck</v-icon></td>
          <td><v-icon v-if="child.afternoon" class="partou-check-icon">$vuetify.icons.partouCheck</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <p v-else>{{ $t('pages.ServiceOffer.childrenTable.noReservations') }}</p>
</template>

<script lang="ts">
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ServiceOfferChildTableItem from './ServiceOfferChildTableItem'

@Component({
  components: { PartouCard, PartouCheckBox }
})
export default class ServiceOfferChildrenTable extends Vue {
  @Prop({ required: true })
  value!: ServiceOfferChildTableItem[]

  get orderedItems () : ServiceOfferChildTableItem[] {
    return this.value.sort(function (a, b) {
      const firstNameA = a.firstName ?? ''
      const firstNameB = b.firstName ?? ''

      if (firstNameA < firstNameB) { return -1 }
      if (firstNameA > firstNameB) { return 1 }
      return 0
    })
  }

  getChildName (rowData: ServiceOfferChildTableItem) : string {
    let name = `${rowData.firstName} ${rowData.lastName}`
    if (rowData.reservedSeat) {
      name += ` (${this.$t('pages.ServiceOffer.childrenTable.reservedSeat')})`
    }
    return name
  }

  parseDateOfBirth (dateOfBirth : Date) : string {
    return moment(dateOfBirth).format('DD-MM-YYYY')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

.partou-check-icon::v-deep {
  path {
    stroke-width: 4;
  }
}

.v-data-table::v-deep {
  .v-data-table__wrapper {
     table {
      border-collapse: collapse;
     }
  }
}

th {
  height: 40px;
  font-size: 14px;
  font-weight: 500px;
  color: $partou-primary-black-ninety;
  border-bottom: 1px solid rgba(188, 188, 187, 0.35);

  &:first-child {
    padding-left: 8px;
  }

  &:last-child {
    padding-right: 8px;
  }
}

tr {
  height: 40px;
  &:last-child {
    border-bottom: 1px solid rgba(188, 188, 187, 0.35);
  }

  td {
    padding: 0 !important;
    height: 40px !important;
    font-size: 14px;

    &:first-child {
      padding-left: 8px !important;
    }

    &:last-child {
      padding-right: 8px !important;
    }
  }
}
</style>
