<template>
  <div>
    <v-dialog
      content-class="partou-notify rounded-xl"
      class="rounded-xl"
      @click:outside="onNotifyCancel"
      @keydown.esc="onNotifyCancel"
      :value="showDialog"
      :persistent="persistent"
      :width="width"
      :scrollable="scrollable">
      <v-card class="pa-8 rounded-xl">
        <PartouFloatingCircleButton ref="close-button" class="close-button" iconName="$vuetify.icons.partouClose" :isDisabled="persistent" @click="onNotifyCancel" />
        <div :class=" { 'scroll': scrollable }" class="content">
          <slot />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'

@Component({
  components: { PartouFloatingCircleButton }
})
export default class PartouNotify extends Vue {
  @Prop({ required: false, default: false })
  persistent! : boolean

  @Prop({ required: false, default: false })
  scrollable! : boolean

  @Prop({ required: true, default: false })
  showDialog! : boolean

  @Prop({ required: false, default: 306 })
  width! : number

  onNotifyCancel () : void {
    if (!this.persistent) {
      this.$emit('canceled')
    }
  }
}
</script>

<style lang="scss">
.partou-notify {
  overflow-y: visible !important;
  max-width: calc(100vw - 64px);

  .content {
    overflow: visible !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    max-height: calc(100vh - 80px);

    &.scroll {
      overflow-y: auto !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/index.scss';
@import '@/styles/variables/variables.scss';

.close-button {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 32px;
  top: -16px;
  button span svg {
    path {
      fill: $partou-primary-black-ninety !important;
      color: $partou-primary-black-ninety !important;
    }
  }
}
</style>
