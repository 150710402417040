<template>
  <div class="priority-data d-flex data-component">
    <template v-if="currentProposition">
      <template v-if="currentProposition.locationInquiry">
        <div class="flex-column priority-data-item">
          <p class="data-labels priority-label">{{ $t('waitingListPropositions.table.expanded.priority') }}</p>
          <p class="mb-8"><b>{{ getPriorityText(currentProposition) }}</b></p>
        </div>
        <div v-if="currentProposition.proposedOn !== null" class="flex-column priority-data-item">
          <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.proposedOn') }}</p>
            <p>{{ getFormattedDate(currentProposition.proposedOn) }}</p>
        </div>
        <div v-if="currentProposition.expiresOn !== null" class="flex-column priority-data-item">
          <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.expiresOn') }}</p>
          <div class="d-flex">
            <p class="mb-8">{{ getFormattedDate(currentProposition.expiresOn) }}</p>
            <v-icon v-if="isWaitingListPropositionExtendedComputed" class="ml-2">$vuetify.icons.partouPropositionExtended</v-icon>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PriorityOption } from '@/pages/ServicesPage/WaitingList/PriorityOption'
import { WaitingListProposition } from '@/models'
import { getFormattedDate } from '@/utils/dateUtils'
import { isWaitingListPropositionExtended } from '@/utils/waitingListPropositionUtils'

@Component({})
export default class PriorityInfo extends Vue {
  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  @Prop({ required: true })
  possiblePriorities!: PriorityOption[]

  get isWaitingListPropositionExtendedComputed () : boolean {
    return isWaitingListPropositionExtended(this.currentProposition)
  }

  getPriorityText (selectedProposition: WaitingListProposition): string {
    const priorityValue = selectedProposition.locationInquiry.priority
    return this.possiblePriorities.find(priorityOption => priorityOption.value === priorityValue)?.text ?? this.$t('services.waitingList.unknown').toString()
  }

  getFormattedDate (date: Date, withTime = false): string {
    return getFormattedDate(date, withTime)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.priority-data-item {
  margin-right: 16px;
}

.priority-label {
  color: $partou-primary-black-ninety;
}

.v-icon--is-component {
    height: 14px;
     width: 14px;
}

</style>
