<template v-slot:header>
  <PartouCard :hide-actions="true" class="mt-2 full-width">
    <template v-slot:card-body>
      <div class="activations">
        <h1 class="sub-header mb-6">{{ $t('services.serviceActivations.title') }}</h1>
        <v-row>
          <v-col class="mr-1">
            <BenefitCalculatorActivation
              v-model="service"
              @input="onServiceActivationsChanged"
              :isLoading="isLoading"
              class="mb-8"
            />
            <SelectionGuideActivation
              v-model="service"
              @input="onServiceActivationsChanged"
              :isLoading="isLoading"
            />
          </v-col>
          <v-col class="ml-1">
            <WaitingListActivation
              v-model="service"
              @input="onServiceActivationsChanged"
              :isLoading="isLoading"
              :canEnableWaitingList="canEnableWaitingList"
              class="mb-8"
            />
            <OffersAndPlacementsActivation
              v-model="service"
              @input="onServiceActivationsChanged"
              :isLoading="isLoading"
            />
          </v-col>
        </v-row>
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="100"></v-progress-circular>
        </v-overlay>
      </div>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import BenefitCalculatorActivation from './components/BenefitCalculatorActivation.vue'
import WaitingListActivation from './components/WaitingListActivation.vue'
import SelectionGuideActivation from './components/SelectionGuideActivation.vue'
import OffersAndPlacementsActivation from './components/OffersAndPlacementsActivation.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Service } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'

@Component({
  components: { PartouCard, BenefitCalculatorActivation, WaitingListActivation, SelectionGuideActivation, OffersAndPlacementsActivation }
})
export default class ServiceActivations extends Vue {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)

  @Prop({ required: true })
  public entityId!: string

  service?: Service | null = null
  isLoading = false
  useFlexkidsProducts = false
  canEnableWaitingList = false

  @Watch('entityId', { immediate: true })
  async onEntityIdChangedAsync () : Promise<void> {
    this.isLoading = true
    this.service = await this.serviceService.getActivationInformationByServiceIdAsync(this.entityId, new Date())

    if (!this.service) {
      this.isLoading = false
      return
    }

    if (!this.useFlexkidsProducts) {
      try {
        this.canEnableWaitingList = (await this.serviceService.canEnableWaitingList(this.service)).result
      } catch (err) {
        this.canEnableWaitingList = false
      }
    }

    this.isLoading = false
  }

  async onServiceActivationsChanged (newService: Service): Promise<void> {
    this.service = { ...newService }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.activations {
  margin: 16px;
}

h1.sub-header {
  font-size: 24px;
}

.v-overlay {
  border-radius: 24px;
}
</style>
