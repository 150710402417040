import { GetServiceSettingsByServiceIdDocument, GetServiceSettingsByServiceIdQuery, ServiceSettings, SetPlanningHorizonByServiceIdDocument, SetPlanningHorizonByServiceIdMutation, SetWaitingListActualisationIntervalsMutation, SetWaitingListActualisationIntervalsDocument, SetUseFlexkidsProductsByServiceIdMutation, SetUseFlexkidsProductsByServiceIdDocument, SetShowLinkToPartouOffersDocument, SetShowLinkToPartouOffersMutation, SetOpeningTimesNoteByServiceIdDocument, SetOpeningTimesNoteByServiceIdMutation, GetServiceSettingsForServiceDetailPageByServiceIdDocument, GetServiceSettingsForServiceDetailPageByServiceIdQuery, SetAllowDirectPlacementByServiceIdMutation, SetAllowDirectPlacementByServiceIdDocument, SetAvailableInCalculatorByServiceIdMutation, SetAvailableInCalculatorByServiceIdDocument, SetVisibleInSelectionGuideByServiceIdMutation, SetVisibleInSelectionGuideByServiceIdDocument, SetBookableInSelectionGuideByServiceIdMutation, SetBookableInSelectionGuideByServiceIdDocument, SetWaitingListValidationByServiceIdMutation, SetWaitingListValidationByServiceIdDocument, SetPlacementPolicyByServiceIdMutation, SetPlacementPolicyByServiceIdDocument } from '@/models'
import { ApolloClient } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IServiceSettingsService } from './IServiceSettingsService'
import Permission from '@/models/enums/Permission'
import PlacementPolicy from '@/models/configuration/PlacementPolicy'

@injectable()
export default class ServiceSettingsService implements IServiceSettingsService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getServiceSettingsByServiceIdAsync (serviceId: string) : Promise<ServiceSettings> {
    const result = await this.apollo.query<GetServiceSettingsByServiceIdQuery>({
      query: GetServiceSettingsByServiceIdDocument,
      variables: { serviceId },
      context: { headers: { 'X-Hasura-Role': Permission.GetSetting } }
    })
    return result?.data?.service_settings[0] as ServiceSettings
  }

  async getServiceSettingsForServiceDetailPageByServiceIdAsync (serviceId: string) : Promise<Partial<ServiceSettings>> {
    const result = await this.apollo.query<GetServiceSettingsForServiceDetailPageByServiceIdQuery>({
      query: GetServiceSettingsForServiceDetailPageByServiceIdDocument,
      variables: { serviceId },
      context: { headers: { 'X-Hasura-Role': Permission.GetService } }
    })
    return result?.data?.service_settings[0] as ServiceSettings
  }

  async setPlanningHorizonByServiceIdAsync (serviceId: string, planningHorizonInMonths: number) : Promise<void> {
    await this.apollo.mutate<SetPlanningHorizonByServiceIdMutation>({
      mutation: SetPlanningHorizonByServiceIdDocument,
      variables: { serviceId, planningHorizonInMonths },
      context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } }
    }).catch(e => console.error(e))
  }

  async setWaitingListActualisationIntervalsAsync (serviceId: string, inquiryExpiresInInMinutes: number, sendRemainOnWaitingListMailInInMinutes: number, sendRemainOnWaitingListReminderMailInInMinutes: number): Promise<void> {
    await this.apollo.mutate<SetWaitingListActualisationIntervalsMutation>({
      mutation: SetWaitingListActualisationIntervalsDocument,
      variables: { serviceId, inquiryExpiresInInMinutes, sendRemainOnWaitingListMailInInMinutes, sendRemainOnWaitingListReminderMailInInMinutes },
      context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } }
    }).catch(e => console.error(e))
  }

  async setUseFlexkidsProductsAsync (serviceId: string, useFlexkidsProducts: boolean) : Promise<void> {
    await this.apollo.mutate<SetUseFlexkidsProductsByServiceIdMutation>({
      mutation: SetUseFlexkidsProductsByServiceIdDocument,
      variables: { serviceId, useFlexkidsProducts },
      context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } }
    }).catch(e => console.error(e))
  }

  async setShowLinkToPartouOffersAsync (serviceId: string, showLinkToPartouOffers: boolean) : Promise<void> {
    await this.apollo.mutate<SetShowLinkToPartouOffersMutation>({
      mutation: SetShowLinkToPartouOffersDocument,
      variables: { serviceId, showLinkToPartouOffers },
      context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } }
    }).catch(e => console.error(e))
  }

  async setOpeningTimesNoteByServiceIdAsync (serviceId: string, note: string) : Promise<void> {
    await this.apollo.mutate<SetOpeningTimesNoteByServiceIdMutation>({
      mutation: SetOpeningTimesNoteByServiceIdDocument,
      variables: { serviceId, note },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceOpeningTimes } }
    }).catch(e => console.error(e))
  }

  async setAllowDirectPlacementAsync (serviceId: string, allowDirectPlacement: boolean): Promise<boolean> {
    const result = await this.apollo.mutate<SetAllowDirectPlacementByServiceIdMutation>({
      mutation: SetAllowDirectPlacementByServiceIdDocument,
      variables: {
        serviceId,
        allowDirectPlacement
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } }
    }).catch(e => console.error(e))

    return !!result
  }

  async setAvailableInCalculatorAsync (serviceId: string, availableInCalculator: boolean): Promise<boolean> {
    const result = await this.apollo.mutate<SetAvailableInCalculatorByServiceIdMutation>({
      mutation: SetAvailableInCalculatorByServiceIdDocument,
      variables: {
        serviceId,
        availableInCalculator
      },
      context: { headers: { 'X-Hasura-Role': Permission.PublishService } }
    }).catch(e => console.error(e))

    return !!result
  }

  async setVisibleInSelectionGuideAsync (serviceId: string, visibleInSelectionGuide: boolean): Promise<boolean> {
    const result = await this.apollo.mutate<SetVisibleInSelectionGuideByServiceIdMutation>({
      mutation: SetVisibleInSelectionGuideByServiceIdDocument,
      variables: {
        serviceId,
        visibleInSelectionGuide
      },
      context: { headers: { 'X-Hasura-Role': Permission.PublishService } }
    }).catch(e => console.error(e))

    return !!result
  }

  async setBookableInSelectionGuideAsync (serviceId: string, bookableInSelectionGuide: boolean): Promise<boolean> {
    const result = await this.apollo.mutate<SetBookableInSelectionGuideByServiceIdMutation>({
      mutation: SetBookableInSelectionGuideByServiceIdDocument,
      variables: {
        serviceId,
        bookableInSelectionGuide
      },
      context: { headers: { 'X-Hasura-Role': Permission.PublishService } }
    }).catch(e => console.error(e))

    return !!result
  }

  async setWaitingListValidationAsync (serviceId: string, waitingListValidation: boolean): Promise<boolean> {
    const result = await this.apollo.mutate<SetWaitingListValidationByServiceIdMutation>({
      mutation: SetWaitingListValidationByServiceIdDocument,
      variables: {
        serviceId,
        waitingListValidation
      },
      context: { headers: { 'X-Hasura-Role': Permission.PublishService } }
    }).catch(e => console.error(e))

    return !!result
  }

  async setPlacementPolicyAsync (serviceId: string, placementPolicy: PlacementPolicy): Promise<void> {
    await this.apollo.mutate<SetPlacementPolicyByServiceIdMutation>({
      mutation: SetPlacementPolicyByServiceIdDocument,
      variables: {
        serviceId,
        isModeratePolicyEnabled: placementPolicy.isModeratePolicyEnabled,
        flexiblePolicyPercentageUpToAndIncluding: placementPolicy.flexiblePolicyPercentageUpToAndIncluding,
        moderatePolicyPercentageUpToAndIncluding: placementPolicy.moderatePolicyPercentageUpToAndIncluding
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageSetting } }
    }).catch(e => console.error(e))
  }
}
