<template>
  <v-row ref="product-audit-sub-filters">
    <v-col class="flex-grow-0">
      <span class="ml-6 autocomplete-label">{{$t('pages.audit.filters.product.changeType.header')}}</span>
      <PartouAutocomplete
        class="filter-dropdown filter-dropdown-auto-grow borderless"
        :items="changeTypeFilterItems"
        :value="changeType && $t(`pages.audit.filters.product.changeType.filter${changeType}`)"
        :tooltip="changeType && $t(`pages.audit.filters.product.changeType.filter${changeType}`)"
        item-text="name"
        item-value="id"
        label="name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="true"
        :placeholder="$t('pages.audit.filters.product.changeType.placeholder')"
        @input="onChangeChangeTypeFilter"
      />
    </v-col>
    <v-col class="pl-0 flex-grow-0">
      <span class="ml-6 autocomplete-label">{{$t('pages.audit.filters.product.serviceVariety.header')}}</span>
      <PartouAutocomplete
        ref="product-service-variety-filter"
        class="filter-dropdown filter-dropdown-auto-grow borderless"
        :items="serviceVarietyFilterItems"
        :value="serviceVariety && $t(`pages.audit.filters.product.serviceVariety.filter${serviceVariety}`)"
        :tooltip="serviceVariety && $t(`pages.audit.filters.product.serviceVariety.filter${serviceVariety}`)"
        item-text="name"
        item-value="id"
        label="name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="true"
        :placeholder="$t('pages.audit.filters.product.serviceVariety.placeholder')"
        @input="onChangeServiceVarietyFilter"
      />
    </v-col>
    <v-col class="pl-0 flex-grow-0">
      <span class="ml-6 autocomplete-label">{{$t('pages.audit.filters.product.product.header')}}</span>
      <PartouAutocomplete
        class="filter-dropdown filter-dropdown-auto-grow borderless"
        :items="productFilterItems"
        :value="selectedProduct"
        :tooltip="selectedProduct && selectedProduct.name"
        item-text="name"
        item-value="id"
        label="name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="true"
        :placeholder="$t('pages.audit.filters.product.product.placeholder')"
        @input="onChangeProductFilter"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { AuditServiceVariety, Product, ProductTypeFilter, Service, ServiceKind, ServiceVarietyName } from '@/models'
import { ProductSubFilterSettings } from '@/models/Audit'
import i18n from '@/plugins/i18n'
import { IProductService } from '@/services/ProductService/IProductService'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

type DropdownFilterItem = {
  id: string,
  name: string
}

@Component({
  components: { PartouAutocomplete }
})
export default class ServiceAuditTableFiltersProduct extends Vue {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  @Prop({ required: true })
  value!: ProductSubFilterSettings

  @Prop({ required: false, default: null })
  service!: Service | null

  changeType = this.value?.changeType ?? null
  serviceVariety = this.value?.serviceVariety ?? null
  selectedProduct = this.value?.product ?? null
  productFilterItems: Partial<Product>[] = []
  serviceKind: ServiceKind | null = null

  get changeTypeFilterItems (): DropdownFilterItem[] {
    return Object.keys(ProductTypeFilter).map((key) => {
      return {
        id: key,
        name: i18n.t(`pages.audit.filters.product.changeType.filter${key}`).toString()
      }
    })
  }

  get serviceVarietyFilterItems (): DropdownFilterItem[] {
    const allowServiceVarieties = []

    if (!this.service || this.serviceKind === ServiceKind.DayCare) {
      allowServiceVarieties.push({ id: AuditServiceVariety.Kdv, name: i18n.t('pages.audit.filters.product.serviceVariety.filterKDV').toString() })
    }

    if (!this.service || this.serviceKind === ServiceKind.SchoolCare) {
      allowServiceVarieties.push({ id: AuditServiceVariety.Nso, name: i18n.t('pages.audit.filters.product.serviceVariety.filterNSO').toString() })
      allowServiceVarieties.push({ id: AuditServiceVariety.Vso, name: i18n.t('pages.audit.filters.product.serviceVariety.filterVSO').toString() })
    }

    return allowServiceVarieties
  }

  @Watch('value', { immediate: true, deep: true })
  async onFiltersChange (newValue?: ProductSubFilterSettings): Promise<void> {
    this.changeType = newValue?.changeType ?? null
    this.serviceVariety = newValue?.serviceVariety ?? null
    this.selectedProduct = newValue?.product ?? null

    await this.updateProductsAsync()
  }

  @Watch('service', { immediate: true, deep: true })
  async onChangeService (newValue: Service | null): Promise<void> {
    this.service = newValue
    await this.updateServiceVarietiesAsync()
    this.$emit('input', { ...this.value, serviceVariety: null, product: null })
  }

  onChangeChangeTypeFilter (changeType: DropdownFilterItem | null): void {
    this.$emit('input', { ...this.value, changeType: changeType?.id })
  }

  onChangeServiceVarietyFilter (serviceVariety: DropdownFilterItem | null): void {
    this.$emit('input', { ...this.value, serviceVariety: serviceVariety?.id, product: null })
  }

  onChangeProductFilter (product: Product | null): void {
    this.$emit('input', { ...this.value, product })
  }

  async updateServiceVarietiesAsync (): Promise<void> {
    this.serviceKind = null
    if (this.service) {
      this.serviceKind = await this.serviceService.getServiceKindByServiceIdAsync(this.service.id)
    }
  }

  async updateProductsAsync (): Promise<void> {
    const serviceVariety = this.serviceVariety ? ServiceVarietyName[this.serviceVariety] : undefined

    if (this.service) {
      this.productFilterItems = await this.productService.getProductsByServiceAsync(this.service.id, serviceVariety)
    } else {
      this.productFilterItems = await this.productService.getProductsByServiceVarietyAsync(serviceVariety)
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables/variables.scss";

.filter-dropdown {
  width: 200px;
}

.filter-dropdown-auto-grow {
  .vs__dropdown-menu {
    min-width: 200px;
    width: auto !important;
  }
}
</style>
