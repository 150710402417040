<template v-if="showProposeDialog">
  <PartouDialog
    class="propose-proposition-dialog"
    :width="600"
    :showDialog="showProposeDialog"
    :showLoaderOnConfirm="true"
    :onNotifyAcceptedAsync="onNotifyAcceptedAsync"
    :cancelTranslationString="$t('waitingListPropositions.table.expanded.cancelHandling')"
    :confirmTranslationString="$t('waitingListPropositions.table.expanded.propose')"
    @canceled="onPropositionActionCancelled"
  >
    <p
      class="mb-6"
      v-html="$t('waitingListPropositions.table.expanded.proposeCheck', { name: `${currentProposition.locationInquiry.child.firstName} ${currentProposition.locationInquiry.child.lastName}`})"
    />
    <div class="button-error-container">
      <span v-if="plannerActionError" class="error-message">
        {{ $t('services.controlVariables.triggerCalculateCapacityError') }}
      </span>
    </div>
  </PartouDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import { WaitingListProposition } from '@/models'

@Component({
  components: {
    PartouDialog
  }
})
export default class ProposePropositionDialog extends Vue {
  @Prop({ required: true })
  showProposeDialog!: boolean

  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  @Prop({ required: false, default: false })
  plannerActionError!: boolean

  @Prop({ required: true })
  OnWaitinglistPropositionProposedAsync? : () => Promise<void>

  @Emit('canceled')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPropositionActionCancelled () {}

  async onNotifyAcceptedAsync () : Promise<void> {
    if (this.OnWaitinglistPropositionProposedAsync) {
      await this.OnWaitinglistPropositionProposedAsync()
    }
  }
}
</script>

<style scoped>
.button-error-container {
  display: flex;
  line-break: 16px;

  .error-message {
    padding-bottom: 5px;
  }
}
</style>
