<template>
  <v-card class="rounded-xl card pa-8 full-width" :flat="true">
    <v-card-text>
      <div class="select-product-card-header">
        <h1>{{$t('services.products.chooseCalculatorProduct.title')}}</h1>
      </div>
      <div class="content mt-8">
        <div class="description">
          <h2 class="red-header">{{$t('services.products.chooseCalculatorProduct.subTitleKDV')}}</h2>
          <p v-html="$t('services.products.chooseCalculatorProduct.description')"></p>
          <p class="mb-0" v-html="$t('services.products.chooseCalculatorProduct.info')"></p>
        </div>
        <div class="product-selection">
          <PartouAutocomplete
            class="rounded-xl borderless"
            item-text="name"
            item-value="id"
            label="name"
            name="selectProductKDV"
            :value="selectedProductKDV"
            :items="productsKDV"
            :appendIconString="'mdi-chevron-down'"
            :clearable="true"
            :placeholder="$t('services.products.chooseCalculatorProduct.productSelectPlaceholder')"
            :noDataText="$t('services.products.chooseCalculatorProduct.productSelectNoData')"
            @input="selectionChanged" />
        </div>
      </div>
      <PartouDialog
        :width="600"
        v-if="showConfirmRemoveDialog"
        :showDialog="showConfirmRemoveDialog"
        @accepted="onShowConfirmRemoveDialogConfirmed"
        @canceled="onShowConfirmRemoveDialogCanceled"
        :cancelTranslationString="'services.products.table.expanded.removeCalculationProduct.cancel'"
        :confirmTranslationString="'services.products.table.expanded.removeCalculationProduct.remove'">
          <h3>{{ $t('services.products.table.expanded.removeCalculationProduct.title') }} </h3>
          <p class="mb-6 mt-6"> {{ $t('services.products.table.expanded.removeCalculationProduct.description')}} </p>
      </PartouDialog>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { Product, ProductCategory, ServiceKind, ServiceProduct } from '@/models'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import ServiceVarietyName from '@/models/enums/ServiceVarietyName'
import { IProductService } from '@/services/ProductService/IProductService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'

@Component({
  components: { PartouAutocomplete, PartouDialog }
})
export default class BenefitCalculatorProductSelection extends Vue {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  productsKDV: Product[] = []
  selectedProductKDV: Product|null = null
  showConfirmRemoveDialog = false

  serviceKinds = ServiceKind
  serviceVarieties = ServiceVarietyName

  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  isChildBenefitCalculatorActive!: boolean

  @Prop({ required: true })
  availableServiceProducts!: Partial<ServiceProduct>[]

  @Watch('availableServiceProducts', { immediate: true, deep: true })
  @Watch('serviceKind', { immediate: true })
  onItemsChanged () {
    this.updateSelectableProducts()
  }

  @Emit('onSelectedCalculatorProductChanged')
  onSelectedCalculatorProductChanged () : boolean {
    return this.selectedProductKDV === null
  }

  async mounted () : Promise<void> {
    this.updateSelectableProducts()
  }

  updateSelectableProducts () {
    const currentDate: Date = new Date()
    this.productsKDV = []
    this.selectedProductKDV = null

    this.availableServiceProducts.forEach((sp: Partial<ServiceProduct>) => {
      if (sp.product &&
          sp.product?.category === ProductCategory.AllWeeks &&
          new Date(sp.validFrom) < currentDate &&
          sp.product?.serviceVariety === ServiceVarietyName.KDV) {
        this.productsKDV.push(sp.product)
        if (sp.isUsedInChildBenefitCalculator) {
          this.selectedProductKDV = sp.product
        }
      }
    })
  }

  async selectionChanged (selectedProduct: Product) {
    if (this.isChildBenefitCalculatorActive && !selectedProduct) {
      this.showConfirmRemoveDialog = true
    } else {
      this.updateProductSelection(selectedProduct)
    }
  }

  onShowConfirmRemoveDialogConfirmed () {
    this.showConfirmRemoveDialog = false
    this.updateProductSelection(null)
  }

  onShowConfirmRemoveDialogCanceled () {
    this.showConfirmRemoveDialog = false
  }

  async updateProductSelection (selectedProduct: Product|null) {
    await this.productService.DeactivateIsUsedInChildBenefitCalculatorForService(this.serviceId)
    this.selectedProductKDV = selectedProduct
    if (this.selectedProductKDV) {
      await this.productService.ActivateIsUsedInChildBenefitCalculatorAsync(this.serviceId, this.selectedProductKDV.id)
    }
    this.onSelectedCalculatorProductChanged()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.select-product-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  color: $partou-secondary-bordeaux !important;
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-content: normal;
}

.description {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 20px;
  width: 50%
}

.product-selection {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: center;
  margin-left: 20px;
  width: 50%
}
</style>
