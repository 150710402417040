<template>
  <div class="mt-2 full-width">
    <v-btn v-if="hasWaitingListAnalysisPagePermission" class="btn-waitinglist-analysis-page" rounded @click="onGoToWaitingListAnalysisPageClick">
      {{ $t('services.waitingList.goToWaitingListAnalysisPageButton') }}
      <v-icon dark right>
        mdi-arrow-right
      </v-icon>
    </v-btn>
    <PartouCard class="mt-2 full-width">
      <template v-slot:card-body>
        <WaitingListTable :items="items" :editEnabled="true" :totalCount="totalCount" :isLoading="isLoading"
          :expandedItem="expandedItem" :serviceId="serviceId" @onServiceVarietyChanged="onTableOptionsChangedAsync"
          @onTableOptionsChanged="onTableOptionsChangedAsync" @onExpandedChanged="onExpandedChangedAsync" />
      </template>
    </PartouCard>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Debounce from '@/utils/decorators/debounceDecorator'
import WaitingListTable from './WaitingListTable.vue'
import { WaitingListTableOptions } from './WaitingListTableOptions'
import { IWaitingListService } from '@/services/WaitingListService/IWaitingListService'
import { LocationInquiry } from '@/models'
import { ROUTES } from '@/router/routes'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Permission from '@/models/enums/Permission'

@Component({
  components: { PartouNotify, PartouCard, WaitingListTable, DaySelector }
})
export default class WaitingList extends Vue {
  @Prop({ required: true })
  public serviceId!: string

  totalCount = 0
  expandedItem: Partial<LocationInquiry> = {}
  items: LocationInquiry[] = []

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  waitingListService: IWaitingListService = container.get<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService)

  isLoading = false
  showDeleteDialog = false

  tableOptions?: WaitingListTableOptions

  get hasWaitingListAnalysisPagePermission () : boolean {
    return this.authService.hasPermission([Permission.GetAnalysis])
  }

  @Debounce(400)
  async onTableOptionsChangedAsync (tableOptions: WaitingListTableOptions): Promise<void> {
    this.tableOptions = tableOptions
    await this.setWaitingListTableDataAsync()
  }

  async setWaitingListTableDataAsync (): Promise<void> {
    if (!this.tableOptions) {
      return
    }

    this.isLoading = true

    const { items, totalCount } = await this.waitingListService.getWaitingListTableItems(this.tableOptions)
    this.items = items
    this.totalCount = totalCount

    if (this.expandedItem.id) {
      const expandedItemToRefresh = this.items.find(x => x.booking?.id === this.expandedItem.id)
      if (expandedItemToRefresh?.id) {
        this.expandedItem = await this.waitingListService.getOneAsync(expandedItemToRefresh.id)
      } else {
        this.expandedItem = {}
      }
    }

    this.isLoading = false
  }

  async onExpandedChangedAsync (items: LocationInquiry[]): Promise<void> {
    if (items[0]) {
      this.isLoading = true
      this.expandedItem = await this.waitingListService.getOneAsync(items[0].id)
      this.isLoading = false
    }
  }

  onGoToWaitingListAnalysisPageClick (): void {
    this.$router.push({ name: ROUTES.serviceWaitingListAnalysis, params: { serviceId: this.serviceId } })
  }
}

</script>
