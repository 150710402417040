<template>
  <div class="d-flex flex-row justify-space-between">
    <div class="d-flex flex-row align-center day-cube-container">
      <template v-for="(item, index) in propositionDays">
        <div ref="cube" class="day-cube" :class="{ 'day-cube-no-bg': item === '-', accepted: placed && dayIsAccepted(item), declined: placed && dayIsDeclined(item) }" :key="index">
          <div class="day-label">
            <h3> {{ item }} </h3>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { DayOfWeek, ReservationStatus, ServiceVarietyName, WaitingListProposition } from '@/models'
import { getShortenedDayName } from '@/models/enums/DayOfWeek'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DayTableWaitingListProposition extends Vue {
  @Prop({ required: true })
  serviceVarietyName!: ServiceVarietyName

  @Prop({ required: true })
  proposition!: WaitingListProposition

  @Prop({ required: true })
  propositionDays!: string[]

  @Prop({ required: false, default: false })
  placed?: boolean

  dayIsAccepted (reservation: string): boolean {
    const reservationDays = this.proposition.placement.reservations.filter(x => x.serviceVariety.name === this.serviceVarietyName && this.getShortDayName(x.dayOfWeek) === reservation)
    if (reservationDays.length === 0) {
      return false
    }
    return reservationDays[0].status === ReservationStatus.Occupied
  }

  dayIsDeclined (reservation: string): boolean {
    const reservationDays = this.proposition.placement.reservations.filter(x => x.serviceVariety.name === this.serviceVarietyName && this.getShortDayName(x.dayOfWeek) === reservation)
    if (!reservationDays[0]) {
      return false
    }
    return reservationDays[0].status === ReservationStatus.Rejected
  }

  getShortDayName (dayName: string) :string {
    return dayName === '-' ? dayName : this.firstToUpper(getShortenedDayName(dayName as DayOfWeek))
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.day-cube {
  background: $partou-primary-orange-fourty;
  height: 28px;
  width: 28px;
  margin-inline: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.accepted {
    border-bottom: 3.5px solid $partou-secondary-bright-green;
  }
  &.declined {
    border-bottom: 3.5px solid $partou-primary-salmon;
  }
}

.day-cube-no-bg {
  background: transparent !important;
}

.day-cube-no-bg > div > h3 {
  color: $partou-primary-black-seventy;
}

.day-label {
  width: 24px;
  text-align: center;
}

.row-column {
  font-size: 13px;
  color: $partou-primary-black-fifty;
  margin-bottom: 0px;
}

.day-cube-container > div:first-child {
  margin-left: 0;
}
</style>
