<template>
  <div>
    <template v-if="editEnabled">
      <div class="change-pincode">
        <v-tooltip top :disabled="!pincodeInFuture" :value="showEditTooltip" @input="handleTooltipInput">
          <template v-slot:activator="{}">
            <div class="pincode-select-container" @mouseover="onMouseOverPincodeSelectDiv" @mouseleave="onMouseLeavePincodeSelectDiv">
              <div class="select-container">
                <v-select
                  class="mb-0"
                  :value="label"
                  :items="selectablePincodes"
                  append-icon="mdi-chevron-down"
                  @change="onPincodeChanged"
                  hide-details
                  dense
                  return-object
                  @focus="onFocusPincodeSelect"
                  @blur="onBlurPincodeSelect">
                  <template v-slot:item='{item}'><div class="select-item" v-html='item.html'/> </template>
                  <template v-slot:selection='{item}'><div class="select-item" v-html='item.html'/> </template>
                </v-select>
              </div>
              <v-icon v-if="pincodeInFuture" class="icon partou-planned margin-top">$vuetify.icons.partouPlanned</v-icon>
            </div>
          </template>
          <p class="white--text mt-4" v-html="getFuturePincodeTooltip()"></p>
        </v-tooltip>
      </div>
    </template>
    <template v-else>
      <v-tooltip top :disabled="!pincodeInFuture">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="pincode-container">
            <span class="pincode" v-html="html"></span>
            <v-icon v-if="pincodeInFuture" class="icon partou-planned">$vuetify.icons.partouPlanned</v-icon>
          </div>
        </template>
        <p class="white--text mt-4" v-html="getFuturePincodeTooltip()"></p>
      </v-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
import { GroupPincode, PartOfDay, ServiceKind, PincodeItem, Maybe, DayOfWeek } from '@/models'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { MAX_AGERANGE } from '@/models/enums/AgeCategory'
import moment from 'moment'

export type PincodeOption = { pincode: PincodeItem[], pm: number, value: string, html: string };

@Component({})
export default class PincodeSelect extends Vue {
  @Prop({ required: true, default: [] })
  value! : GroupPincode[]

  @Prop({ required: true, default: undefined })
  serviceKind! : ServiceKind

  @Prop({ required: true, default: false })
  editEnabled! : boolean

  @Prop({ required: true, default: '' })
  groupId! : string

  @Prop({ required: true })
  dayOfWeek!: DayOfWeek

  @Prop({ required: false, default: undefined })
  pincodeInFuture?: GroupPincode

  // eslint-disable-next-line @typescript-eslint/ban-types
  selectablePincodes : PincodeOption[] = []
  label : string | undefined = ''
  html : string | undefined = ''

  isPincodeSelectFocussed = false
  isSelectMouse = false
  showEditTooltip = false

  handleTooltipInput (): void {
    // This method will be called when the tooltip tries to update its model value
    // This events is ingoerd by doing nothing since it needs to be set in de focus/blur/mouse methods
  }

  onFocusPincodeSelect (): void {
    this.isPincodeSelectFocussed = true
    this.determineShouldShowTooltip()
  }

  onBlurPincodeSelect (): void {
    this.isPincodeSelectFocussed = false
    this.determineShouldShowTooltip()
  }

  onMouseOverPincodeSelectDiv (): void {
    this.isSelectMouse = true
    this.determineShouldShowTooltip()
  }

  onMouseLeavePincodeSelectDiv (): void {
    this.isSelectMouse = false
    this.determineShouldShowTooltip()
  }

  determineShouldShowTooltip (): void {
    if (!this.showEditTooltip && (this.isPincodeSelectFocussed || this.isSelectMouse)) {
      this.$nextTick(() => {
        this.showEditTooltip = true
      })
    } else if (this.showEditTooltip && !this.isPincodeSelectFocussed && !this.isSelectMouse) {
      this.$nextTick(() => {
        this.showEditTooltip = false
      })
    }
  }

  getFuturePincodeTooltip (): string {
    const pincode = this.selectablePincodes.find((x) => _.isEqual(x.pincode, this.pincodeInFuture?.pincode))
    const futurePincodeLabel = pincode?.value
    const pincodeTranslation = `${this.getSplittedPincode(futurePincodeLabel).join(' : ')} ${this.getBudgetedStaff(futurePincodeLabel)}`
    const date = moment(this.pincodeInFuture?.validFrom).format('DD-MM-YYYY')
    const dayOfWeekTranslation = this.$t(`days.${this.dayOfWeek.toLowerCase()}`)
    return this.$t('pages.ServiceOffer.pincodeInFutureTooltip', { pincode: pincodeTranslation, date, dayOfWeek: dayOfWeekTranslation }).toString()
  }

  getSplittedPincode (label?: string): Array<string> {
    label ??= this.label
    if (!label || label?.trim() === '') {
      return []
    }

    return label.split(' |')[0].split(':')
  }

  getBudgetedStaff (label?: string): Maybe<string> {
    label ??= this.label
    if (!label || label?.trim() === '') {
      return undefined
    }

    return `| ${label.split(' ')[2]}`
  }

  mounted () : void {
    this.selectablePincodes = []
    switch (this.serviceKind) {
    case ServiceKind.DayCare:
      this.selectablePincodes = this.pincodes.KDV
      break
    case ServiceKind.SchoolCare:
      this.selectablePincodes = this.pincodes.BSO
      break
    default: {
      this.selectablePincodes = [...this.pincodes.KDV, ...this.pincodes.BSO]
    }
    }
    this.renderLabel()
  }

  renderLabel (): void {
    this.label = ''
    this.html = ''
    if (this.value.length > 0) {
      const pincode = this.selectablePincodes.find((x) => _.isEqual(x.pincode, this.value[0].pincode))
      this.label = pincode?.value
      this.html = pincode?.html
    }
  }

  @Emit('input')
  onPincodeChanged (selectedPincode : PincodeOption) : GroupPincode[] {
    if (this.value.length > 0) {
      this.value.forEach((groupPincode) => {
        groupPincode.pincode = selectedPincode.pincode
        groupPincode.isDirty = true
      })
    } else {
      this.value.push({ partOfDay: PartOfDay.Morning, pincode: selectedPincode.pincode, groupId: this.groupId, isDirty: true } as GroupPincode)
      this.value.push({ partOfDay: PartOfDay.Afternoon, pincode: selectedPincode.pincode, groupId: this.groupId, isDirty: true } as GroupPincode)
    }
    this.renderLabel()
    this.showEditTooltip = false
    this.onBlurPincodeSelect()
    return this.value
  }

  pincodes: { KDV: PincodeOption[], BSO: PincodeOption[] } = {
    KDV: [
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 0, value: '0:0:0:0 | gesloten', html: '<b>0 : 0 : 0 : 0</b><div style="width: 16px; display: inline-block;"></div>| Gesloten' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 2 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 2 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 0, value: '2:2:0:0 | 1pm', html: '<b>2 : 2 : 0 : 0</b><div style="width: 17px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 4 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 4 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 2, value: '4:4:0:0 | 2pm', html: '<b>4 : 4 : 0 : 0</b><div style="width: 16px; display: inline-block;"></div>| 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 6 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 6 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '6:6:0:0 | 3pm', html: '<b>6 : 6 : 0 : 0</b><div style="width: 16px; display: inline-block;"></div>| 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 5 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 8 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '5:8:0:0 | 3pm', html: '<b>5 : 8 : 0 : 0</b><div style="width: 16px; display: inline-block;"></div>| 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 8 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 8 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 4, value: '8:8:0:0 | 4pm', html: '<b>8 : 8 : 0 : 0</b><div style="width: 16px; display: inline-block;"></div>| 4pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 1 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 3 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 1, value: '1:3:1:0 | 1pm', html: '<b>1 : 3 : 1 : 0</b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 3 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 5 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 2, value: '3:5:1:0 | 2pm', html: '<b>3 : 5 : 1 : 0</b><div style="width: 16px; display: inline-block;"></div>| 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 4 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 9 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '4:9:1:0 | 3pm', html: '<b>4 : 9 : 1 : 0</b><div style="width: 16px; display: inline-block;"></div>| 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 5 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 7 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '5:7:1:0 | 3pm', html: '<b>5 : 7 : 1 : 0</b><div style="width: 16px; display: inline-block;"></div>| 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 1 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 2 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 1 }], pm: 1, value: '1:2:1:1 | 1pm', html: '<b>1 : 2 : 1 : 1</b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 2 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 3 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 3 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 2, value: '2:3:3:4 | 2pm', html: '<b>2 : 3 : 3 : 4</b><div style="width: 16px; display: inline-block;"></div>| 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 3 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 5 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 4 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 3, value: '3:5:4:4 | 3pm', html: '<b>3 : 5 : 4 : 4</b><div style="width: 16px; display: inline-block;"></div>| 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 4 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 1, value: '0:0:4:4 | 1pm', html: '<b>0 : 0 : 4 : 4</b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 8 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 8 }], pm: 2, value: '0:0:8:8 | 2pm', html: '<b>0 : 0 : 8 : 8</b><div style="width: 16px; display: inline-block;"></div>| 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 8 }], pm: 1, value: '0:0:0:8 | 1pm', html: '<b>0 : 0 : 0 : 8</b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 16 }], pm: 2, value: '0:0:0:16 | 2pm', html: '<b>0 : 0 : 0 : 16</b><div style="width: 16px; display: inline-block;"></div>| 2pm' }
    ],
    BSO: [
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 0 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 0 }], pm: 0, value: '0:0 | gesloten', html: '<b>0 : 0 </b><div style="width: 16px; display: inline-block;"></div>| Gesloten' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 10 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 0 }], pm: 1, value: '10:0 | 1pm', html: '<b>10 : 0 </b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 20 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 0 }], pm: 2, value: '20:0 | 2pm', html: '<b>20 : 0 </b><div style="width: 16px; display: inline-block;"></div>| 2pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 9 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 2 }], pm: 1, value: '9:2 | 1pm', html: '<b>9 : 2 </b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 18 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 4 }], pm: 2, value: '18:4 | 2pm', html: '<b>18 : 4 </b><div style="width: 16px; display: inline-block;"></div>| 2pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 0 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 12 }], pm: 1, value: '0:12 | 1pm', html: '<b>0 : 12 </b><div style="width: 16px; display: inline-block;"></div>| 1pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 0 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 24 }], pm: 2, value: '0:24 | 2pm', html: '<b>0 : 24 </b><div style="width: 16px; display: inline-block;"></div>| 2pm' }
    ]
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

.v-menu__content {
  overflow-y: scroll;
  box-sizing: content-box;
  background-color: $partou-primary-white;
  border-radius: 24px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    left: -10px;
    margin-right: 10px;
    padding-right: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: rgba(239, 87, 93, 0.16);
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $partou-primary-salmon;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $partou-primary-salmon-sixty;
  }

  .v-list {
    &.v-select-list {
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;
      flex-grow: 0;
      background: $partou-primary-white;

      .v-list-item {
        width: 100%;
        height: 56px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .v-text-field::v-deep {
    .v-input__control {
      .v-input__slot {
        &:after {
          border: 0 !important
        }
        &:before {
          border: 0 !important
        }
      }
    }
  }

  .change-pincode::v-deep {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;

    .pincode-select-container{
      cursor: default;

      .select-container {
        max-width: 96%;
        margin-top: -6px;

        .select-item {
          font-size: 14px;
          line-height: 16px;
        }

        .v-input {
          .v-select__slot {
            .v-input__icon {
              margin-top: -2px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .pincode-container {
    cursor: default;

    .pincode {
      color: $partou-primary-black-eighty;
      font-size: 14px;
      line-height: 16px;
    }

    .pincode-divider {
      margin-right: 3px;
      margin-left: 3px;
    }

    .budgeted-staff {
      margin-left: 13px;
      color: $partou-primary-black-eighty;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  svg {
    &.partou-planned {
      margin-top: 3px;
      width: 16px;
      height: 16px;

      &.margin-top {
        margin-top: -20px;
      }
    }
  }

  .partou-planned {
    float: right;
  }
</style>
