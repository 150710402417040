<template>
  <PartouCard class="analysis-card mt-2 full-width">
    <template slot="card-header">
      <div class="rounded-t-xl card pt-4"
        :class="waitingListPropositionAnalysisResult.didAllocateWaitingListSpots ? 'did-allocate-waiting-list-spots' : 'did-not-allocate-waiting-list-spots'">
      </div>
    </template>

    <template slot="card-body">
      <div class="waiting-list-proposition-card-header">
        <v-row>
          <v-col cols="5">
            <h2>{{ $t('waitingListPropositionAnalysis.calculationDate') }}</h2>
            <p>{{waitingListPropositionAnalysisResult.calculationDate | dateTime}}</p>
          </v-col>
          <v-col cols="5">
            <h2>{{ $t('waitingListPropositionAnalysis.calculationTime') }}</h2>
            <p>{{waitingListPropositionAnalysisResult.data.calculationTime | time}}</p>
          </v-col>
          <v-col cols="2">
            <h2>{{ $t('waitingListPropositionAnalysis.maxStartDate') }}</h2>
            <p>{{waitingListPropositionAnalysisResult.data.maxStartDate | formatDate}}</p>
          </v-col>
        </v-row>
        <v-card-actions class="pb-0 pt-0">
          <v-btn text @click="showExtraInfo = !showExtraInfo" class="full-width">
            <v-spacer />
            <v-icon>{{ showExtraInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            <v-spacer />
          </v-btn>
        </v-card-actions>
      </div>
      <v-expand-transition>
        <div v-show="showExtraInfo">
          <div class="waiting-list-Proposition-card-body">
            <v-expansion-panels accordion class="full-width">
              <WaitingListPropositionAnalysisResultSummaryCard :issuedPropositions="waitingListPropositionAnalysisResult.data.issuedPropositions"/>
              <WaitingListPropositionAnalysisResultRoundTable class="round-table" v-for="(round, index) in waitingListPropositionAnalysisResult.data.rounds" :key="index" :index="index" :round="round" :placementPolicy="waitingListPropositionAnalysisResult.data.placementPolicy"/>
            </v-expansion-panels>
          </div>
        </div>
      </v-expand-transition>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListPropositionAnalysisResultSummaryCard from './WaitingListPropositionAnalysisResultSummaryCard.vue'
import WaitingListPropositionAnalysisResultRoundTable from './WaitingListPropositionAnalysisResultRoundTable.vue'
import WaitingListPropositionAnalysisResult from '@/models/WaitingListPropositionAnalysisResult'

@Component({
  components: { PartouCard, DaysCoverage, WaitingListPropositionAnalysisResultSummaryCard, WaitingListPropositionAnalysisResultRoundTable }
})
export default class WaitingListPropositionAnalysisResultCard extends Vue {
  @Prop({ required: true })
  waitingListPropositionAnalysisResult!: WaitingListPropositionAnalysisResult

  showExtraInfo = false
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.did-allocate-waiting-list-spots {
  background-color: $partou-secondary-green;
}

.did-not-allocate-waiting-list-spots {
  background-color: $partou-red;
}

.waiting-list-proposition-card-header {
  padding-left: 92px;
  padding-right: 98px;
}

.v-expansion-panels {
  display: block;
}

.round-table{
  margin-top: 10px;
}
</style>
