<template>
  <div ref="waiting-list-analysis" id="waiting-list-analysis" class="full-width">
    <v-row class="mb-1">
      <v-col class="col-lg-4 col-md-6 col-12">
        <ServicePicker v-model="currentService" @input="onCurrentServiceChange" :services="services" />
      </v-col>
      <v-col v-if="hasWaitingListPagePermission">
        <v-btn class="full-height" rounded :disabled="!currentService" @click="onGoToWaitingListPageClick">
          {{ $t('waitingListPropositionAnalysis.goToWaitingListPageButton') }}
          <v-icon dark right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <WaitingListPropositionAnalysisPageFilter v-model="resultFilter" @onFilterClicked="onFiltersChanged" />
    <PartouCard>
      <template slot="card-body">
        <div id="" v-if="!currentService">
          <h1>{{ $t('waitingListPropositionAnalysis.page.chooseLocation') }}</h1>
        </div>
        <div id="waitingListPropositionAnalysisResults" v-else-if="showResults">
          <WaitingListPropositionAnalysisResultsView :waitingListPropositionAnalysisResults="waitingListPropositionAnalysisResults" />
        </div>
        <div id="waitingListPropositionAnalysisResultsLoading" v-else-if="isLoading">
          <h1>{{ $t('waitingListPropositionAnalysis.page.loading') }}</h1>
        </div>
        <div id="waitingListPropositionAnalysisNoResult" v-else>
          <h1>{{ $t('waitingListPropositionAnalysis.page.noResult') }}</h1>
          <p>{{ $t('waitingListPropositionAnalysis.page.noResultDescription') }}</p>
        </div>
      </template>
    </PartouCard>
  </div>
</template>

<script lang="ts">
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import ServicePicker from '@/components/ServicePicker'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Service } from '@/models'
import Permission from '@/models/enums/Permission'
import { ROUTES } from '@/router/routes'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { IWaitingListService } from '@/services/WaitingListService/IWaitingListService'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import moment from 'moment'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import WaitingListPropositionAnalysisResultsView from './components/WaitingListPropositionAnalysisResultsView.vue'
import WaitingListPropositionAnalysisPageFilter, { WaitingListPropositionAnalysisResultFilters } from './components/WaitingListPropositionAnalysisPageFilter.vue'
import WaitingListPropositionAnalysisResult from '@/models/WaitingListPropositionAnalysisResult'

const serviceModule = namespace(NAMESPACES.service)

@Component({
  components: { WaitingListPropositionAnalysisResultsView, PartouCard, ServicePicker, DateInputField, WaitingListPropositionAnalysisPageFilter }
})
export default class WaitingListPropositionAnalysisPage extends Vue {
  @Prop({ required: false })
  serviceId?: string

  waitingListService: IWaitingListService = container.get<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => Promise<void>
  @serviceModule.State(STATE.service.services) services!: Array<Service>

  waitingListPropositionAnalysisResults?: WaitingListPropositionAnalysisResult[]
  currentService: Service | null = null

  isLoading = false

  resultFilter: WaitingListPropositionAnalysisResultFilters = {
    fromDate: moment().subtract(1, 'week').toDate()
  }

  get showResults (): boolean {
    return !this.isLoading && this.waitingListPropositionAnalysisResults !== undefined && this.waitingListPropositionAnalysisResults?.length !== 0
  }

  get hasWaitingListPagePermission (): boolean {
    return this.authService.hasPermission([Permission.GetWaitingList])
  }

  created (): void {
    this.getServices()
  }

  @Watch('services')
  onServiceChanged (): void {
    if (this.serviceId) {
      this.loadAllForService(this.serviceId)
    }
  }

  async loadAllForService (serviceId: string): Promise<void> {
    this.loadWaitingListPropositionResults(serviceId)
    this.loadCurrentService(serviceId)
  }

  async loadWaitingListPropositionResults (serviceId: string): Promise<void> {
    this.isLoading = true

    this.waitingListPropositionAnalysisResults = await this.waitingListService.getWaitingListPropositionAnalysisResults({
      serviceId,
      fromDate: this.resultFilter.fromDate,
      untilDate: this.resultFilter.untilDate,
      didAllocateWaitingListSpots: this.resultFilter.onlyAllocatedResults ? true : null
    })
    this.isLoading = false
  }

  async loadCurrentService (serviceId: string): Promise<void> {
    const newCurrentService = this.services.find(l => l.id === serviceId) ?? null
    this.currentService = newCurrentService
  }

  onCurrentServiceChange (service: Service): void {
    this.$router.push({ name: ROUTES.serviceWaitingListPropositionAnalysisNew, params: { serviceId: service.id } })
    this.loadAllForService(service.id)
  }

  onFiltersChanged (): void {
    if (this.serviceId) {
      this.loadWaitingListPropositionResults(this.serviceId)
    }
  }

  onGoToWaitingListPageClick () : void {
    if (this.currentService) {
      this.$router.push({ name: ROUTES.serviceWaitingList, params: { serviceId: this.currentService.id } })
    }
  }
}
</script>
