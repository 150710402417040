<template>
    <div class="service-planner-picker">
      <span class="autocomplete-label">{{$t('servicePlannerPicker.label')}}</span>
      <PartouAutocomplete
        class="rounded-xl borderless"
        :items="this.servicePlanners.filter((p) => p.encrypted_full_name != '')"
        @input="onPlannerChanged"
        item-text="encrypted_full_name"
        item-value="id"
        label="encrypted_full_name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="true"
        :multiple="true"
        :chips="true"
        :placeholder="$t('servicePlannerPicker.searchPlaceholder')"
        :noDataText="$t('servicePlannerPicker.noData')" />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import { Planner } from '@/models'

@Component({
  components: { PartouAutocomplete }
})
export default class ServicePlannerPicker extends Vue {
  @Prop({ required: true, default: [] })
  servicePlanners!: Planner[]

  @Emit('input')
  onPlannerChanged (planners: Planner[]): Planner[] {
    return planners
  }
}
</script>
<style lang="scss">
@import "@/styles/variables/variables.scss";
.vs__dropdown-option {
  padding-bottom: 8px !important;
}

.service-planner-picker {
  width: 420px;
}

.vs__selected {
  background: none !important;
  border: none !important;
}

.service-planner-picker .vs__selected-options {
  .vs__selected {
    display:flex;
    align-items: center;
    color: $partou-primary-black-ninety  !important;
  }
  .vs__search::placeholder {
    color: $partou-primary-black-fourty !important;
    font-weight: normal;
  }
}

.autocomplete-label {
  display: block;
  margin-bottom: 8px;
}

</style>
