<template>
  <div class="checklist-item-row">
    <v-skeleton-loader v-if="isLoading" class="mx-auto" width="340" type="text" />
    <v-row v-else>
      <v-col class="flex-grow-0">
        <v-icon v-if="isChecked" class="icon ml-3" :class="{'green-stroke': isRequired, 'gray-stroke': !isRequired}">$vuetify.icons.partouCheckNew</v-icon>
        <v-icon v-else class="icon ml-3" :class="{'salmon-fill': isRequired, 'gray-fill': !isRequired}">$vuetify.icons.partouCross</v-icon>
      </v-col>
      <v-col class="flex-grow-1 ml-6">
        <p>
          <span class="description">{{ description }}</span>
          <router-link v-if="hasPermission" :to="routeUrl">
            <v-icon class="icon bordeaux-fill ml-2 mr-6" @click="triggerBackNavigationSnackbar(routeUrl)">$vuetify.icons.partouEditNew</v-icon>
          </router-link>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="icon gray-fill ml-2 mr-6" v-on="on" v-bind="attrs">$vuetify.icons.partouEditNew</v-icon>
            </template>
            <p class="text-center white--text mt-4">{{ $t('services.serviceActivations.checklist.noRights') }}</p>
          </v-tooltip>
          <span v-if="!isRequired" class="optional-item">{{ $t('services.serviceActivations.checklist.optionalItem') }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showSubtitle && !isLoading" class="mt-2">
      <v-col><p class="optional-item-is-default">{{ subtitle }}</p></v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import { findRouteByName } from '@/router/routes'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { NAMESPACES } from '@/store'
import { STATE } from '@/store/serviceModule'
import { ACTIONS } from '@/store/snackbarModule'
import { IShowSnackbarPayload } from '@/store/snackbarModule/ISnackbarState'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const serviceModule = namespace(NAMESPACES.service)
const snackbarModule = namespace(NAMESPACES.snackbar)

@Component({
  components: {}
})
export default class ActivationsChecklistItem extends Vue {
  @serviceModule.State(STATE.activeServiceId) serviceId!: string
  @snackbarModule.Action(ACTIONS.showSnackbar) showSnackbarAction!: (payload: IShowSnackbarPayload) => void

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  public isChecked!: boolean

  @Prop({ required: true })
  public description!: string

  @Prop({ required: true })
  public editLink!: string

  @Prop({ required: true })
  public editPermission!: Permission

  @Prop({ required: false, default: true })
  public isRequired!: boolean

  @Prop({ required: false, default: undefined })
  public hasDefaultSetting?: boolean

  @Prop({ required: false, default: false })
  public isLoading?: boolean

  get hasPermission (): boolean {
    const route = findRouteByName(this.editLink)
    if (!route) {
      return false
    }

    const hasRoutePermission = this.authService.hasPermission(route?.meta?.allowedPermissions)
    const hasEditPermission = this.editPermission !== undefined ? this.authService.hasPermission([this.editPermission]) : true

    return hasRoutePermission && hasEditPermission
  }

  get routeUrl (): string {
    const route = findRouteByName(this.editLink)
    if (!route) {
      return '/'
    }

    return route.path.replace(':entityId', this.serviceId ?? '').replace(':serviceId', this.serviceId ?? '')
  }

  get showSubtitle (): boolean {
    return this.hasDefaultSetting !== undefined
  }

  get subtitle (): string {
    if (this.hasDefaultSetting) {
      return this.$t('services.serviceActivations.checklist.defaultSetting').toString()
    }

    return this.$t('services.serviceActivations.checklist.customSetting').toString()
  }

  triggerBackNavigationSnackbar (targetPath: string): void {
    this.showSnackbarAction({
      content: 'BackToActivationsSnackbarContent',
      timeout: -1,
      targetPath
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.checklist-item-row::v-deep {
  width: fit-content;
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 16px !important;
  }

  .col {
    line-height: 16px;
  }

  div {
    padding: 0;
  }
}

.icon::v-deep {
  margin: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;

  &.green-stroke path {
    stroke: $partou-green;
  }

  &.salmon-fill path {
    fill: $partou-primary-salmon;
  }

  &.bordeaux-fill path {
    fill: $partou-secondary-bordeaux;
  }

  &.gray-stroke path {
    stroke: $partou-primary-black-thirty;
  }

  &.gray-fill {
    cursor: default;

    path {
      fill: $partou-primary-black-thirty;
    }
  }
}

span.description {
  color: $partou-primary-black-ninety;
  width: max-content;
  line-height: 16px;
  padding-top: 1px;
}

span.optional-item {
  color: $partou-primary-black-seventy;
  line-height: 16px;
  padding-top: 1px;
}

p.optional-item-is-default {
  color: $partou-primary-black-seventy;
  line-height: 16px;
  padding-left: 52px;
}
</style>
