<template>
    <div class="proposition-status-picker">
      <span class="autocomplete-label">{{$t('propositionStatusPicker.statusSelection')}}</span>
      <PartouAutocomplete
        class="rounded-xl borderless"
        :items="waitingListPropositionStatuses"
        :value="getTranslatedStatus(value)"
        @input="onPropositionStatusChanged"
        item-text="name"
        item-value="value"
        label="name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="false"
        :placeholder="$t('propositionStatusPicker.searchPlaceholder')"
        :noDataText="$t('propositionStatusPicker.noData')" />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'
import { PropositionStatusOption } from '@/pages/WaitingListPropositionPage/WaitingListPropositionTable/WaitingListPropositionTableOptions'

@Component({
  components: { PartouAutocomplete }
})
export default class WaitingListPropositionStatusPicker extends Vue {
  @Prop({ required: false })
  value?: WaitingListPropositionStatus

  getTranslatedStatus (tableFilterStatus: WaitingListPropositionStatus | undefined) {
    return this.waitingListPropositionStatuses.find(status => status.value === tableFilterStatus)
  }

  waitingListPropositionStatuses: PropositionStatusOption[] = []

  mounted () {
    this.waitingListPropositionStatuses = this.sortedPropositionStatuses
  }

  get sortedPropositionStatuses () : Array<PropositionStatusOption> {
    const customPropositionStatusOrder = ['Created', 'Proposed', 'Reserved', 'Definitive', 'Declined', 'Revoked', 'Cancelled', 'Expired']

    return Object.values(WaitingListPropositionStatus)
      .map((status: WaitingListPropositionStatus) => {
        return {
          name: this.getStatusLabel(status),
          value: status
        }
      })
      .sort((a, b) => {
        const orderA = customPropositionStatusOrder.indexOf(a.value)
        const orderB = customPropositionStatusOrder.indexOf(b.value)

        return orderA !== -1 && orderB !== -1
          ? orderA - orderB
          : orderA !== -1 ? -1 : 1
      })
  }

  getStatusLabel (status: string) : string {
    switch (status) {
    case WaitingListPropositionStatus.Created:
      return this.$t('services.waitingList.waitingListPropositionStatus.new').toString()
    case WaitingListPropositionStatus.Declined:
      return this.$t('services.waitingList.waitingListPropositionStatus.declined').toString()
    case WaitingListPropositionStatus.Expired:
      return this.$t('services.waitingList.waitingListPropositionStatus.expired').toString()
    case WaitingListPropositionStatus.Proposed:
      return this.$t('services.waitingList.waitingListPropositionStatus.proposed').toString()
    case WaitingListPropositionStatus.Definitive:
      return this.$t('services.waitingList.waitingListPropositionStatus.completed').toString()
    case WaitingListPropositionStatus.Cancelled:
      return this.$t('services.waitingList.waitingListPropositionStatus.cancelled').toString()
    case WaitingListPropositionStatus.Reserved:
      return this.$t('services.waitingList.waitingListPropositionStatus.processing').toString()
    case WaitingListPropositionStatus.Revoked:
      return this.$t('services.waitingList.waitingListPropositionStatus.revoked').toString()
    default:
      return ''
    }
  }

  @Emit('input')
  onPropositionStatusChanged (propositionStatus:PropositionStatusOption): WaitingListPropositionStatus {
    return propositionStatus.value
  }
}
</script>
<style lang="scss">
@import "@/styles/variables/variables.scss";

.proposition-status-picker {
  min-width: 300px;
}

.proposition-status-picker.background-grey {
  .vs__selected-options {
  background: $partou-background !important;
  }
  .vs__dropdown-menu {
    background: $partou-background !important;
  }
}

.proposition-status-picker .vs__selected-options {
  height: 40px;

  .vs__search {
    font-weight: bold;
  }
  .vs__selected {
    display:flex;
    font-weight: bold;
    align-items: center;
    color: $partou-primary-black-ninety  !important;
  }
  .vs__search::placeholder {
    color: $partou-primary-black-fourty !important;
    font-weight: normal;
  }
}
</style>
