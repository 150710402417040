import { WaitingListProposition } from '@/models'
import moment from 'moment'

export function isWaitingListPropositionExtended (proposition : WaitingListProposition): boolean {
  if (!proposition.proposedOn || !proposition.expiresOn) {
    return false
  }
  // add 7 days and then get the date only to check if the expires on is within the orignal 6 days the users get to respond to the proposition.
  return moment(proposition.proposedOn).add(7, 'days').format('YYYY-MM-DD') < proposition.expiresOn
}
