import { IServiceService } from '@/services/ServiceService/IServiceService'
import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import IServiceState, { ISetActiveServicePayload } from '@/store/serviceModule/IServiceState'
import { MUTATIONS } from '@/store/serviceModule/serviceMutations'

export const ACTIONS = {
  getServices: 'getServices',
  setActiveServiceId: 'setActiveServiceId'
}

export function actions (serviceService : IServiceService): ActionTree<IServiceState, IState> {
  return {
    async getServices ({ commit }) {
      const data = await serviceService.getAllAsync()
      commit(MUTATIONS.setServices, data)
    },
    setActiveServiceId ({ commit }, payload: ISetActiveServicePayload) {
      commit(MUTATIONS.setActiveServiceId, payload)
    }
  }
}
