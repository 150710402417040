<template>
  <ActivationsCard :title="$t('services.serviceActivations.selectionGuideManagement.title')">
    <div>
      <v-row class="activation-item-row">
        <v-col class="flex-grow-0 activation-item-col-slider">
          <v-switch inset
            ref="is-visible-in-selection-guide-slider"
            :input-value="isVisibleInSelectionGuide"
            :disabled="!isAuthorized || isVisibleSliderDisabled"
            @change="onToggleVisibleInSelectionGuide"
          />
        </v-col>
        <v-col class="activation-item-col">
          <h3>{{ $t('services.serviceActivations.selectionGuideManagement.visible.title') }}</h3>
          <p>{{ $t('services.serviceActivations.selectionGuideManagement.visible.description') }}</p>
          <p class="error-message" v-if="isAuthorized && !isLoading && isVisibleSliderDisabled">{{ $t('services.serviceActivations.selectionGuideManagement.visible.errorText') }}</p>
          <div class="mt-4">
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="visibleInSelectionGuideChecklist.serviceHasDescription"
              :editLink="Routes.serviceDetail"
              :editPermission="Permission.ManageService"
              :description="$t('services.serviceActivations.selectionGuideManagement.visible.checklist.serviceDescription')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="visibleInSelectionGuideChecklist.serviceHasMedia"
              :editLink="Routes.serviceDetail"
              :editPermission="Permission.ManageServiceMedia"
              :description="$t('services.serviceActivations.selectionGuideManagement.visible.checklist.serviceMedia')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="visibleInSelectionGuideChecklist.serviceHasProperties"
              :isRequired="false"
              :editLink="Routes.serviceFeatures"
              :editPermission="Permission.ManageFeature"
              :description="$t('services.serviceActivations.selectionGuideManagement.visible.checklist.serviceProperties')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceDetail"
              :editPermission="Permission.ManageServiceOpeningTimes"
              :hasDefaultSetting="this.visibleInSelectionGuideChecklist.hasDefaultOpeningTimes"
              :description="$t('services.serviceActivations.selectionGuideManagement.visible.checklist.serviceOpeningTimes')" />
          </div>
        </v-col>
      </v-row>
      <v-row class="activation-item-row">
        <v-col class="flex-grow-0 activation-item-col-slider">
          <v-switch inset
            ref="is-bookable-in-selection-guide-slider"
            :input-value="isBookableInSelectionGuide"
            :disabled="!isAuthorized || isBookableSliderDisabled"
            @change="onToggleBookableInSelectionGuide"
          />
        </v-col>
        <v-col class="activation-item-col">
          <h3>{{ $t('services.serviceActivations.selectionGuideManagement.bookable.title') }}</h3>
          <p>{{ $t('services.serviceActivations.selectionGuideManagement.bookable.description') }}</p>
          <p class="error-message" v-if="isAuthorized && !isLoading && isBookableSliderDisabled">{{ $t('services.serviceActivations.selectionGuideManagement.bookable.errorText') }}</p>
          <div class="mt-4">
            <ActivationsChecklistItem v-if="useFlexkidsProducts"
              :isLoading="isLoading"
              :isChecked="bookableInSelectionGuideChecklist.hasActiveBookableProducts"
              :editLink="Routes.serviceProducts"
              :editPermission="Permission.ManageProduct"
              :description="$t('services.serviceActivations.selectionGuideManagement.bookable.checklist.activeBookableProducts')" />
            <ActivationsChecklistItem v-else
              :isLoading="isLoading"
              :isChecked="bookableInSelectionGuideChecklist.hasValidSubscriptions"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageService"
              :description="$t('services.serviceActivations.selectionGuideManagement.bookable.checklist.configureSubscriptionsOrProducts')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="bookableInSelectionGuideChecklist.hasPinCodesSet"
              :isRequired="false"
              :editLink="Routes.serviceOffer"
              :editPermission="Permission.ManageOffer"
              :description="$t('services.serviceActivations.selectionGuideManagement.bookable.checklist.pinCodes')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageSetting"
              :hasDefaultSetting="bookableInSelectionGuideChecklist.hasDefaultFlexMargin"
              :description="$t('services.serviceActivations.selectionGuideManagement.bookable.checklist.flexMargin')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageSetting"
              :hasDefaultSetting="bookableInSelectionGuideChecklist.hasDefaultPlanningHorizon"
              :description="$t('services.serviceActivations.selectionGuideManagement.bookable.checklist.planningHorizon')" />
          </div>
        </v-col>
      </v-row>
    </div>
    <PartouDialog
      ref="visible-in-selection-guide-dialog"
      v-if="showVisibleInSelectionGuideConfirmDialog"
      :width="400"
      :showDialog="showVisibleInSelectionGuideConfirmDialog"
      @accepted="onConfirmVisibleInSelectionGuideAsync"
      @canceled="onCancelVisibleInSelectionGuide"
      :cancelTranslationString="`services.serviceActivations.selectionGuideManagement.visible.${this.dialogVisibleInSelectionGuideTextBase}.cancelButton`"
      :confirmTranslationString="`services.serviceActivations.selectionGuideManagement.visible.${this.dialogVisibleInSelectionGuideTextBase}.confirmButton`">
        <h2>{{ $t(`services.serviceActivations.selectionGuideManagement.visible.${this.dialogVisibleInSelectionGuideTextBase}.title`) }} </h2>
        <p class="dialog-text mb-8 mt-4"> {{ $t(`services.serviceActivations.selectionGuideManagement.visible.${this.dialogVisibleInSelectionGuideTextBase}.text`)}} </p>
    </PartouDialog>
    <PartouDialog
      ref="bookable-in-selection-guide-dialog"
      v-if="showBookableInSelectionGuideConfirmDialog"
      :width="400"
      :showDialog="showBookableInSelectionGuideConfirmDialog"
      @accepted="onConfirmBookableInSelectionGuideAsync"
      @canceled="onCancelBookableInSelectionGuide"
      :cancelTranslationString="`services.serviceActivations.selectionGuideManagement.bookable.${this.dialogBookableInSelectionGuideTextBase}.cancelButton`"
      :confirmTranslationString="`services.serviceActivations.selectionGuideManagement.bookable.${this.dialogBookableInSelectionGuideTextBase}.confirmButton`">
        <h2>{{ $t(`services.serviceActivations.selectionGuideManagement.bookable.${this.dialogBookableInSelectionGuideTextBase}.title`) }} </h2>
        <p class="dialog-text mb-8 mt-4"> {{ $t(`services.serviceActivations.selectionGuideManagement.bookable.${this.dialogBookableInSelectionGuideTextBase}.text`)}} </p>
    </PartouDialog>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import ActivationsChecklistItem from './ActivationsChecklistItem.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import Permission from '@/models/enums/Permission'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Service, ServiceSettings } from '@/models'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IOfferService } from '@/services/OfferService/IOfferService'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { ROUTES } from '@/router/routes'
import { hasActiveBookableProducts, hasDefaultOpeningTimes, hasDefaultFlexMargin, hasDefaultPlanningHorizon, hasPinCodesSet, hasValidSubscriptions, hasOpeningTimes } from './ActivationsChecklistHelpers'

@Component({
  components: { ActivationsCard, PartouDialog, ActivationsChecklistItem }
})
export default class SelectionGuideActivation extends Vue {
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  offerService: IOfferService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)

  @Prop({ required: true })
  public value!: Service

  @Prop({ required: false, default: false })
  isLoading?: boolean

  showVisibleInSelectionGuideConfirmDialog = false
  isVisibleInSelectionGuide = false
  originalVisibleInSelectionGuide = false

  showBookableInSelectionGuideConfirmDialog = false
  isBookableInSelectionGuide = false
  originalBookableInSelectionGuide = false

  visibleInSelectionGuideChecklist = {
    serviceHasDescription: false,
    serviceHasMedia: false,
    serviceHasProperties: false,
    hasOpeningTimes: true,
    hasDefaultOpeningTimes: true
  }

  bookableInSelectionGuideChecklist = {
    hasActiveBookableProducts: false,
    hasValidSubscriptions: false,
    hasPinCodesSet: false,
    hasDefaultFlexMargin: true,
    hasDefaultPlanningHorizon: true
  }

  Permission = Permission
  Routes = ROUTES

  get service (): Service {
    return this.value
  }

  get serviceSettings (): ServiceSettings {
    return this.service?.serviceSettings?.[0]
  }

  get useFlexkidsProducts (): boolean {
    return this.service?.serviceSettings[0]?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.PublishService])
  }

  get dialogVisibleInSelectionGuideTextBase (): string {
    return this.isVisibleInSelectionGuide ? 'activateDialog' : 'deactivateDialog'
  }

  get dialogBookableInSelectionGuideTextBase (): string {
    return this.isBookableInSelectionGuide ? 'activateDialog' : 'deactivateDialog'
  }

  get isVisibleSliderDisabled (): boolean {
    // The service must have a valid permitNumber, description and at least one media item attached in order to be
    // publishable.
    return !this.service?.permitNumber || !this.service?.description?.length || !this.service?.serviceMedia?.length
  }

  get isBookableSliderDisabled (): boolean {
    if (!this.service) {
      return true
    }

    // If the service uses Flexkids products then there has to be at least one active product.
    if (this.useFlexkidsProducts) {
      return !this.bookableInSelectionGuideChecklist.hasActiveBookableProducts
    }

    // For subscription based service there must be a valid permitNumber, a price that is active given the current date
    // and there must be at least one active subscription.
    return !this.bookableInSelectionGuideChecklist.hasValidSubscriptions
  }

  @Watch('value', { immediate: true, deep: true })
  onServiceChange (): void {
    this.isVisibleInSelectionGuide = this.serviceSettings.visibleInSelectionGuide
    this.isBookableInSelectionGuide = this.serviceSettings.bookableInSelectionGuide
    this.originalVisibleInSelectionGuide = this.isVisibleInSelectionGuide
    this.originalBookableInSelectionGuide = this.isBookableInSelectionGuide

    this.visibleInSelectionGuideChecklist.serviceHasDescription = !!this.service.description
    this.visibleInSelectionGuideChecklist.serviceHasMedia = this.service.serviceMedia.length > 0
    this.visibleInSelectionGuideChecklist.serviceHasProperties = this.service.serviceFeatures.length > 0
    this.visibleInSelectionGuideChecklist.hasOpeningTimes = hasOpeningTimes(this.service)
    this.visibleInSelectionGuideChecklist.hasDefaultOpeningTimes = hasDefaultOpeningTimes(this.service)

    this.bookableInSelectionGuideChecklist.hasActiveBookableProducts = hasActiveBookableProducts(this.service)
    this.bookableInSelectionGuideChecklist.hasValidSubscriptions = hasValidSubscriptions(this.service)
    this.bookableInSelectionGuideChecklist.hasPinCodesSet = hasPinCodesSet(this.service)
    this.bookableInSelectionGuideChecklist.hasDefaultFlexMargin = hasDefaultFlexMargin(this.service)
    this.bookableInSelectionGuideChecklist.hasDefaultPlanningHorizon = hasDefaultPlanningHorizon(this.service)
  }

  onToggleVisibleInSelectionGuide (enabled: boolean): void {
    this.originalVisibleInSelectionGuide = this.isVisibleInSelectionGuide
    this.isVisibleInSelectionGuide = enabled
    this.showVisibleInSelectionGuideConfirmDialog = true
  }

  async onConfirmVisibleInSelectionGuideAsync (): Promise<void> {
    if (!this.service || !this.serviceSettings) {
      this.onCancelVisibleInSelectionGuide()
      return
    }

    this.showVisibleInSelectionGuideConfirmDialog = false
    this.serviceSettings.visibleInSelectionGuide = this.isVisibleInSelectionGuide
    const success = await this.serviceSettingsService.setVisibleInSelectionGuideAsync(this.service.id, this.isVisibleInSelectionGuide)
    if (!success) {
      this.onCancelVisibleInSelectionGuide()
    }
  }

  onCancelVisibleInSelectionGuide (): void {
    this.isVisibleInSelectionGuide = this.originalVisibleInSelectionGuide
    this.serviceSettings.visibleInSelectionGuide = this.isVisibleInSelectionGuide
    this.showVisibleInSelectionGuideConfirmDialog = false
  }

  onToggleBookableInSelectionGuide (enabled: boolean): void {
    this.originalBookableInSelectionGuide = this.isBookableInSelectionGuide
    this.isBookableInSelectionGuide = enabled
    this.showBookableInSelectionGuideConfirmDialog = true
  }

  async onConfirmBookableInSelectionGuideAsync (): Promise<void> {
    if (!this.service || !this.serviceSettings) {
      this.onCancelBookableInSelectionGuide()
      return
    }

    this.showBookableInSelectionGuideConfirmDialog = false
    this.serviceSettings.bookableInSelectionGuide = this.isBookableInSelectionGuide
    const success = await this.serviceSettingsService.setBookableInSelectionGuideAsync(this.service.id, this.isBookableInSelectionGuide)
    if (!success) {
      this.onCancelBookableInSelectionGuide()
      return
    }

    // If IsBookable is enabled then CapacityChangedOn needs to be set so that the capacities will be calculated again.
    if (this.isBookableInSelectionGuide) {
      await this.offerService.updateCapacityChangedOnForOfferByServiceIdAsync(this.service.id)
    }

    this.$emit('input', this.service)
  }

  onCancelBookableInSelectionGuide (): void {
    this.isBookableInSelectionGuide = this.originalBookableInSelectionGuide
    this.serviceSettings.bookableInSelectionGuide = this.isBookableInSelectionGuide
    this.showBookableInSelectionGuideConfirmDialog = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}

p.dialog-text {
  color: $partou-primary-black-ninety;
}
</style>
