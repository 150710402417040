<template>
  <div class="data-component reservation-info" v-if="reservations.length > 0">
    <div class="days-info">
      <p class="data-labels">{{ $t(`waitingListPropositions.table.expanded.${label}`) }}</p>
      <div class="d-flex start-data" v-for="reservation in reservations" :key="reservation.id">
        <p class="start-data-day">{{ getShortDayName(reservation.dayOfWeek) }}</p>
        <p class="start-data-date">{{ getFormattedDate(reservation.validFrom) }}</p>
      </div>
    </div>
    <div class="group-info">
      <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.group') }}</p>
      <div class="d-flex justify-space-between mb-2" v-for="reservation in reservations" :key="reservation.id">
        <p>{{ reservation.group.name }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Placement, Reservation, ReservationStatus, ServiceVarietyName } from '@/models'
import { getShortenedDayName, parseStringToDayOfWeek } from '@/models/enums/DayOfWeek'
import { getFormattedDate } from '@/utils/dateUtils'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ServiceVarietyReservations extends Vue {
  @Prop({ required: true })
  placement!: Placement

  @Prop({ required: true })
  serviceVarietyName!: ServiceVarietyName

  @Prop({ required: true })
  label!: string

  ServiceVarietyName = ServiceVarietyName
  ReservationStatus = ReservationStatus

  orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

  get reservations () {
    return this.getSortedReservationsForCareType(this.placement, this.serviceVarietyName)
  }

  getSortedReservationsForCareType (placement: Placement, serviceVarietyName: ServiceVarietyName) : Reservation[] {
    const reservationsForCareType = Array.from(
      placement.reservations
        .filter(reservation => reservation.serviceVariety.name === serviceVarietyName)
        .reduce((map, reservation) => {
          if (!map.has(reservation.dayOfWeek)) {
            map.set(reservation.dayOfWeek, reservation)
          }
          return map
        }, new Map())
        .values()
    )
    reservationsForCareType.sort((a, b) => this.orderedDays.indexOf(a.dayOfWeek) - this.orderedDays.indexOf(b.dayOfWeek))
    return reservationsForCareType
  }

  getFormattedDate (date: Date, withTime = false): string {
    return getFormattedDate(date, withTime)
  }

  getShortDayName (dayOfWeek: string): string {
    return this.firstToUpper(getShortenedDayName(parseStringToDayOfWeek(dayOfWeek)))
  }

  firstToUpper (dayLabel: string): string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
  .reservation-info {
    display: flex;
    padding-right: 24px;
  }

  .group-info > * {
    padding-left: 8px;
  }

  .days-info {
    width: 115px;
  }

  .data-labels {
    width: 100% !important;
  }

  .start-data {
    gap: 8px;
  }

  .start-data-date {
    width: 84px;
  }

  .start-data-day {
    width: 23px;
  }
</style>
