<template>
  <div ref="services" id="services" class="service-container">
    <div class="mb-8">
      <h1>{{ $t('services.title') }}</h1>
      <v-row class="mb-1">
        <v-col class="flex-grow-0">
          <ServicePicker class="background-grey" v-model="currentService" @input="onCurrentServiceChange" :services="services"/>
        </v-col>
        <v-col class="sub-menu flex-grow-1 pt-8">
          <v-tabs v-if="serviceTabs && serviceTabs.length" ref="service-navigation-tabs" :show-arrows="false" class="rounded-xl">
            <v-tab v-for="(tab, index) in serviceTabs" :key="index"
              @click="onTabClicked(index)"
              :to="tab.route"
              :disabled="tab.disabled"
              ref="service-navigation-tab" class="ml-4 mr-4">
              {{tab.label}}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <router-view></router-view>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import { ROUTES } from '@/router/routes'
import ServicePicker from '@/components/ServicePicker'
import { Service } from '@/models'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import { ISetActiveServicePayload } from '@/store/serviceModule/IServiceState'

const serviceModule = namespace(NAMESPACES.service)

interface ServiceTab {
  route: string | null;
  label: string;
  disabled?: boolean;
}

@Component({
  components: { ServicePicker }
})
export default class ServicesPage extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => void
  @serviceModule.Action(ACTIONS.service.setActiveServiceId) setActiveServiceId!: (payload: ISetActiveServicePayload) => void
  @serviceModule.State(STATE.service.services) services!: Array<Service>

  serviceTabs : ServiceTab[] = []
  activeTabIndex = 0
  currentService: Service | null = null
  currentServiceId: string | null = null

  @Watch('$route', { immediate: true })
  onRouteChanged () : void {
    const serviceId = this.$route.params.serviceId || this.$route.params.entityId

    if (!serviceId) {
      this.serviceTabs = []
      this.currentService = null
      return
    }

    // The services aren't set yet when the page is reloaded, so do not redirect to not found when services is empty
    if (this.services.length > 0) {
      const service = this.services.find((service : Service) => service.id === serviceId)
      if (service) {
        this.currentService = service
        Vue.set(this, 'currentService', service)
      } else {
        this.$router.push({ name: ROUTES.notFound })
      }
    }

    this.buildServiceTabs(serviceId)
    const activeTabIndex = this.serviceTabs.findIndex(x => x.route === this.$route.path)
    this.activeTabIndex = activeTabIndex > -1 ? activeTabIndex : 0
  }

  created (): void {
    this.currentServiceId = this.$route.params.serviceId || this.$route.params.entityId
    this.setActiveServiceId({ activeServiceId: this.currentServiceId ?? null })
    this.getServices()
  }

  buildServiceTabs (serviceId: string) : void {
    this.serviceTabs = []

    if (this.authService.hasPermission([Permission.GetService])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/activeringen`, label: this.$t('services.menu.activations').toString() })
    }

    if (this.authService.hasPermission([Permission.GetService])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/info`, label: this.$t('services.menu.information').toString() })
    }

    if (this.authService.hasPermission([Permission.GetFeature])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/kenmerken`, label: this.$t('services.menu.attributes').toString() })
    }

    if (this.authService.hasPermission([Permission.GetOffer])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/aanbod`, label: this.$t('services.menu.offer').toString() })
    }

    if (this.authService.hasPermission([Permission.GetWaitingList])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/wachtlijst`, label: this.$t('services.menu.waitingList').toString() })
    }

    if (this.authService.hasPermission([Permission.GetProduct])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/producten`, label: this.$t('services.menu.products').toString() })
    }

    if (this.authService.hasPermission([Permission.GetSetting, Permission.GetPricing])) {
      this.serviceTabs.push({ route: `/services/${serviceId}/instellingen`, label: this.$t('services.menu.controlVariables').toString() })
    }
  }

  @Watch('services')
  onServiceChanged () : void {
    if (!this.currentService && this.currentServiceId) {
      if (this.services.length > 0) {
        const service = this.services.find((service : Service) => service.id === this.currentServiceId)
        if (service) {
          this.currentService = service
          Vue.set(this, 'currentService', this.services.find((service : Service) => service.id === this.currentServiceId))
          this.setActiveServiceId({ activeServiceId: service.id })
        } else {
          this.setActiveServiceId({ activeServiceId: null })
          this.$router.push({ name: ROUTES.notFound })
        }
      }
    }
  }

  onTabClicked (tabIndex: number): void {
    this.activeTabIndex = tabIndex
    this.navigateToActiveRoute()
  }

  onCurrentServiceChange (service: Service | null): void {
    this.serviceTabs = []

    if (service?.id) {
      this.setActiveServiceId({ activeServiceId: service.id })
      this.buildServiceTabs(service.id)
      this.navigateToActiveRoute()
    }
  }

  navigateToActiveRoute () : void {
    if (!this.currentService || !this.serviceTabs?.length) {
      return
    }

    const targetPath = this.serviceTabs[this.activeTabIndex]?.route
    if (targetPath && this.$route.path !== targetPath) {
      Vue.nextTick().then(() => this.$router.push(targetPath))
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/variables/colours.scss';

  .sub-menu {
    align-content: flex-end;

    .v-tabs-bar {
      background: none !important;
      height: 32px;

      .v-tab {
        align-items: flex-start !important;
        color: $partou-primary-black !important;
        font-size: 16px;
        text-transform: none !important;
      }
    }
  }

  .service-container {
    margin-left: auto;
    margin-right: auto;
  }

  .full-width {
    width: 100%;
  }

</style>
