<template>
  <PartouCard class="analysis-card mt-2 full-width">
    <template slot="card-body">
      <h1>{{ $t('waitingListPropositionAnalysis.createdWaitingListPropositions') }}</h1>
      <div class="issued-proposition" v-for="(issuedProposition, index) in issuedPropositions" :key="`issued-proposition-${issuedProposition.childNumber}-${index}`">
        <h2>
          {{issuedProposition.childFullName}} ({{issuedProposition.childNumber}})
        </h2>
        <div class="service-variety-row" v-for="serviceVarietyName in getServiceVarietiesForIssuedProposition(issuedProposition)" :key="`issued-proposition-${issuedProposition.childFullName}-${index}-${serviceVarietyName}`">
          <span class="data-labels"><b>{{serviceVarietyName}}</b></span>
          <div class="d-flex justify-space-between days-per-service-variety"
              v-for="day in getSortedReservationsForCareType(issuedProposition, serviceVarietyName)"
              :key="`issued-proposition-${issuedProposition.childNumber}-${index}-${serviceVarietyName}-${day.dayOfWeek}`">
            <div class="start-data-day">{{ $t(`days.${day.dayOfWeek.toLowerCase()}`) }}</div>
            <div class="start-data-date">{{ day.startsOn | formatDate }}</div>
          </div>
        </div>
      </div>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { ServiceVarietyName } from '@/models'
import { DayOfWeekWithServiceVarietyAndStartDate, IssuedProposition } from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: { PartouCard, DaysCoverage }
})
export default class WaitingListPropositionAnalysisResultSummaryCard extends Vue {
  @Prop({ required: true })
  issuedPropositions!: Array<IssuedProposition>

  orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

  getServiceVarietiesForIssuedProposition (issuedProposition: IssuedProposition): ServiceVarietyName[] {
    return [...new Set(issuedProposition.issuedDaysWithServiceVarietyAndStartDate.map((x) => x.serviceVarietyName))]
  }

  getSortedReservationsForCareType (issuedProposition: IssuedProposition, serviceVarietyName: ServiceVarietyName) : DayOfWeekWithServiceVarietyAndStartDate[] {
    const daysForCareType = issuedProposition.issuedDaysWithServiceVarietyAndStartDate.filter(x => x.serviceVarietyName === serviceVarietyName)
    daysForCareType.sort((a, b) => this.orderedDays.indexOf(a.dayOfWeek) - this.orderedDays.indexOf(b.dayOfWeek))
    return daysForCareType
  }
}
</script>

<style lang="scss" scoped>
.issued-proposition {
  &:not(:last-child) {
    margin-bottom: 14px;
  }

  .service-variety-row {
    display: inline-block;
    vertical-align: top;

    &:not(:last-child) {
      margin-right: 28px;
    }

    .days-per-service-variety {
      width: 200px;
    }
  }
}
</style>
