<template>
  <div>
    <div class="placement-policy">
      <span>{{ $t('waitingListPropositionAnalysis.placementPolicy.description') }}</span>
      <div class="percentages">
        <span class="percentage" v-if="hasFlexiblePlacementPolicy()">{{ $t('waitingListPropositionAnalysis.placementPolicy.flexiblePercentage', { percentageFrom:0, percentageUpToAndIncluding: placementPolicy.flexiblePolicyPercentageUpToAndIncluding }) }}</span>
        <span class="percentage" v-if="hasModeratePlacementPolicy()">{{ $t('waitingListPropositionAnalysis.placementPolicy.moderatePercentage', { percentageFrom: placementPolicy.flexiblePolicyPercentageUpToAndIncluding + 1, percentageUpToAndIncluding: placementPolicy.moderatePolicyPercentageUpToAndIncluding }) }}</span>
        <span class="percentage no-margin-right">{{ $t('waitingListPropositionAnalysis.placementPolicy.strictPercentage', { percentageFrom: getStrictPolicyPercentageFrom(), percentageUpToAndIncluding: 100 }) }}</span>
      </div>
    </div>
    <div class="toddler-group">
      <v-icon>$vuetify.icons.partouInfo</v-icon>
      <span>{{ $t('waitingListPropositionAnalysis.placementPolicy.toddlerGroup') }}</span>
    </div>
    <PartouDataTable
      :headers="headers"
      :items="tableItems"
      :items-per-page="itemsPerPage"
      :itemsPerPageOptions="[]"
      :totalCount="tableItems.length"
      disable-search>
      <template v-slot:[`item.impact`]="{ item }">
        <span :class="item.value < item.impact ? 'impact-too-high' : '' ">
          {{item.impact}}
        </span>
      </template>

      <template v-slot:[`item.available`]="{ item }">
        <v-icon>{{ item.available ? "$vuetify.icons.partouAvailable" : "$vuetify.icons.partouNotAvailable" }}</v-icon>
      </template>
    </PartouDataTable>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import { GroupOccupancyPercentageAverage, PlacementPolicy } from '@/models/WaitingListPropositionAnalysisResult'
import { DayOfWeek } from '@/models'

export interface IPlacementPolicyTableRow {
  group: string
  day: string
  placementPolicy: string
  occupancyPercentageAverage: string
}

@Component({
  components: { PartouCard, DateInputField, DaySelector, PartouDataTable }
})
export default class PlacementPolicyTable extends Vue {
  @Prop({ required: true })
  placementPolicy!: PlacementPolicy

  @Prop({ required: true })
  // If Map<string, GroupOccupancyPercentageAverage> is used an runtime error occurs
  occupancyPercentageAveragesPerGroup!: any // eslint-disable-line @typescript-eslint/no-explicit-any

  headers = [
    { text: 'analysis.placementPolicy.table.group', value: 'group', align: 'left', sortable: false },
    { text: 'analysis.placementPolicy.table.day', value: 'day', align: 'left', sortable: false },
    { text: 'analysis.placementPolicy.table.placementPolicy', value: 'placementPolicy', align: 'left', sortable: false },
    { text: 'analysis.placementPolicy.table.occupancyPercentageAverage', value: 'occupancyPercentageAverage', align: 'left', sortable: false, width: '280px' }
  ]

  tableItems: IPlacementPolicyTableRow[] = []
  itemsPerPage = 0

  mounted () : void {
    const orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
    const rows = [] as IPlacementPolicyTableRow[]

    for (const key in this.occupancyPercentageAveragesPerGroup) {
      if (this.occupancyPercentageAveragesPerGroup.hasOwnProperty(key)) { // eslint-disable-line
        rows.push({
          group: key
        } as IPlacementPolicyTableRow)

        for (const orderedDayOfWeek of orderedDays) {
          const occupancyPercentageAverage = this.occupancyPercentageAveragesPerGroup[key].find((y: GroupOccupancyPercentageAverage) => y.dayOfWeek === orderedDayOfWeek)
          rows.push({
            group: '',
            day: this.$t(`days.${orderedDayOfWeek.toLowerCase()}`).toString(),
            placementPolicy: this.getPlacementPolicyForGroupAndDayOfWeek(this.occupancyPercentageAveragesPerGroup[key], orderedDayOfWeek as DayOfWeek),
            occupancyPercentageAverage: occupancyPercentageAverage ? occupancyPercentageAverage.percentage + '%' : '-'
          } as IPlacementPolicyTableRow)
        }
      }
    }

    this.tableItems = rows
    this.itemsPerPage = rows.length
  }

  getPlacementPolicyForGroupAndDayOfWeek (groupOccupancyPercentageAverage: Array<GroupOccupancyPercentageAverage>, dayOfWeek: DayOfWeek): string {
    const occupancyPercentageAverage = groupOccupancyPercentageAverage.find(x => x.dayOfWeek === dayOfWeek)
    if (!occupancyPercentageAverage || !this.placementPolicy) {
      return '-'
    }

    if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding && occupancyPercentageAverage.percentage <= this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding) {
      return this.$t('pages.ServiceOffer.placementPolicy.flexible').toString()
    }

    if (this.placementPolicy.isModeratePolicyEnabled && this.placementPolicy.moderatePolicyPercentageUpToAndIncluding && occupancyPercentageAverage.percentage <= this.placementPolicy.moderatePolicyPercentageUpToAndIncluding) {
      return this.$t('pages.ServiceOffer.placementPolicy.moderate').toString()
    }

    return this.$t('pages.ServiceOffer.placementPolicy.strict').toString()
  }

  hasFlexiblePlacementPolicy (): boolean {
    return this.placementPolicy.isModeratePolicyEnabled || this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding !== undefined
  }

  hasModeratePlacementPolicy (): boolean {
    return this.placementPolicy.isModeratePolicyEnabled && this.placementPolicy.moderatePolicyPercentageUpToAndIncluding !== undefined
  }

  getStrictPolicyPercentageFrom (): number {
    if (this.placementPolicy.isModeratePolicyEnabled) {
      if (this.placementPolicy.moderatePolicyPercentageUpToAndIncluding) {
        return this.placementPolicy.moderatePolicyPercentageUpToAndIncluding + 1
      }
      return 0
    } else if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding || this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding === 0) {
      return this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding + 1
    }

    return 0
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

  .placement-policy {
    background-color: $partou-primary-backdrop-white;
    padding: 8px 16px;
    display: table;
    border-radius: 4px;
    margin-bottom: 8px;

    .percentages {
      .percentage {
        border-radius: 4px;
        background-color: $partou-secondary-blue-fourty;
        padding: 0 8px;
        margin-right: 16px;
        font-size: 14px;

        &.no-margin-right {
          margin-right: 0;
        }
      }
    }
  }

  .toddler-group {
    color: $partou-primary-black-seventy;
    fill: $partou-primary-black-seventy;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
</style>
