<template>
  <v-card flat
    class="frame"
    :class="{'frame-disabled': disabled}">
    <div class="frame-content">
      <div class="mb-6">
        <h2>{{ title }}</h2>
        <p v-if="errorMessage && !isLoading" class="error-message mb-0 mt-2" ref="error-message">{{ errorMessage }}</p>
      </div>
      <slot></slot>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ActivationsCard extends Vue {
  @Prop({ required: true })
  title!: string

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ required: false, default: false })
  isLoading?: boolean

  @Prop({ required: false, default: '' })
  errorMessage!: string
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px;
  padding-bottom: 32px;
  border: solid 1px $partou-primary-black-thirty;
  border-radius: 16px;

  div.frame-content::v-deep {
    width: 100%;

    p:not(:last-of-type) {
      margin-bottom: 8px;
    }

    p:is(:last-of-type) {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 8px;
    }

    .v-input--selection-controls {
      margin-top: 8px !important;
    }

    .activation-item-row {
      margin: 0 !important;
    }

    .activation-item-row:is(:first-of-type):not(:last-of-type) {
      margin-bottom: 24px !important;
    }

    .activation-item-col {
      padding: 0 !important;
    }

    .activation-item-col-slider {
      height: 28px !important;
      margin-top: 0 !important;
      padding-top: 8px !important;
    }
  }
}

.frame-disabled {
  background: $partou-primary-backdrop-white;
  border: none;
}
</style>
