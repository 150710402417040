<template>
  <v-container>
    <v-row>
      <div class="header">{{$t('pages.ServiceOffer.offer')}}</div>
    </v-row>
    <v-row v-if="selectedDate">
      <v-col cols="3" class="pt-0 pr-0 pb-0 pl-8">
        <ServiceOfferDatePicker :date="selectedDate" @datePicked="onDatePickedAsync"></ServiceOfferDatePicker>
      </v-col>
      <v-col cols="9" class="pt-0 pr-8 pb-0 pl-0">
        <ServiceOfferWeekDayPicker :weekData="weekData" :selectedDate="selectedDate" @weekdayClicked="onWeekDayClicked"></ServiceOfferWeekDayPicker>
      </v-col>
    </v-row>
    <v-row class="" v-if="selectedDate">
      <ServiceOfferDashboard :key="serviceDashboardKey" :weekData="weekData" :selectedDate="selectedDate" @refresh="refreshData" @weekdayClicked="onWeekDayClicked" @onDataUpdated="onRowDataUpdatedAsync" :serviceKind="service.kind" :placementPolicy="placementPolicy" class="service-offer-dashboard-container"></ServiceOfferDashboard>
    </v-row>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
    </v-overlay>
    <PartouDialog
      :width="400"
      :showDialog="displayDialog"
      :cancelTranslationString="'notification.cancel'"
      :confirmTranslationString="'notification.overwrite'"
      :callback="dialogCallbackAsync"
      @accepted="onDialogAcceptedAsync"
      @canceled="onDialogCanceled">
        <p> {{ $t(errorMsg) }} </p>
    </PartouDialog>
    <PartouNotify :width="400" :showDialog="updatePinCodeError" @canceled="onPinCodeErrorNotifyClose">
      <p  class="error-color">
        <v-icon class="error-icon" :size="24">info_outline</v-icon>
         {{ $t('notification.serviceOffer.pinCodeOverwriteErrorTitle') }}
      </p>
      <p>
        {{ $t('notification.serviceOffer.pinCodeOverwriteGenericError') }}
      </p>
      <v-row>
        <v-spacer/>
        <PartouButton colour="partou-black-ninety" @click="onPinCodeErrorNotifyClose" :text="$t('notification.back')" />
      </v-row>
    </PartouNotify>
  </v-container>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ServiceOfferDatePicker from './ServiceOfferDatePicker/ServiceOfferDatePicker.vue'
import ServiceOfferWeekDayPicker from './ServiceOfferWeekDayPicker/ServiceOfferWeekDayPicker.vue'
import ServiceOfferDashboard from './ServiceOfferDashboard/ServiceOfferDashboard.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'

import DailyData from './models/DailyData.model'
import PivotGridRowData from './models/PivotGridRowData.model'
import { GroupPincode, Service } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import DailyDataMapper from '@/pages/ServicesPage/ServiceOffer/models/DailyDataMapper'
import { IGroupPincodeService } from '@/services/GroupPincodeService/IGroupPincodeService'
import { parseNumberToDayOfWeek } from '@/models/enums/DayOfWeek'
import PlacementPolicy from '@/models/configuration/PlacementPolicy'

@Component({
  components: { ServiceOfferDatePicker, ServiceOfferWeekDayPicker, ServiceOfferDashboard, PartouDialog, PartouNotify, PartouButton }
})
export default class ServiceOffer extends Vue {
  @Prop({ required: true })
  serviceId!: string

  serviceService!: IServiceService
  groupPincodeService! : IGroupPincodeService

  serviceDashboardKey = 0
  service?: Service
  placementPolicy?: PlacementPolicy

  weekData: DailyData[] = []
  selectedDate?: Date | null = null
  isLoading = true
  updatePinCodeError = false

  // variables for confirm message
  displayDialog = false
  errorMsg = 'notification.serviceOffer.pincodeOverwrite'

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dialogCallbackAsync : () => Promise<void> = async () => {}

  beforeCreate () : void {
    this.groupPincodeService = container.get<IGroupPincodeService>(SERVICE_IDENTIFIERS.IGroupPincodeService)
    this.serviceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  }

  async mounted () : Promise<void> {
    this.isLoading = true
    await this.onDatePickedAsync(new Date(), true).finally(() => { this.isLoading = false })
  }

  @Watch('serviceId')
  async refreshData () : Promise<void> {
    this.isLoading = true
    try {
      if (this.selectedDate) {
        await this.onDatePickedAsync(this.selectedDate, true)
        this.serviceDashboardKey++
        this.isLoading = false
      }
    } catch (e) {
      console.error(e)
      this.isLoading = false
    }
  }

  // Note: this is triggered when a new date is clicked in the datepicker
  async onDatePickedAsync (date : Date, forceRefresh = false) : Promise<void> {
    this.weekData = await this.buildWeekDataAsync(date, forceRefresh)
    this.selectedDate = date
  }

  // Note: this is triggered when a day (monday to friday) is clicked in the dashboard
  async onWeekDayClicked (date : Date) : Promise<void> {
    this.selectedDate = date
  }

  async fetchPageDataAsync (startDate: Date) : Promise<Service> {
    return this.serviceService.getServiceDashboardDataAsync(this.serviceId, startDate)
  }

  async fetchPincodesOfGroupAsync (groupId : string, startDate : Date) : Promise<GroupPincode[]> {
    return this.serviceService.getGroupPincodesOfGroupByIdAsync(groupId, startDate, parseNumberToDayOfWeek(moment(startDate).isoWeekday()))
  }

  async hasConflictingFuturePincodesAsync (rowData : PivotGridRowData[]) : Promise<boolean> {
    const thisDate = moment(this.selectedDate ?? new Date())
    const dayOfWeek = parseNumberToDayOfWeek(thisDate.isoWeekday())
    const startDate = thisDate.add(1, 'days').toDate()

    for (const row of rowData) {
      const futurePincodes = await this.serviceService.getGroupPincodesOfGroupByIdAsync(row.group.id, startDate, dayOfWeek)
      if (futurePincodes.length > 0) {
        return true
      }
    }

    return false
  }

  async onRowDataUpdatedAsync (rowData : PivotGridRowData[]) : Promise<void> {
    // Filter only the rowData that have changes
    const dirtyRowData = rowData.filter(x => x.groupPincodes.some(x => x.isDirty))
    const pincodesToReplace = dirtyRowData.flatMap(row => row.groupPincodes)

    // conflicting pincodes do require user to accept the overwrite message to overwrite the future pincodes
    const callbackAsync = async () => {
      this.isLoading = true
      this.updatePinCodeError = false

      if (pincodesToReplace.length > 0 && !await this.groupPincodeService.persistGroupPincodesAsync(this.selectedDate ?? new Date(), pincodesToReplace)) {
        this.updatePinCodeError = true
      }

      // disable isDirty
      this.weekData.forEach((weekdata) => weekdata.pivotGridRowData.forEach((row) => {
        row.groupPincodes.forEach((pincode) => { pincode.isDirty = false })
      }))
      await this.refreshData()
    }

    if (await this.hasConflictingFuturePincodesAsync(dirtyRowData)) {
      this.showOverwriteMessage(callbackAsync)
      return
    }
    await callbackAsync()
  }

  onDialogCanceled () : void {
    this.displayDialog = false
    this.refreshData()
  }

  onPinCodeErrorNotifyClose () : void {
    this.updatePinCodeError = false
  }

  async onDialogAcceptedAsync () : Promise<void> {
    this.displayDialog = false
    await this.dialogCallbackAsync()
  }

  showOverwriteMessage (callback: () => Promise<void>) : void {
    this.displayDialog = true
    this.dialogCallbackAsync = callback
  }

  async buildWeekDataAsync (date: Date, forceRefresh = true) : Promise<DailyData[]> {
    const oldWeekStartDate = moment(this.selectedDate).isoWeekday(1)
    const newWeekStartDate = moment(date).isoWeekday(1)

    // Check if the week is different from the current one (if they are the same, no need to refetch data)
    if (!forceRefresh && oldWeekStartDate.isoWeek() === newWeekStartDate.isoWeek() && oldWeekStartDate.year() === newWeekStartDate.year()) {
      return this.weekData
    }

    this.service = await this.fetchPageDataAsync(newWeekStartDate.toDate())
    this.placementPolicy = {
      isModeratePolicyEnabled: this.service.serviceSettings[0].isModeratePolicyEnabled,
      flexiblePolicyPercentageUpToAndIncluding: this.service.serviceSettings[0].flexiblePolicyPercentageUpToAndIncluding,
      moderatePolicyPercentageUpToAndIncluding: this.service.serviceSettings[0].moderatePolicyPercentageUpToAndIncluding
    }
    return new DailyDataMapper().map(this.service, date)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .header {
    font-size: 24px;
    color: $partou-primary-black;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
    padding-left: 32px;
  }

  .service-offer-dashboard-container {
    width: 100%;
    min-width: 1200px;
  }

  .error-color {
    font-weight: 500;
    font-size: 18px;
    line-height: 20.7px;
    color: $partou-primary-salmon;
    .error-icon {
      color: $partou-primary-salmon;
      margin-top: -4px;
      margin-right: -2px;
      transform: rotate(180deg);
    }
  }
</style>
