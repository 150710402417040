<template>
  <v-tabs class="tabs-container" v-model="selectedTabIndex" fixed-tabs :show-arrows="false">
    <v-tab class="day-wrapper" v-for="(day, index) in weekData" :key="index" @click="onWeekDayClicked(day.date)">
      <div class="day-tab">
        <h2 class="day-tab-text">{{ $t(`pages.ServiceOffer.day.${day.dayIndex}`) }}</h2>
        <p class="day-tab-date">{{ day.dateString }}</p>
      </div>
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DailyData from '../models/DailyData.model'

@Component({
  components: { PartouCard }
})
export default class ServiceOfferWeekDayPicker extends Vue {
  @Prop({ required: true })
  weekData?: DailyData[]

  @Prop({ required: true })
  selectedDate?: Date

  selectedTabIndex = 0

  @Watch('selectedDate', { immediate: true })
  onSelectedDateChanged () : void {
    const selectedDateString = moment(this.selectedDate).format('DD MMM')
    const tabIndex = this.weekData && this.weekData.findIndex(x => x.dateString === selectedDateString)

    // Set index if it's between 0 (Monday) and 4 (Friday). Otherwise, set it to 0 (Monday)
    this.selectedTabIndex = tabIndex && tabIndex > 0 && tabIndex < 5 ? tabIndex : 0
  }

  onWeekDayClicked (date: Date) : void {
    this.$emit('weekdayClicked', date)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .tabs-container::v-deep {
    width: 720px;
    float: right;
    position: relative;
    border-radius: 24px 24px 0px 0px;
    border-top: 1px solid $partou-primary-black-thirty;
    border-right: 1px solid $partou-primary-black-thirty;
    border-left: 1px solid $partou-primary-black-thirty;

    .v-tabs-bar {
      background-color: transparent;
      height: 94px;
    }

    .v-slide-group__content {
      border-radius: 24px 24px 0px 0px;

    }
    .v-slide-group__wrapper {
      border-radius: 24px 24px 0px 0px;

      .v-tabs-slider {
        opacity: 0;
      }
    }

    .day-wrapper {
      display: inline-block;
          color: $partou-primary-black-eighty !important;

      .day-tab {
        margin-top: 14px;
        text-align: center;
        text-transform: lowercase;
        cursor: pointer;

        .day-tab-text {
          display: block;
          font-size: 30px;
          color: $partou-primary-black-eighty !important;
        }

        .day-tab-date {
          display: block;
          font-size: $body-font-size;
          color: $partou-primary-black-eighty !important;
        }
      }

      &.v-tab--active {
        background-color: $partou-primary-white;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
        border-top: 1px solid $partou-primary-black-thirty;
        border-right: 1px solid $partou-primary-black-thirty;
        border-left: 1px solid $partou-primary-black-thirty;
        border-radius: 24px 24px 0px 0px;

        &:before {
          background-color: blue !important;
          visibility: hidden;
          opacity: 0;
        }

        .day-tab {
          .day-tab-text {
            color: $partou-black !important;
          }

          .day-tab-date {
            color: $partou-black !important;
          }
        }
      }
    }
  }
</style>
