enum StatusCssClasses {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Pending = 'pending',
    Waiting = 'waiting',
    Rejected = 'rejected',
    Placed = 'placed',
    New = 'new',
    Definitive = 'definitive'
}

export default StatusCssClasses
