import { DEFAULT_FLEX_MARGIN, DEFAULT_FLEXIBLE_POLICY_PERCENTAGE, DEFAULT_INQUIRY_EXPIRATION_IN_MINUTES, DEFAULT_MODERATE_POLICY_PERCENTAGE, DEFAULT_PLANNING_HORIZON_IN_MONTHS } from '@/constants/constants'
import { ProductCategory, SchoolGroup, SchoolService, Service, ServiceKind, ServiceVarietyName } from '@/models'

export function hasActiveBookableProducts (service: Service): boolean {
  return !!service?.permitNumber && service?.serviceSettings[0].useFlexkidsProducts && service?.serviceProducts.length > 0
}

export function hasValidSubscriptions (service: Service): boolean {
  // Make sure there is a currently active service price and opening time.
  const hasValidPrice = service?.servicePricings.length > 0
  const hasValidOpeningTime = service?.serviceVarieties.length > 0
  const hasValidSubscription = service?.subscriptionServices.length > 0

  if (!service?.permitNumber || !hasValidPrice || !hasValidOpeningTime || !hasValidSubscription) {
    return false
  }

  // For school care make sure we have a school with at least one schoolGroup which has group opening times that are
  // valid given the current date.
  if (service.kind === ServiceKind.SchoolCare) {
    if (!service?.schoolServices?.length) {
      return false
    }

    return service.schoolServices.some((schoolService: SchoolService) => {
      const school = schoolService.school
      if (!school.schoolGroups?.length) {
        return false
      }

      return school.schoolGroups.some((schoolGroup: SchoolGroup) => schoolGroup.schoolGroupOpenings.length > 0)
    })
  }

  return true
}

export function hasValidCalculatorProducts (service: Service): boolean {
  if (!hasActiveBookableProducts(service)) {
    return false
  }

  // Get products that are set for use in the child benefit calculator, make sure there is at least one.
  const activeBenefitCalculatorProducts = service?.serviceProducts.filter(p => p.isUsedInChildBenefitCalculator)
  if (activeBenefitCalculatorProducts.length === 0) {
    return false
  }

  // For day care make sure we have an all weeks product set.
  if (service?.kind === ServiceKind.DayCare &&
    activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.KDV && p.product.category === ProductCategory.AllWeeks)) {
    return true
  }

  // For school care make sure we have an NSO product set and if a VSO product is set make sure it is a school weeks
  // product.
  if (service?.kind === ServiceKind.SchoolCare) {
    const hasVSO: boolean = activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.VSO)
    const hasValidVSO: boolean = activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.VSO && p.product?.category === ProductCategory.SchoolWeeks)
    const hasValidNSO: boolean = activeBenefitCalculatorProducts.some(p => p.product?.serviceVariety === ServiceVarietyName.NSO)

    if (hasValidNSO && (!hasVSO || hasValidVSO)) {
      return true
    }
  }

  return false
}

export function hasPinCodesSet (service: Service): boolean {
  return !!service?.groups.some(g => g.groupPincodes.length)
}

export function hasOpeningTimes (service: Service): boolean {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  return !!service?.serviceVarieties.some(v => v.serviceVarietyOpenings.some((svo) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const openingTimes = svo as any
    return days.some(d => openingTimes[d].length > 0)
  }))
}

export function hasDefaultOpeningTimes (service: Service): boolean {
  return service?.serviceVarieties.every((serviceVariety) =>
    serviceVariety.serviceVarietyOpenings.every((openingTimes) => openingTimes.isManuallySet === false)
  )
}

export function hasDefaultFlexMargin (service: Service): boolean {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  return days.every(d => service?.flexMargin[d] === DEFAULT_FLEX_MARGIN)
}

export function hasDefaultPlacementPolicy (service: Service): boolean {
  const serviceSettings = service?.serviceSettings[0]

  if (service.kind === ServiceKind.DayCare) {
    return serviceSettings &&
      serviceSettings.isModeratePolicyEnabled &&
      serviceSettings.flexiblePolicyPercentageUpToAndIncluding === DEFAULT_FLEXIBLE_POLICY_PERCENTAGE &&
      serviceSettings.moderatePolicyPercentageUpToAndIncluding === DEFAULT_MODERATE_POLICY_PERCENTAGE
  }

  return serviceSettings &&
    !serviceSettings.isModeratePolicyEnabled &&
    (serviceSettings.flexiblePolicyPercentageUpToAndIncluding === undefined || serviceSettings.flexiblePolicyPercentageUpToAndIncluding === null) &&
    (serviceSettings.moderatePolicyPercentageUpToAndIncluding === undefined || serviceSettings.moderatePolicyPercentageUpToAndIncluding === null)
}

export function hasDefaultPlanningHorizon (service: Service): boolean {
  return service?.serviceSettings[0].planningHorizonInMonths === DEFAULT_PLANNING_HORIZON_IN_MONTHS
}

export function hasDefaultActualizationPeriod (service: Service): boolean {
  return service?.serviceSettings[0].inquiryExpiresInInMinutes === DEFAULT_INQUIRY_EXPIRATION_IN_MINUTES
}
