import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IUploadService } from '../UploadService/IUploadService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IServiceMediaService } from '../ServiceMediaService/IServiceMediaService'
import { IAuthService } from '../AuthService/IAuthService'
import { IPlannerService } from './IPlannerService'
import { GetPlannersWithPropositionsDocument, GetPlannersWithPropositionsQuery, Planner } from '@/models'
import Permission from '@/models/enums/Permission'

@injectable()
export default class PlannerService implements IPlannerService {
  private apollo: ApolloClient<unknown>
  get authService () : IAuthService { return container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService) }
  get uploadService () : IUploadService { return container.get<IUploadService>(SERVICE_IDENTIFIERS.IUploadService) }
  get serviceMediaService () : IServiceMediaService { return container.get<IServiceMediaService>(SERVICE_IDENTIFIERS.IServiceMediaService) }

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getAllPlannersForWaitingListPropositions (): Promise<Partial<Planner>[]> {
    const result = await this.apollo.query<GetPlannersWithPropositionsQuery>({ query: GetPlannersWithPropositionsDocument, context: { headers: { 'X-Hasura-Role': Permission.GetService } } })
    const planners: Planner[] = result.data.getPlannersWithPropositions.map(planner => ({
      id: planner.id,
      encrypted_full_name: planner.fullName
    }))
    return planners
  }
}
