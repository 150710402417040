import { Module } from 'vuex'
import IState from '@/store/IState'
import IServiceState, { STATE } from '@/store/serviceModule/IServiceState'
import { actions, ACTIONS } from '@/store/serviceModule/serviceActions'
import { serviceMutations, MUTATIONS } from '@/store/serviceModule/serviceMutations'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IServiceService } from '@/services/ServiceService/IServiceService'

export const serviceState: IServiceState = {
  services: [],
  activeServiceId: null
}

export function module (): Module<IServiceState, IState> {
  const serviceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)

  return {
    namespaced: true,
    state: serviceState,
    mutations: serviceMutations,
    actions: actions(serviceService)
  }
}

export const NAMESPACE = 'service'
export { ACTIONS }
export { MUTATIONS }
export { STATE }
