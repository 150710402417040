<template>
  <ActivationsCard :title="$t('services.serviceActivations.waitingListManagement.title')">
    <div>
      <v-row class="activation-item-row">
        <v-col class="flex-grow-0 activation-item-col-slider">
          <v-switch inset
            ref="waiting-list-actualization-slider"
            :input-value="isWaitingListActualizationEnabled"
            :disabled="!isAuthorized || isWaitingListActualizationSliderDisabled"
            @change="onToggleWaitingListActualization"
          />
        </v-col>
        <v-col class="activation-item-col">
          <h3>{{ $t('services.serviceActivations.waitingListManagement.waitingListActualization.title') }}</h3>
          <p>{{ $t('services.serviceActivations.waitingListManagement.waitingListActualization.description') }}</p>
          <p class="error-message" v-if="isAuthorized && !isLoading && isWaitingListActualizationSliderDisabled">{{ $t('services.serviceActivations.waitingListManagement.waitingListActualization.errorText') }}</p>
          <div class="mt-4">
            <ActivationsChecklistItem v-if="useFlexkidsProducts"
              :isLoading="isLoading"
              :isChecked="checklist.hasActiveBookableProducts"
              :editLink="Routes.serviceProducts"
              :editPermission="Permission.ManageProduct"
              :description="$t('services.serviceActivations.waitingListManagement.waitingListActualization.checklist.activeBookableProducts')" />
            <ActivationsChecklistItem v-else
              :isLoading="isLoading"
              :isChecked="checklist.hasValidSubscriptions"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageService"
              :description="$t('services.serviceActivations.waitingListManagement.waitingListActualization.checklist.configureSubscriptionsOrProducts')" />
            <ActivationsChecklistItem
              :isLoading="isLoading"
              :isChecked="true"
              :isRequired="false"
              :editLink="Routes.serviceControlVariables"
              :editPermission="Permission.ManageWaitingList"
              :hasDefaultSetting="checklist.hasDefaultActualizationPeriod"
              :description="$t('services.serviceActivations.waitingListManagement.waitingListActualization.checklist.actualizationPeriods')" />
          </div>
        </v-col>
      </v-row>
      <v-row class="activation-item-row">
        <v-col class="flex-grow-0 activation-item-col-slider">
          <v-switch inset
            ref="validate-waiting-list-propositions-slider"
            :input-value="validateWaitingListPropositions"
            :disabled="!isAuthorized || isValidateWaitingListSliderDisabled"
            @change="onToggleValidateWaitingList"
          />
        </v-col>
        <v-col class="activation-item-col">
          <h3>{{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.title') }}</h3>
          <p>{{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.description') }}</p>
        </v-col>
      </v-row>
    </div>
    <PartouDialog
      ref="waiting-list-actualization-dialog"
      v-if="showWaitingListActualizationConfirmDialog"
      :width="400"
      :showDialog="showWaitingListActualizationConfirmDialog"
      @accepted="onConfirmWaitingListActualizationAsync"
      @canceled="onCancelWaitingListActualization"
      :cancelTranslationString="`services.serviceActivations.waitingListManagement.waitingListActualization.${this.dialogWaitingListActualizationTextBase}.cancelButton`"
      :confirmTranslationString="`services.serviceActivations.waitingListManagement.waitingListActualization.${this.dialogWaitingListActualizationTextBase}.confirmButton`">
        <h2>{{ $t(`services.serviceActivations.waitingListManagement.waitingListActualization.${this.dialogWaitingListActualizationTextBase}.title`) }} </h2>
        <p class="dialog-text mb-8 mt-4"> {{ $t(`services.serviceActivations.waitingListManagement.waitingListActualization.${this.dialogWaitingListActualizationTextBase}.text`)}} </p>
    </PartouDialog>
    <PartouDialog
      ref="waiting-list-validation-dialog"
      v-if="showValidateWaitingListConfirmDialog"
      :width="400"
      :showDialog="showValidateWaitingListConfirmDialog"
      @accepted="onConfirmValidateWaitingListAsync"
      @canceled="onCancelValidateWaitingList"
      :cancelTranslationString="`services.serviceActivations.waitingListManagement.waitingListValidation.${this.dialogValidateWaitingListTextBase}.cancelButton`"
      :confirmTranslationString="`services.serviceActivations.waitingListManagement.waitingListValidation.${this.dialogValidateWaitingListTextBase}.confirmButton`">
        <h2>{{ $t(`services.serviceActivations.waitingListManagement.waitingListValidation.${this.dialogValidateWaitingListTextBase}.title`) }} </h2>
        <p class="dialog-text mb-8 mt-4"> {{ $t(`services.serviceActivations.waitingListManagement.waitingListValidation.${this.dialogValidateWaitingListTextBase}.text`)}} </p>
    </PartouDialog>
    <PartouDialog
      ref="has-created-waiting-list-propositions-warning"
      v-if="showHasCreatedWaitingListPropositionsDialog"
      :showDialog="showHasCreatedWaitingListPropositionsDialog"
      :width="412"
      @canceled="closeHasCreatedWaitingListPropositionsDialog">
        <h3>{{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.warningDialog.title') }} </h3>
        <p class="mb-6 mt-6"> {{ $t('services.serviceActivations.waitingListManagement.waitingListValidation.warningDialog.text') }} </p>
    </PartouDialog>
  </ActivationsCard>
</template>

<script lang="ts">
import ActivationsCard from './ActivationsCard.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import Permission from '@/models/enums/Permission'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { Service, ServiceSettings } from '@/models'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { hasActiveBookableProducts, hasDefaultActualizationPeriod, hasValidSubscriptions } from './ActivationsChecklistHelpers'
import { ROUTES } from '@/router/routes'
import ActivationsChecklistItem from './ActivationsChecklistItem.vue'

@Component({
  components: { ActivationsCard, ActivationsChecklistItem, PartouDialog }
})
export default class WaitingListActivation extends Vue {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  serviceSettingsService: IServiceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  value!: Service

  @Prop({ required: false })
  canEnableWaitingList!: boolean

  @Prop({ required: false, default: false })
  isLoading?: boolean

  isWaitingListActualizationEnabled = false
  originalWaitingListActualizationEnabled = false
  originalWaitingListActualization: null | Date = null
  showWaitingListActualizationConfirmDialog = false

  validateWaitingListPropositions = false
  originalValidateWaitingList = false
  showValidateWaitingListConfirmDialog = false
  showHasCreatedWaitingListPropositionsDialog = false

  Permission = Permission
  Routes = ROUTES
  checklist = {
    hasActiveBookableProducts: false,
    hasValidSubscriptions: false,
    hasDefaultActualizationPeriod: true
  }

  get service (): Service {
    return this.value
  }

  get serviceSettings (): ServiceSettings {
    return this.service?.serviceSettings?.[0]
  }

  get useFlexkidsProducts (): boolean {
    return this.service?.serviceSettings[0]?.useFlexkidsProducts
  }

  get isAuthorized (): boolean {
    return this.authService.hasPermission([Permission.ManageWaitingList])
  }

  get dialogWaitingListActualizationTextBase (): string {
    return this.isWaitingListActualizationEnabled ? 'activateDialog' : 'deactivateDialog'
  }

  get dialogValidateWaitingListTextBase (): string {
    return this.validateWaitingListPropositions ? 'activateDialog' : 'deactivateDialog'
  }

  get isWaitingListActualizationSliderDisabled (): boolean {
    if (!this.service) {
      return true
    }

    if (this.useFlexkidsProducts) {
      return !this.checklist.hasActiveBookableProducts
    }

    return !this.checklist.hasValidSubscriptions
  }

  get isValidateWaitingListSliderDisabled (): boolean {
    return !this.service || !this.serviceSettings
  }

  @Watch('value', { immediate: true, deep: true })
  onServiceChange (): void {
    this.isWaitingListActualizationEnabled = !this.serviceSettings.waitingListFrozenSince
    this.validateWaitingListPropositions = this.serviceSettings.waitingListValidation
    this.originalWaitingListActualizationEnabled = this.isWaitingListActualizationEnabled
    this.originalWaitingListActualization = this.serviceSettings.waitingListFrozenSince
    this.originalValidateWaitingList = this.validateWaitingListPropositions

    this.checklist.hasActiveBookableProducts = hasActiveBookableProducts(this.service)
    this.checklist.hasValidSubscriptions = hasValidSubscriptions(this.service)
    this.checklist.hasDefaultActualizationPeriod = hasDefaultActualizationPeriod(this.service)
  }

  onToggleWaitingListActualization (enabled: boolean): void {
    this.originalWaitingListActualizationEnabled = this.isWaitingListActualizationEnabled
    this.originalWaitingListActualization = this.serviceSettings.waitingListFrozenSince
    this.isWaitingListActualizationEnabled = enabled
    this.showWaitingListActualizationConfirmDialog = true
  }

  async onConfirmWaitingListActualizationAsync (): Promise<void> {
    if (!this.service) {
      this.onCancelWaitingListActualization()
      return
    }

    let success
    this.showWaitingListActualizationConfirmDialog = false
    if (this.isWaitingListActualizationEnabled) {
      this.serviceSettings.waitingListFrozenSince = null
      success = await this.serviceService.enableWaitingListAutomationAsync(this.service.id)
    } else {
      this.serviceSettings.waitingListFrozenSince = new Date()
      success = await this.serviceService.disableWaitingListAutomationAsync(this.service.id)
    }

    if (!success) {
      this.onCancelWaitingListActualization()
    }
  }

  onCancelWaitingListActualization (): void {
    this.isWaitingListActualizationEnabled = this.originalWaitingListActualizationEnabled
    this.serviceSettings.waitingListFrozenSince = this.originalWaitingListActualization
    this.showWaitingListActualizationConfirmDialog = false
  }

  onToggleValidateWaitingList (enabled: boolean): void {
    this.originalValidateWaitingList = this.validateWaitingListPropositions
    this.validateWaitingListPropositions = enabled
    this.showValidateWaitingListConfirmDialog = true
  }

  async onConfirmValidateWaitingListAsync (): Promise<void> {
    if (!this.service || !this.serviceSettings) {
      this.onCancelValidateWaitingList()
      return
    }

    this.showValidateWaitingListConfirmDialog = false

    // When validate waiting list propositions gets disabled check if there are waiting list propositions and if so show
    // a warning.
    if (!this.validateWaitingListPropositions && await this.serviceService.getHasCreatedWaitingListPropositionsAsync(this.service.id)) {
      this.showHasCreatedWaitingListPropositionsDialog = true
    }

    this.serviceSettings.waitingListValidation = this.validateWaitingListPropositions
    const success = await this.serviceSettingsService.setWaitingListValidationAsync(this.service.id, this.validateWaitingListPropositions)
    if (!success) {
      this.onCancelValidateWaitingList()
    }
  }

  onCancelValidateWaitingList (): void {
    this.validateWaitingListPropositions = this.originalValidateWaitingList
    this.serviceSettings.waitingListValidation = this.validateWaitingListPropositions
    this.showValidateWaitingListConfirmDialog = false
  }

  closeHasCreatedWaitingListPropositionsDialog (): void {
    this.showHasCreatedWaitingListPropositionsDialog = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/switch.scss';

p {
  color: $partou-primary-black-seventy;
}

p.dialog-text {
  color: $partou-primary-black-ninety;
}
</style>
