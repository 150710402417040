<template>
  <div v-if="currentProposition" class="days-data data-component">
      <template v-if="currentProposition.locationInquiry.booking.serviceKind === ServiceKind.SchoolCare">
        <div class="d-flex flex-column day-table-wrapper">
          <div class="d-flex flex-row">
            <div class="servicevariety-label-wrapper">
              <div class="servicevariety-label"></div>
            </div>
            <div class="servicevariety-label-wrapper">
              <div class="servicevariety-label">{{ $t('dayTable.vso') }}</div>
            </div>
            <div class="servicevariety-label-wrapper">
              <div class="servicevariety-label">{{ $t('dayTable.nso') }}</div>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.propositions') }}</div>
            <DayTableWaitingListProposition :propositionDays="currentWaitingListPropositionDays(ServiceVarietyName.VSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.VSO" :placed="true"/>
            <DayTableWaitingListProposition :propositionDays="currentWaitingListPropositionDays(ServiceVarietyName.NSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.NSO" :placed="true"/>
          </div>

          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.placements') }}</div>
            <DayTableWaitingListProposition :propositionDays="placementDays(ServiceVarietyName.VSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.VSO"/>
            <DayTableWaitingListProposition :propositionDays="placementDays(ServiceVarietyName.NSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.NSO"/>
          </div>

          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.waitingLists') }}</div>
            <DayTableWaitingListProposition :propositionDays="waitingListDays(ServiceVarietyName.VSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.VSO"/>
            <DayTableWaitingListProposition :propositionDays="waitingListDays(ServiceVarietyName.NSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.NSO"/>
          </div>

          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.OriginalWaitingLists') }}</div>
            <DayTableWaitingListProposition :propositionDays="originalWaitingListDays(ServiceVarietyName.VSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.VSO"/>
            <DayTableWaitingListProposition :propositionDays="originalWaitingListDays(ServiceVarietyName.NSO)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.NSO"/>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-column day-table-wrapper">
          <div class="d-flex flex-row">
            <div class="servicevariety-label-wrapper">
              <div class="servicevariety-label"></div>
            </div>
            <div class="servicevariety-label-wrapper">
              <div class="servicevariety-label">{{ $t('dayTable.kdv') }}</div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.propositions') }}</div>
            <DayTableWaitingListProposition :propositionDays="currentWaitingListPropositionDays(ServiceVarietyName.KDV)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.KDV" :placed="true"/>
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.placements') }}</div>
            <DayTableWaitingListProposition :propositionDays="placementDays(ServiceVarietyName.KDV)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.KDV"/>
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.waitingLists') }}</div>
            <DayTableWaitingListProposition :propositionDays="originalWaitingListDays(ServiceVarietyName.KDV)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.KDV"/>
          </div>
          <div class="d-flex flex-row">
            <div class="d-flex row-column align-center">{{ $t('dayTable.OriginalWaitingLists') }}</div>
            <DayTableWaitingListProposition :propositionDays="originalWaitingListDays(ServiceVarietyName.KDV)" :proposition="currentProposition" :serviceVarietyName="ServiceVarietyName.KDV"/>
          </div>
        </div>
      </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DayOfWeek, ServiceVarietyName, WaitingListProposition, ServiceKind, ReservationStatus } from '@/models'
import DayTableWaitingListProposition from './DayTableWaitingListProposition.vue'
import { getShortenedDayName } from '@/models/enums/DayOfWeek'
import WaitingListPlaceStatus from '@/models/enums/WaitingListPlaceStatus'

@Component({
  components: {
    DayTableWaitingListProposition
  }
})
export default class SelectedDaysInfo extends Vue {
  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind

  currentWaitingListPropositionDays (serviceVariety: string) : string[] {
    const currentWaitingListPropositionDaysBasedOnFullWeek = new Array<string>(5).fill('-')
    const currentWaitingListPropositionDays = Array.from(new Set(this.currentProposition.placement.reservations.filter(x => x.serviceVariety.name === serviceVariety).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

    currentWaitingListPropositionDays.forEach(dayOfProposition => {
      const dayOfWeekIndexOfProposition = Object.keys(DayOfWeek).indexOf(dayOfProposition)
      currentWaitingListPropositionDaysBasedOnFullWeek[dayOfWeekIndexOfProposition] = this.firstToUpper(getShortenedDayName(dayOfProposition))
    })

    return currentWaitingListPropositionDaysBasedOnFullWeek
  }

  placementDays (serviceVariety: string) : string[] {
    const placementDays = new Array<string>(5).fill('-')
    const currentWaitingListPropositionDays = Array.from(new Set(this.currentProposition.placement.reservations.filter(x => x.serviceVariety.name === serviceVariety && x.status === ReservationStatus.Occupied).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

    currentWaitingListPropositionDays.forEach(dayOfProposition => {
      const dayOfWeekIndexOfProposition = Object.keys(DayOfWeek).indexOf(dayOfProposition)
      placementDays[dayOfWeekIndexOfProposition] = this.firstToUpper(getShortenedDayName(dayOfProposition))
    })

    return placementDays
  }

  waitingListDays (serviceVariety: string) : string[] {
    const waitingListPlaces = this.currentProposition?.locationInquiry?.waitingListPlaces ?? []
    const originalWaitingListDaysBasedOnFullWeek = new Array<string>(5).fill('-')
    const originalWaitingListDays = Array.from(new Set(waitingListPlaces.filter(x => x.serviceVariety.name === serviceVariety && x.status !== WaitingListPlaceStatus.Accepted).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

    originalWaitingListDays.forEach(dayOfWaitinglist => {
      const dayOfWeekIndexOfWaitingList = Object.keys(DayOfWeek).indexOf(dayOfWaitinglist)
      originalWaitingListDaysBasedOnFullWeek[dayOfWeekIndexOfWaitingList] = this.firstToUpper(getShortenedDayName(dayOfWaitinglist))
    })

    return originalWaitingListDaysBasedOnFullWeek
  }

  originalWaitingListDays (serviceVariety: string) : string[] {
    const waitingListPlaces = this.currentProposition?.locationInquiry?.waitingListPlaces ?? []
    const originalWaitingListDaysBasedOnFullWeek = new Array<string>(5).fill('-')
    const originalWaitingListDays = Array.from(new Set(waitingListPlaces.filter(x => x.serviceVariety.name === serviceVariety).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

    originalWaitingListDays.forEach(dayOfWaitinglist => {
      const dayOfWeekIndexOfWaitingList = Object.keys(DayOfWeek).indexOf(dayOfWaitinglist)
      originalWaitingListDaysBasedOnFullWeek[dayOfWeekIndexOfWaitingList] = this.firstToUpper(getShortenedDayName(dayOfWaitinglist))
    })

    return originalWaitingListDaysBasedOnFullWeek
  }

  getShortDayName (dayName: string) :string {
    return dayName === '-' ? dayName : this.firstToUpper(getShortenedDayName(dayName as DayOfWeek))
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.day-table-wrapper > div > div{
  flex: 1;
  flex-basis: 0;
  min-width: 0;
  margin-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
  justify-content: flex-end;
  color: $partou-primary-black-eighty;
}

.day-table-wrapper > div > div:not(:first-child) {
  border-left: solid 1px $partou-primary-salmon-fourty;
  padding-top: 8px;
}

.day-table-wrapper > div:first-child {
  border-bottom: solid 1px $partou-primary-salmon-fourty;
}

::v-deep .day-table-wrapper > div:nth-child(2) > div:not(:first-child) > div > div {
  background-color: $partou-secondary-blue-fourty;
}

::v-deep .day-table-wrapper > div:nth-child(3) > div:not(:first-child) > div > div {
  background-color: $partou-secondary-green-fourty;
}

.servicevariety-label {
  color: $partou-primary-black-seventy;
}

.day-cube {
  height: 28px;
  width: 28px;
  margin-inline: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
