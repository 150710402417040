<template>
  <div>
    <table class="col-12" v-if="dayData" summary="service-offer-pivotgrid">
      <tr>
        <th class="group-column header highlighted-border-right highlighted-border-bottom" scope="col">
          {{ $t(`pages.ServiceOffer.groups`) }}
        </th>
        <th v-for="column in dayData.pivotGridColumnHeaderData" :key="column.ageCategory" class="age-range-column header border-right highlighted-border-bottom" scope="col">
          <div class="content">
            <span class="age-range">{{ $t(`pages.ServiceOffer.ageCategory.${column.ageCategory}`) }} {{ $t(`pages.ServiceOffer.year`) }}</span>
            <span class="available-seats">{{ column.availableSeats }} {{ $t(`pages.ServiceOffer.available`) }} ({{column.totalSeats}})</span>
          </div>
        </th>
        <th class="pincode-column header highlighted-border-bottom" scope="col">
          <span>{{ $t(`pages.ServiceOffer.optimalOccupancy`) }}</span>
        </th>
      </tr>
      <tr v-for="row in dayData.pivotGridRowData" :key="row.group.name" >
        <td class="group-column content highlighted-border-right border-bottom" >
          <div @click="showChildrenDialog(row)" class="clickable">
            <div class="group-name">{{ row.group.name }}</div>
            <div :class="['group-details', { right: !hasOccupancyPercentageAverageForDayOfWeek(row.group) && !isClosedGroup(row.group)}]">
              <span v-if="isClosedGroup(row.group)" class="closed-group">{{ $t(`pages.ServiceOffer.closedGroup`) }}</span>
              <span v-else-if="hasOccupancyPercentageAverageForDayOfWeek(row.group)" class="occupancy-percentage">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="partou-union">$vuetify.icons.partouUnion</v-icon> <span v-bind="attrs" v-on="on" class="text">{{ getOccupancyPercentageAverageForDayOfWeek(row.group) }}%</span>
                  </template>
                  <p class="text-center white--text mt-4">{{ getOccupancyPercentageAverageTooltipText() }}</p>
                </v-tooltip>
              </span>
              <span class="placement-policy text" v-if="!isClosedGroup(row.group) && hasOccupancyPercentageAverageForDayOfWeek(row.group)" >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="partou-calendar-shine">$vuetify.icons.partouCalendarShine</v-icon> <span v-bind="attrs" v-on="on">{{getPlacementPolicyDescription(row.group, row.groupPincodes)}}</span>
                  </template>
                  <p class="text-center white--text mt-4">{{ getPlacementPolicyTooltipText(row.group, row.groupPincodes) }}</p>
                </v-tooltip>
              </span>
              <span :class="['capacity', 'text']">{{ $t(`pages.ServiceOffer.capacity`) }} {{ getPlanCapacity(row.group) }}</span>
            </div>
          </div>
        </td>
        <td v-for="ageCategory in row.ageCategoryData" :key="ageCategory.ageCategory" class="age-range-column content border-right border-bottom">
          <div @click="showChildrenDialog(row)" class="clickable seats-container">
            <template v-for="index in ageCategory.occupiedSeats">
              <div :key="'occupiedSeats'+index" class="seat seat-red" v-bind:class="{ 'seat-bordered': index <= ageCategory.maxSeatsByPincode }" />
            </template>
            <template v-for="index in ageCategory.flexSeats" >
              <div :key="'flexSeat'+index" class="seat seat-blue" v-bind:class="{ 'seat-bordered': index + ageCategory.occupiedSeats <= ageCategory.maxSeatsByPincode }" />
            </template>
            <template v-for="index in ageCategory.reservedSeats" >
              <div :key="'reservedSeats'+index" class="seat seat-yellow" v-bind:class="{ 'seat-bordered': index + ageCategory.flexSeats + ageCategory.occupiedSeats <= ageCategory.maxSeatsByPincode }" />
            </template>
            <template v-for="index in ageCategory.availableSeats" >
              <div :key="'availableSeats'+index" class="seat seat-green seat-bordered" />
            </template>
          </div>
        </td>
        <td class="pincode-column content border-bottom">
          <PincodeSelect :key="pincodeSelectKey" v-model="row.groupPincodes" :dayOfWeek="currentDayOfWeek" :pincodeInFuture="row.pincodeInFuture" :editEnabled="editEnabled" :serviceKind="serviceKind" :groupId="row.group.id"></PincodeSelect>
        </td>
      </tr>
    </table>
    <ServiceOfferChildrenDialog v-if="childrenDialogData" :show="isChildrenDialogShown" @showChanged="onChildrenDiaglogShowChange" :value="childrenDialogData" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DailyData from '../../models/DailyData.model'
import PincodeSelect from '@/pages/ServicesPage/ServiceOffer/ServiceOfferDashboard/PincodeSelect/PincodeSelect.vue'
import { Group, GroupCapacity, GroupPincode, PlacementPolicyType, ServiceKind } from '@/models'
import { isBetween } from '@/utils/dateUtils'
import ServiceOfferChildrenDialog from '../ServiceOfferChildrenDialog/ServiceOfferChildrenDialog.vue'
import PivotGridRowData from '../../models/PivotGridRowData.model'
import DayOfWeek, { parseNumberToDayOfWeek } from '@/models/enums/DayOfWeek'
import moment from 'moment'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import OccupancyPercentageSettings from '@/models/configuration/OccupancyPercentageSettings'
import PlacementPolicy from '@/models/configuration/PlacementPolicy'
import _ from 'lodash'

@Component({
  components: { PartouCard, PincodeSelect, ServiceOfferChildrenDialog }
})
export default class ServiceOfferDashboardPivotGrid extends Vue {
  @Prop({ required: true })
  dayData!: DailyData

  @Prop({ required: true, default: false })
  editEnabled!: boolean

  @Prop({ required: true, default: undefined })
  serviceKind! : ServiceKind

  @Prop({ required: true, default: undefined })
  placementPolicy? : PlacementPolicy

  pincodeSelectKey = 0
  isChildrenDialogShown = false
  childrenDialogData: PivotGridRowData | null = null
  occupancyPercentageSettings: OccupancyPercentageSettings | null = null
  toddlerPincodes = [
    { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 4 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 1, value: '0:0:4:4 | 1pm' },
    { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 8 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 8 }], pm: 2, value: '0:0:8:8 | 2pm' },
    { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 8 }], pm: 1, value: '0:0:0:8 | 1pm' },
    { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 16 }], pm: 2, value: '0:0:0:16 | 2pm' }
  ]

  get currentDayOfWeek (): DayOfWeek {
    return parseNumberToDayOfWeek(this.dayData.dayIndex)
  }

  @Watch('dayData')
  onDayDataChanged () : void {
    this.pincodeSelectKey++
  }

  onChildrenDiaglogShowChange (value: boolean) : void {
    this.isChildrenDialogShown = value
  }

  hasOccupancyPercentageAverageForDayOfWeek (group: Group) : boolean {
    const occupancyPercentageAverage = this.getOccupancyPercentageAverageForDayOfWeek(group)
    return occupancyPercentageAverage !== null
  }

  getOccupancyPercentageAverageForDayOfWeek (group: Group) : number | null {
    const dayOfWeek = this.currentDayOfWeek
    const occupancyPercentageAverage = group.occupancyPercentageAverages.find(x => x.dayOfWeek === dayOfWeek)
    return occupancyPercentageAverage?.averagePercentage ?? null
  }

  async mounted () : Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    this.occupancyPercentageSettings = await configurationService.getConfigurationByKeyAsync({ key: 'occupancy_percentage', date: new Date() })
  }

  getOccupancyPercentageAverageTooltipText () : string {
    // Default to 6
    const amountOfMonths = this.occupancyPercentageSettings?.calculateAverageOverInMonths || 6
    const dayOfWeek = this.currentDayOfWeek
    const dayOfWeekTranslation = this.$t(`days.${dayOfWeek.toLowerCase()}`)
    const from = moment().format('DD-MM-YYYY')
    const until = moment().add(amountOfMonths, 'months').format('DD-MM-YYYY')
    const tooltipText = this.$t('pages.ServiceOffer.occupancyPercentageAverageTooltip', { dayOfWeek: dayOfWeekTranslation, from, until, amountOfMonths })
    return tooltipText.toString()
  }

  getPlanCapacity (group : Group) : number {
    const groupCapacity = group.groupCapacities?.filter((x : GroupCapacity) => isBetween(this.dayData.date, x.validFrom, x.validUntil))[0]
    if (!groupCapacity) {
      return 0
    }

    const dayOfWeek = this.currentDayOfWeek
    return this.getPlanCapacityForDayOfWeek(groupCapacity, dayOfWeek)
  }

  getPlanCapacityForDayOfWeek (groupCapacity : GroupCapacity, dayOfWeek : DayOfWeek) : number {
    switch (dayOfWeek) {
    case DayOfWeek.Monday: return groupCapacity.mondayPlanCapacity
    case DayOfWeek.Tuesday: return groupCapacity.tuesdayPlanCapacity
    case DayOfWeek.Wednesday: return groupCapacity.wednesdayPlanCapacity
    case DayOfWeek.Thursday: return groupCapacity.thursdayPlanCapacity
    case DayOfWeek.Friday: return groupCapacity.fridayPlanCapacity
    default: return 0
    }
  }

  showChildrenDialog (data: PivotGridRowData) : void {
    this.childrenDialogData = data
    this.isChildrenDialogShown = true
  }

  isClosedGroup (group: Group): boolean {
    const dayOfWeek = this.currentDayOfWeek
    const currentPincode = group.groupPincodes.find(x => x.dayOfWeek === dayOfWeek && new Date(x.validFrom) <= this.dayData.date && (!x.validUntil || new Date(x.validUntil) > this.dayData.date))
    if (!currentPincode || currentPincode.pincode.every((x: any) => x.Seats === 0)) { // eslint-disable-line @typescript-eslint/no-explicit-any
      return true
    }

    return false
  }

  getPlacementPolicy (group: Group, groupPincodes: GroupPincode[]): PlacementPolicyType {
    // If the pincode is a toddlerPincode always return flexible
    if (groupPincodes.length > 0 && this.toddlerPincodes.some((x) => _.isEqual(x.pincode, groupPincodes[0].pincode))) {
      return PlacementPolicyType.Flexible
    }

    const dayOfWeek = this.currentDayOfWeek
    const occupancyPercentageAverage = group.occupancyPercentageAverages.find(x => x.dayOfWeek === dayOfWeek)
    if (!occupancyPercentageAverage || !this.placementPolicy) {
      return PlacementPolicyType.None
    }

    if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding && occupancyPercentageAverage.averagePercentage <= this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding) {
      return PlacementPolicyType.Flexible
    }

    if (this.placementPolicy.isModeratePolicyEnabled && this.placementPolicy.moderatePolicyPercentageUpToAndIncluding && occupancyPercentageAverage.averagePercentage <= this.placementPolicy.moderatePolicyPercentageUpToAndIncluding) {
      return PlacementPolicyType.Moderate
    }

    return PlacementPolicyType.Strict
  }

  getPlacementPolicyDescription (group: Group, groupPincodes: GroupPincode[]): string {
    const placementPolicyType = this.getPlacementPolicy(group, groupPincodes)

    switch (placementPolicyType) {
    case PlacementPolicyType.None:
      return '-'
    case PlacementPolicyType.Flexible:
      return this.$t('pages.ServiceOffer.placementPolicy.flexible').toString()
    case PlacementPolicyType.Moderate:
      return this.$t('pages.ServiceOffer.placementPolicy.moderate').toString()
    case PlacementPolicyType.Strict:
      return this.$t('pages.ServiceOffer.placementPolicy.strict').toString()
    }
  }

  getPlacementPolicyTooltipText (group: Group, groupPincodes: GroupPincode[]): string {
    const placementPolicyType = this.getPlacementPolicy(group, groupPincodes)

    switch (placementPolicyType) {
    case PlacementPolicyType.None:
      return '-'
    case PlacementPolicyType.Flexible:
      return this.$t('pages.ServiceOffer.placementPolicy.flexibleTooltip').toString()
    case PlacementPolicyType.Moderate:
      return this.$t('pages.ServiceOffer.placementPolicy.moderateTooltip').toString()
    case PlacementPolicyType.Strict:
      return this.$t('pages.ServiceOffer.placementPolicy.strictTooltip').toString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .group-column {
    text-align: left;
    padding: 16px 16px 16px 16px;
    width: 300px;

    &.header {
      color: $partou-secondary-bordeaux !important;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 20px;
    }

    &.content {
      width: 300px;
      padding: 14px 16px;
      display: block;

      .group-name {
        color: $partou-primary-black-eighty;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 8px;
      }

      .group-details {
        display: flex;
        justify-content: space-between;
        height: 21px;

        &.right {
          flex-direction: row-reverse;
        }

        .closed-group {
          width: 110px;
          color: $partou-primary-black-eighty;
        }

        .occupancy-percentage {
          display: flex;
          align-items: center;
          width: 70px;

          span {
            margin: -3px 0 0 8px;
          }
        }

        .placement-policy {
          width: 92px;
          display: flex;

          span {
            margin: 1px 0 0 8px;
          }
        }

        .capacity {
          width: 60px;
          text-align: right;
          display: flex;
          padding-top: 1px;
        }

        .text {
          color: $partou-primary-black-eighty;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }

        svg {
          &.partou-union {
            fill: $partou-primary-blue;
            margin-top: -3px;
            width: 16px;
            height: 16px;
          }

          &.partou-calendar-shine {
            fill: $partou-primary-blue;
            margin-top: 1px;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .age-range-column {
    text-align: left;
    padding: 16px 16px 16px 16px;

    &.header {
      .content {
        display: flex;
        align-items: center;

        .age-range {
          color: $partou-primary-black-eighty;
          font-size: 18px;
          font-weight: 500;
        }

        .available-seats {
          margin-left: auto;
          min-width: 66px;
          text-align: right;
          font-size: 14px;
          font-weight: 400;
          padding-top: 2px;
          color: $partou-primary-black-eighty;
        }
      }
    }

    &.content {
      padding: 8px;

      .seats-container {
        height: 40px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;

        .seat {
          margin: 1px;
          width: 16px;
          height: 16px;
          border-radius: 2px;

          &.seat-green {
            background: $partou-secondary-bright-green;
          }
          &.seat-blue {
            background: $partou-primary-blue
          }
          &.seat-yellow {
            background: $partou-primary-orange;
          }
          &.seat-red {
            background: $partou-primary-salmon;
          }
          &.seat-white {
            background: #FFFFFF;
          }
          &.seat-bordered {
            border: 2px solid $partou-primary-black;
          }
        }
      }
    }
  }

  .pincode-column {
    text-align: left;
    padding: 16px 16px 16px 16px;
    width: 242px;

    &.header {
      color: $partou-secondary-bordeaux !important;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 20px;
    }

    &.content {
      align-items: center;
    }
  }

  .border-right {
    border-right: 1px solid $partou-primary-black-thirty;
  }

  .highlighted-border-right {
    border-right: 1px solid $partou-primary-salmon-sixty;
  }

  .border-bottom {
    border-bottom: 1px solid $partou-primary-black-thirty;
  }

  .highlighted-border-bottom {
    border-bottom: 1px solid $partou-primary-salmon-sixty;
  }

  .clickable {
    cursor: pointer;
  }
</style>
