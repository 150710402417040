import { Service } from '@/models'

export const STATE = {
  services: 'services',
  activeServiceId: 'activeServiceId'
}

export default interface IServiceState {
  services: Array<Service>,
  activeServiceId: string | null
}

export interface ISetActiveServicePayload {
  activeServiceId: string | null
}
