<template>
  <div class="d-flex flex-row justify-space-between">
    <div class="d-flex flex-row align-center day-cube-container">
      <template v-for="(item, index) in propositionDays">
        <div ref="cube" class="day-cube" :class="{ 'day-cube-no-bg': item === '-' }" :key="index">
          <div class="day-label">
            <h3>{{ item }}</h3>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DayTable extends Vue {
    @Prop({ required: true })
    propositionDays!: string[]
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.row-column {
  font-size: 13px;
  color: $partou-primary-black-fifty;
  margin-bottom: 0px;
}

.day-label {
  width: 24px;
  text-align: center;
  &.accepted {
    color: $partou-green;
  }
  &.declined {
    color: $partou-red;
  }
}

.day-cube {
  background: $partou-primary-orange-fourty;
  height: 28px;
  width: 28px;
  margin-inline: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-cube-no-bg {
  background: transparent !important;
}

.day-cube-no-bg > div > h3 {
  color: $partou-primary-black-seventy;
}

.day-cube-container > div:first-child {
  margin-left: 0;
}
</style>
