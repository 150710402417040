import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

const hasCorrectPermission = (allowedPermissions: any, authService: IAuthService) : boolean => { // eslint-disable-line @typescript-eslint/no-explicit-any
  return allowedPermissions && Array.isArray(allowedPermissions) && authService.hasPermission(allowedPermissions)
}

router.beforeEach(async (to, _, next) => {
  const authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  if (!authService.isAuthenticated) {
    await authService.loginAsync(false)
  } else if (to.meta !== undefined) {
    const { allowAllUsers, allowedPermissions } = to.meta
    if (!allowAllUsers && !hasCorrectPermission(allowedPermissions, authService)) {
      next({ path: '/403' })
      return
    }
  }
  next()
})

export default router
