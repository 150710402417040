<template>
  <div class="placement-policy-container">
    <div class="description-container">
      <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.placementPolicy.title')}}</h2>
      <p>{{$t('services.controlVariables.placementPolicy.description', { amountOfMonths: amountOfMonthsOccupancyPercentageAverageIsCalculated })}}</p>
    </div>
    <div class="controls-container">
      <div class="row">
        <div class="column">
          <v-icon class="icon partou-calendar-shine">$vuetify.icons.partouCalendarShine</v-icon>
          <div class="header">{{$t('services.controlVariables.placementPolicy.placementPolicy')}}</div>
        </div>
        <div class="column big">
          <v-icon class="icon partou-union">$vuetify.icons.partouUnion</v-icon>
          <div class="header">{{$t('services.controlVariables.placementPolicy.occupancyPercentageAverage')}}</div>
        </div>
      </div>
      <div class="row">
        <div class="column icon">
          <v-icon :class="['icon', 'partou-check-or-close', { 'enabled-stroke': isFlexiblePolicyEnabled(), 'disabled': !isFlexiblePolicyEnabled() }]">{{isFlexiblePolicyEnabled() ? '$vuetify.icons.partouCheck2' : '$vuetify.icons.partouClose' }}</v-icon>
          <div class="header disabled">{{$t('services.controlVariables.placementPolicy.flexiblePolicy')}}</div>
        </div>
        <div class="column big">
          <div class="partial disabled">{{getFlexiblePolicyPercentageFrom()}}%</div>
          <div class="partial small disabled">{{$t('services.controlVariables.placementPolicy.upToAndIncluding')}}</div>
          <div class="partial disabled">{{getFlexiblePolicyPercentageUpToAndIncluding()}}%</div>
        </div>
      </div>
      <div class="row">
        <div class="column icon">
          <v-icon :class="['icon', 'partou-check-or-close', { 'enabled-stroke': moderatePolicyEnabled, 'disabled': !moderatePolicyEnabled }]">{{moderatePolicyEnabled ? '$vuetify.icons.partouCheck2' : '$vuetify.icons.partouClose' }}</v-icon>
          <div :class="['header', { 'disabled': !moderatePolicyEnabled }]">{{$t('services.controlVariables.placementPolicy.moderatePolicy')}}</div>
        </div>
        <div class="column big">
          <div :class="['partial', { 'disabled': !moderatePolicyEnabled }]">
            <PartouTextField v-if="moderatePolicyEnabled" class='text-field' size="small" type="number" v-model="moderatePolicyPercentageFrom" :disabled="!editEnabled || !moderatePolicyEnabled" suffix="%" @input="onModeratePolicyPercentageFromChanged" @blur="onModeratePolicyPercentageFromBlurred" v-wholeNumberInputDirective :centerText="true" :placeholder="getModeratePolicyPercentagePlaceholder()" />
            <PartouTextField v-if="!moderatePolicyEnabled" class='text-field' size="small" v-model="unknownPercentage" :disabled="true" suffix="%" :center-text="true" />
          </div>
          <div :class="['partial', 'small', { 'disabled': !moderatePolicyEnabled }]">{{$t('services.controlVariables.placementPolicy.upToAndIncluding')}}</div>
          <div :class="['partial', { 'disabled': !moderatePolicyEnabled }]">{{getModeratePolicyPercentageUpToAndIncluding()}}%</div>
          <v-switch class="switch" inset v-model="moderatePolicyEnabled" :disabled="!editEnabled" @change="onModeratePolicyEnabledChanged" />
        </div>
      </div>
      <div class="row">
        <div class="column icon">
          <v-icon class="icon partou-check-or-close enabled-stroke">$vuetify.icons.partouCheck2</v-icon>
          <div class="header enabled">{{$t('services.controlVariables.placementPolicy.strictPolicy')}}</div>
        </div>
        <div class="column big">
          <div class="partial enabled">
            <PartouTextField class='text-field' size="small" type="number" v-model="strictPolicyPercentageFrom" :disabled="!editEnabled" suffix="%" @input="onStrictPolicyPercentageFromChanged" @blur="onStrictPolicyPercentageFromBlurred" v-wholeNumberInputDirective :centerText="true" :placeholder="getStrictPolicyPercentagePlaceholder()" :force-error-state="showStrictIsRequiredErrorMessage || showStrictLowerThanModerateErrorMessage"/>
          </div>
          <div class="partial small enabled">{{$t('services.controlVariables.placementPolicy.upToAndIncluding')}}</div>
          <div class="partial enabled">100%</div>
        </div>
      </div>
      <div class="row" style="margin-top: -10px;">
        <span v-if="showStrictIsRequiredErrorMessage" class="error-message">{{$t('services.controlVariables.placementPolicy.errorMessage.showStrictIsRequired')}}</span>
        <span v-if="showStrictLowerThanModerateErrorMessage" class="error-message">{{$t('services.controlVariables.placementPolicy.errorMessage.strictLowerThanModerate')}}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import '@/utils/listUtils'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import OccupancyPercentageSettings from '@/models/configuration/OccupancyPercentageSettings'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import PartouSlider from '@/components/PartouComponents/PartouSlider.vue'
import { wholeNumberInputDirective } from '@/utils/directives'
import PlacementPolicy from '@/models/configuration/PlacementPolicy'

@Component({
  components: { PartouButton, PartouTextField, PartouSlider },
  directives: { wholeNumberInputDirective }
})
export default class PlacementPolicySettings extends Vue {
  @Prop({ required: true })
  editEnabled! : boolean

  @Prop({ required: true })
  placementPolicy! : PlacementPolicy

  isValid = true
  showStrictIsRequiredErrorMessage = false
  showStrictLowerThanModerateErrorMessage = false

  unknownPercentage = '...'
  moderatePolicyEnabled = false
  moderatePolicyPercentageFrom?: number = undefined
  strictPolicyPercentageFrom?: number = 0
  occupancyPercentageSettings: OccupancyPercentageSettings | null = null

  async mounted () : Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    this.occupancyPercentageSettings = await configurationService.getConfigurationByKeyAsync({ key: 'occupancy_percentage', date: new Date() })
  }

  @Watch('placementPolicy', { immediate: true })
  onPlacementPolicyChanged () : void {
    this.moderatePolicyEnabled = this.placementPolicy.isModeratePolicyEnabled
    this.setModeratePolicyPercentageFromBasedOnServiceSettings()
    this.setStrictPolicyPercentageFromBasedOnServiceSettings()
  }

  @Watch('editEnabled')
  onEditEnabledChanged () : void {
    this.validateForm()
  }

  isFlexiblePolicyEnabled ():boolean {
    return this.moderatePolicyEnabled || (this.strictPolicyPercentageFrom !== undefined && this.strictPolicyPercentageFrom > 0)
  }

  setModeratePolicyPercentageFromBasedOnServiceSettings () {
    if (this.moderatePolicyEnabled) {
      if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding) {
        this.moderatePolicyPercentageFrom = this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding + 1
      } else {
        this.moderatePolicyPercentageFrom = 1
      }
    } else {
      this.moderatePolicyPercentageFrom = undefined
    }
  }

  setStrictPolicyPercentageFromBasedOnServiceSettings () {
    if (this.moderatePolicyEnabled) {
      if (this.placementPolicy.moderatePolicyPercentageUpToAndIncluding) {
        this.strictPolicyPercentageFrom = this.placementPolicy.moderatePolicyPercentageUpToAndIncluding + 1
      } else {
        this.strictPolicyPercentageFrom = 1
      }
    } else if (this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding) {
      this.strictPolicyPercentageFrom = this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding + 1
    } else {
      this.strictPolicyPercentageFrom = 0
    }
  }

  get amountOfMonthsOccupancyPercentageAverageIsCalculated (): number {
    return this.occupancyPercentageSettings?.calculateAverageOverInMonths || 6
  }

  getFlexiblePolicyPercentageFrom (): string {
    return this.strictPolicyPercentageFrom && parseInt(this.strictPolicyPercentageFrom.toString()) !== 0 ? '0' : this.unknownPercentage
  }

  getFlexiblePolicyPercentageUpToAndIncluding (): string {
    if (this.moderatePolicyEnabled) {
      if (this.moderatePolicyPercentageFrom && parseInt(this.moderatePolicyPercentageFrom.toString()) !== 0) {
        return (parseInt(this.moderatePolicyPercentageFrom.toString()) - 1).toString()
      }
    } else if (this.strictPolicyPercentageFrom && parseInt(this.strictPolicyPercentageFrom.toString()) !== 0) {
      return this.strictPolicyPercentageFrom ? (this.strictPolicyPercentageFrom - 1).toString() : this.unknownPercentage
    }

    return this.unknownPercentage
  }

  getModeratePolicyPercentageUpToAndIncluding (): string {
    if (this.moderatePolicyEnabled) {
      return this.strictPolicyPercentageFrom ? (this.strictPolicyPercentageFrom - 1).toString() : this.unknownPercentage
    }

    return this.unknownPercentage
  }

  getModeratePolicyPercentagePlaceholder (): string {
    return this.moderatePolicyEnabled && this.moderatePolicyPercentageFrom ? '' : '1'
  }

  getStrictPolicyPercentagePlaceholder (): string {
    if (this.strictPolicyPercentageFrom) {
      return ''
    }

    if (this.moderatePolicyEnabled && this.moderatePolicyPercentageFrom) {
      return (parseInt(this.moderatePolicyPercentageFrom.toString()) + 1).toString()
    }

    return '0'
  }

  onModeratePolicyEnabledChanged (newValue: boolean): void {
    if (!newValue) {
      this.moderatePolicyPercentageFrom = undefined
    } else {
      this.moderatePolicyPercentageFrom = 1
      if (!this.strictPolicyPercentageFrom || this.strictPolicyPercentageFrom <= 1) {
        this.strictPolicyPercentageFrom = 2
      }
    }

    this.validateForm()
  }

  onModeratePolicyPercentageFromChanged (newValue: string): void {
    if (newValue === '') {
      this.moderatePolicyPercentageFrom = undefined
    } else if (this.hasLeadingZero(newValue)) {
      this.moderatePolicyPercentageFrom = this.trimLeadingZeros(newValue)
    }

    if (newValue && parseInt(newValue.toString()) > 99) {
      // Set the value first to 100 and then set the value back to 99 on the NextTick
      // Otherwise Vue does not detect the change if the current value is already 99 and the value greater than 99 is kept
      this.moderatePolicyPercentageFrom = 100
      this.$nextTick(() => {
        this.moderatePolicyPercentageFrom = 99
        this.validateForm()
      })
    }

    this.validateForm()
  }

  onModeratePolicyPercentageFromBlurred (): void {
    if (!this.moderatePolicyPercentageFrom || this.moderatePolicyPercentageFrom < 1) {
      this.moderatePolicyPercentageFrom = 1
      if (!this.strictPolicyPercentageFrom || this.strictPolicyPercentageFrom <= 1) {
        this.strictPolicyPercentageFrom = 2
      }
    }

    this.validateForm()
  }

  onStrictPolicyPercentageFromChanged (newValue: string): void {
    if (newValue === '') {
      this.strictPolicyPercentageFrom = undefined
    } else if (this.hasLeadingZero(newValue)) {
      this.strictPolicyPercentageFrom = this.trimLeadingZeros(newValue)
    }

    if (newValue && parseInt(newValue.toString()) > 100) {
      // Set the value first to 101 and then set the value back to 100 on the NextTick
      // Otherwise Vue does not detect the change if the current value is already 100 and the value greater than 100 is kept
      this.strictPolicyPercentageFrom = 101
      this.$nextTick(() => {
        this.strictPolicyPercentageFrom = 100
        this.validateForm()
      })
    }

    this.validateForm()
  }

  onStrictPolicyPercentageFromBlurred (): void {
    if (!this.strictPolicyPercentageFrom) {
      if (this.moderatePolicyEnabled && this.moderatePolicyPercentageFrom) {
        this.strictPolicyPercentageFrom = parseInt(this.moderatePolicyPercentageFrom.toString()) + 1
      } else {
        this.strictPolicyPercentageFrom = 0
      }
    }

    this.validateForm()
  }

  validateForm (): void {
    this.showStrictIsRequiredErrorMessage = false
    this.showStrictLowerThanModerateErrorMessage = false

    if (this.strictPolicyPercentageFrom === undefined) {
      this.showStrictIsRequiredErrorMessage = true
    } else if (this.moderatePolicyEnabled && this.moderatePolicyPercentageFrom && parseInt(this.moderatePolicyPercentageFrom.toString()) >= parseInt(this.strictPolicyPercentageFrom.toString())) {
      this.showStrictLowerThanModerateErrorMessage = true
    }

    this.$emit('onPlacementPolicyIsValidChanged', !this.showStrictIsRequiredErrorMessage && !this.showStrictLowerThanModerateErrorMessage)
    if (!this.showStrictIsRequiredErrorMessage && !this.showStrictLowerThanModerateErrorMessage && (!this.moderatePolicyEnabled || this.moderatePolicyPercentageFrom)) {
      this.emitPlacementPolicyChanged()
    }

    this.$forceUpdate() // If there is time, find out why this is necessary
  }

  emitPlacementPolicyChanged () {
    let flexiblePolicyPercentageUpToAndIncluding: number | undefined
    let moderatePolicyPercentageUpToAndIncluding: number | undefined

    if (this.moderatePolicyEnabled) {
      flexiblePolicyPercentageUpToAndIncluding = this.moderatePolicyPercentageFrom! - 1 // eslint-disable-line @typescript-eslint/no-non-null-assertion
      moderatePolicyPercentageUpToAndIncluding = this.strictPolicyPercentageFrom! - 1 // eslint-disable-line @typescript-eslint/no-non-null-assertion
    } else if (this.strictPolicyPercentageFrom! > 0) { // eslint-disable-line @typescript-eslint/no-non-null-assertion
      flexiblePolicyPercentageUpToAndIncluding = this.strictPolicyPercentageFrom! - 1 // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }

    this.$emit('onPlacementPolicyChanged', this.moderatePolicyEnabled, flexiblePolicyPercentageUpToAndIncluding, moderatePolicyPercentageUpToAndIncluding)
  }

  hasLeadingZero (str: string): boolean {
    if (str.length > 1 && str[0] === '0') {
      return true
    }

    return false
  }

  trimLeadingZeros (str: string): number {
    const trimmedStr = str.replace(/^0+/, '')
    return trimmedStr === '' ? 0 : parseInt(trimmedStr)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .placement-policy-container {
    width: 100%;
    display: flex;
    color: $partou-primary-black-ninety;

    .description-container {
      width: 50%;
    }

    .controls-container {
      width: 50%;

      > *:not(:first-child):not(:last-child) {
        padding-top: 16px;
      }

      .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .column {
          width: 174px;
          display: flex;

          &.icon {
            padding-top: 14px;
          }

          &.big {
            width: 300px;
            margin-left: 15px;
          }

          .partial {
            width: 81px;
            line-height: 40px;
            margin-right: 8px;
            text-align: center;

            &.small {
              width: 26px;
            }
          }

          .switch {
            margin-top: 4px;
          }

          .icon {
            margin-right: 8px;
          }
        }

        .header {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
        }
      }

      svg {
        &.icon {
          &.partou-calendar-shine {
            fill: $partou-primary-black-eighty;
            margin-top: 1px;
            width: 16px;
            height: 16px;
          }

          &.partou-union {
            fill: $partou-primary-black-eighty;
            width: 16px;
            height: 16px;
          }

          &.partou-check-or-close {
            margin-left: 0px;
            width: 18px !important;
            height: 18px !important;
          }
        }
      }
    }
  }

  .text-field::v-deep {
    input {
      padding: 0px 0 0 23px;
    }

    .v-input__slot {
      .v-input__append-inner {
        margin-right: 11px;
        padding-left: 0;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .enabled-stroke {
    stroke: $partou-primary-black-ninety;
  }

  .disabled {
    color: $partou-primary-black-thirty;
    fill: $partou-primary-black-thirty;
  }

  .disabled-stroke {
    stroke: $partou-primary-black-thirty;
  }
</style>
