import IServiceState, { ISetActiveServicePayload, STATE } from '@/store/serviceModule/IServiceState'
import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Service } from '@/models'

export const MUTATIONS = {
  setServices: 'setServices',
  setActiveServiceId: 'setActiveServiceId'
}

export const serviceMutations: MutationTree<IServiceState> = {
  setServices (state: IServiceState, services: Array<Service>) {
    Vue.set(state, STATE.services, services)
  },

  setActiveServiceId (state: IServiceState, payload: ISetActiveServicePayload) {
    Vue.set(state, STATE.activeServiceId, payload.activeServiceId)
  }
}
