<template>
  <v-app id="app">
    <v-main id="main-content">
      <AppMenu/>
      <v-container>
        <router-view/>
        <div id="app-version">Versie: {{VUE_APP_VERSION}}</div>
        <v-snackbar bottom left v-model="snackbarVisible" :timeout="snackbarTimeout" class="snackbar">
          <component :is="snackbarContent" />
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import 'document-register-element/build/document-register-element'
import AppMenu from './AppMenu.vue'
import { ACTIONS, STATE } from '@/store/snackbarModule'
import { NAMESPACES } from '@/store'
import { namespace } from 'vuex-class'
import BackToActivationsSnackbarContent from '../ServicesPage/ServiceActivations/components/BackToActivationsSnackbarContent.vue'

const snackbarModule = namespace(NAMESPACES.snackbar)

@Component({
  components: { AppMenu, BackToActivationsSnackbarContent }
})
export default class App extends Vue {
  @snackbarModule.State(STATE.content) snackbarContent!: string | null
  @snackbarModule.State(STATE.timeout) snackbarTimeout!: number
  @snackbarModule.State(STATE.visible) snackbarVisible!: boolean
  @snackbarModule.State(STATE.targetPath) snackbarTargetPath?: string
  @snackbarModule.Action(ACTIONS.hideSnackbar) hideSnackbarAction!: () => void

  public VUE_APP_VERSION = process.env.VUE_APP_VERSION

  @Watch('$route')
  onRouteChanged (to: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (to.path !== this.snackbarTargetPath) {
      this.hideSnackbarAction()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
@import '@/styles/variables/variables.scss';

#app-version {
  margin-top: 13px;
  text-align: center;
  font-size: 12px;
  color: $partou-primary-black-ninety;
  z-index: 5000;
}

.home + #app-version {
  color: $partou-primary-white;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

.snackbar::v-deep {
  .v-snack__wrapper {
    margin: 8px;

    .v-snack__content {
      padding: 8px;
      padding-left: 16px;
    }
  }
}
</style>
