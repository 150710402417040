<template>
  <div>
    <v-alert v-if="!isWaitingListEnabled" dense text :icon="false" class="error--text" type="warning">
      {{ $t('services.waitingList.waitingListDisabled') }}
    </v-alert>
    <PartouDataTable
      ref="partou-data-table"
      :headers="headers"
      :items="items"
      :tableOptions="tableOptions"
      :totalCount="totalCount"
      :showExpand="true"
      :isLoading="isLoading"
      :itemClass="getRowBackground"
      @expandedChanged="onExpandedChanged"
      @onOptionsChanged="onTableOptionsChangedAsync">
      <!-- custom column templates -->
      <template v-slot:[`item.subscriptionService.subscription.name`]="{item}">
        <v-tooltip top v-if="isUnknownSubcription(item.subscriptionService.subscription.name)" max-width="180px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon ref="unkown-subscription-icon" v-bind="attrs" v-on="on" class="red-text">$vuetify.icons.values.error</v-icon>
          </template>
          <p class="text-center white--text mt-4">{{ $t('services.waitingList.unknownSubscriptionTooltip') }}</p>
        </v-tooltip>
      </template>
      <template #item.child.childName="{ item }"> <!-- eslint-disable-line -->
        {{ `${item.child.firstName} ${item.child.lastName}` }}
      </template>
      <template #item.child.dateOfBirth="{ item }"> <!-- eslint-disable-line --><!-- disabled, due to that the directive doesn't support any modifier -->
        {{ getFormattedDate(item.child.dateOfBirth, 'DD-MM-YYYY') }}
      </template>
      <template #item.createdAt="{ item }"> <!-- eslint-disable-line --><!-- disabled, due to that the directive doesn't support any modifier -->
        <!-- TODO: remove Z suffix as temp solution until everything is stored as UTC in backend (KF-1329) -->
        {{ getFormattedDate(item.createdAt, 'DD-MM-YYYY HH:mm:ss') }}
      </template>
      <template #item.validFrom="{ item }"> <!-- eslint-disable-line --><!-- disabled, due to that the directive doesn't support any modifier -->
        {{ getFormattedDate(item.validFrom, 'DD-MM-YYYY') }}
      </template>
      <template #item.serviceVarieties="{ item }"> <!-- eslint-disable-line --><!-- disabled, due to that the directive doesn't support any modifier -->
        {{ getWaitinglistVarietyNames(item.waitingListPlaces) }}
      </template>
      <template #item.priority="{ item }"> <!-- eslint-disable-line --><!-- disabled, due to that the directive doesn't support any modifier -->
        {{ getPriorityText(item.priority) }}
      </template>

      <template #top>

      <!-- tabs for day selection -->
        <v-tabs v-model="activeTabIndex" fixed-tabs :show-arrows="false" class="mb-2">
          <v-tab ref="day-tab" v-for="(day, index) in selectableDays" :key="day.id" @click="onTabClicked(day.dayOfWeek)" v-bind:class="{ divider: index !== selectableDays.length - 1 }">
            <div class="day-tab mb-4">
              <h2 class="day-tab-text">{{ day.dayOfWeek | shortDate }}</h2>
            </div>
          </v-tab>
        </v-tabs>
      </template>

      <!-- expand template -->
      <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <WaitingListTableExpanded v-if="!isLoadingExpandedData" :locationInquiry="expandedItem" :status="getBookingStatus()"></WaitingListTableExpanded>
          </td>
      </template>
    </PartouDataTable>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator'
import BaseEntityTable from '@/pages/_base/BaseEntityTable'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import WaitingListTableExpanded from './WaitingListTableExpanded.vue'
import { WaitingListTableOptions } from './WaitingListTableOptions'
import moment from 'moment'
import { Booking, DayOfWeek, LocationInquiry, WaitingListPlace } from '@/models'
import { getCurrentDayOfWeek } from '@/models/enums/DayOfWeek'
import { PriorityOption } from '@/pages/ServicesPage/WaitingList/PriorityOption'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import WaitingListPlacePriority from '@/models/enums/WaitingListPlacePriority'
import WaitingListPlaceStatus from '@/models/enums/WaitingListPlaceStatus'

@Component({
  components: { PartouCard, PartouDataTable, WaitingListTableExpanded, PartouCheckBox }
})
export default class WaitingListTable extends BaseEntityTable<LocationInquiry> {
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)

  headers: DataTableHeader[] = [
    { text: '', sortable: false, value: 'subscriptionService.subscription.name', width: '40px' },
    { text: 'services.waitingList.table.columns.childName', sortable: false, value: 'child.childName' },
    { text: 'services.waitingList.table.columns.dateOfBirth', sortable: false, value: 'child.dateOfBirth', width: '130px' },
    { text: 'services.waitingList.table.columns.createdAt', sortable: false, value: 'createdAt', width: '130px' },
    { text: 'services.waitingList.table.columns.validFrom', sortable: false, value: 'validFrom', width: '130px' },
    { text: 'services.waitingList.table.columns.serviceVarieties', sortable: false, value: 'serviceVarieties', width: '130px' },
    { text: 'services.waitingList.table.columns.priority', sortable: false, value: 'priority', width: '220px' },
    { text: '', value: 'data-table-expand' }
  ]

  possiblePriorities: PriorityOption[] = []

  @Prop({ required: true })
  items!: LocationInquiry[]

  @Prop({ required: true, default: () => { return {} } })
  expandedItem! : Booking

  @Prop({ required: true })
  serviceId!: string

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<LocationInquiry>

  isWaitingListEnabled = true

  tableOptions!: WaitingListTableOptions

  created () : void {
    for (const waitingListPlacePriority in WaitingListPlacePriority) {
      this.possiblePriorities.push({ value: waitingListPlacePriority, text: this.$t(`services.waitingList.waitingListPlacePriority.${waitingListPlacePriority.toLowerCase()}`).toString() })
    }

    this.tableOptions = {
      sortBy: ['priorityRank', 'bookingId'],
      sortDesc: [false, true],
      page: 1,
      itemsPerPage: 50,
      itemsPerPageOptions: [5, 10, 25, 50, 100],
      filters: {
        selectedDay: getCurrentDayOfWeek(),
        selectedServiceVarieties: {
          KDV: true,
          VSO: true,
          NSO: true
        },
        search: '',
        serviceId: this.serviceId
      }
    }
  }

  mounted () : void {
    this.onTableOptionsChangedAsync(this.tableOptions)

    this.serviceService.getOneAsync(this.serviceId).then(x => {
      this.isWaitingListEnabled = !x?.serviceSettings?.[0].waitingListFrozenSince
    })
  }

  selectableDays: { id: number, dayOfWeek : DayOfWeek }[] = [
    { id: 0, dayOfWeek: DayOfWeek.Monday },
    { id: 1, dayOfWeek: DayOfWeek.Tuesday },
    { id: 2, dayOfWeek: DayOfWeek.Wednesday },
    { id: 3, dayOfWeek: DayOfWeek.Thursday },
    { id: 4, dayOfWeek: DayOfWeek.Friday }
  ]

  activeTabIndex = this.selectableDays.find(x => x.dayOfWeek === getCurrentDayOfWeek())?.id ?? 0
  isLoadingExpandedData = false

  @Emit('onExpandedChanged')
  onExpandedChanged (items: LocationInquiry[]) : LocationInquiry[] {
    return items
  }

  @Emit('onTabClicked')
  onTabClicked (dayOfWeek: DayOfWeek) : void {
    this.tableOptions.filters.selectedDay = dayOfWeek
    this.partouDataTable.onFilterChanged()
    this.collapseAllInTable()
  }

  @Watch('serviceId')
  onServiceIdChanged () : void {
    this.tableOptions.filters.serviceId = this.serviceId
    this.partouDataTable.onFilterChanged()
  }

  getBookingStatus () : string | null {
    return this.expandedItem.bookingStatus?.status ?? null
  }

  getFormattedDate (date : Date, format : string) : string {
    return moment(date).format(format)
  }

  getWaitinglistVarietyNames (items : WaitingListPlace[]) : string {
    return items.filter((x, i, arr) => arr.findIndex(y => y.serviceVariety.name === x.serviceVariety.name) === i).sort((a, b) => { return b.serviceVariety.name.localeCompare(a.serviceVariety.name) }).map(x => x.serviceVariety.name).join(', ')
  }

  getWaitingListStatus (waitingListPlaces : WaitingListPlace[]) : string {
    const selectedDay = this.tableOptions.filters.selectedDay
    const selectedDayWaitingListPlaces = waitingListPlaces.filter(x => x.dayOfWeek === selectedDay)
    const statuses = selectedDayWaitingListPlaces.flatMap(x => x.status)

    if (statuses.some(x => x === WaitingListPlaceStatus.Approached)) {
      return WaitingListPlaceStatus.Approached
    }

    if (statuses.some(x => x === WaitingListPlaceStatus.ApproachedByFlexKids)) {
      return WaitingListPlaceStatus.ApproachedByFlexKids
    }

    if (statuses.every(x => x === WaitingListPlaceStatus.Accepted)) {
      return WaitingListPlaceStatus.Accepted
    }

    return WaitingListPlaceStatus.Waiting
  }

  getPriorityText (priorityValue: string) : string {
    return this.possiblePriorities.find(priorityOption => priorityOption.value === priorityValue)?.text ?? this.$t('services.waitingList.unknown').toString()
  }

  getRowBackground (item : LocationInquiry) : string {
    return item.subscriptionService?.subscription.name === 'Onbekend' ? 'disabled-row' : ''
  }

  isUnknownSubcription (subscriptionName : string) : boolean {
    return subscriptionName === 'Onbekend'
  }

  collapseAllInTable () {
    this.partouDataTable.collapseAll()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
  .full-width {
    width: 100%;
  }

  .divider {
    border-right: 1px solid #C4C4C4 !important;
  }
  .red-text {
    color: $partou-red !important;
  }

  .error--text {
    font-size: 0.875rem !important;
  }

  .day-tab-text {
    color: $partou-black !important;
  }
</style>
