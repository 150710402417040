<template>
  <div>
    <h1 class="pl-8 pt-4">{{ $t('pages.audit.pageTitle') }}</h1>
    <v-row class="pl-8 sub-menu">
      <v-col class="flex-grow-0">
        <ServicePicker
          v-model="filters.service"
          @input="onServiceChanged"
          :services="services"
          :isClearable="true"
        />
      </v-col>
      <v-col class="flex-grow-0">
        <PartouSearchField
          v-model="searchTerms"
          @input="onSearchChanged"
        />
      </v-col>
    </v-row>
    <PartouCard class="mt-2 full-width">
      <template slot="card-body">
        <div>
          <AuditTableFilters
            v-model="filters"
            :users="auditUsers"
            @input="onFilterChanged"
          />
        </div>
        <div>
          <PartouDataTable
            class="audit-table"
            ref="partou-data-table"
            :headers="tableHeaders"
            :items="auditItems"
            :totalCount="totalCount"
            :tableOptions="tableOptions"
            :isLoading="isLoading"
            @onOptionsChanged="onTableOptionsChanged">
            <template #item.createdAt="{ item }"> <!-- eslint-disable-line -->
              <p class="audit-table-item">{{ item.createdAt | formatDateTime }}</p>
            </template>
            <template #item.category="{ item }"> <!-- eslint-disable-line -->
              <p class="audit-table-item">{{ $t(`pages.audit.table.auditDetails.typeNames.${item.category}`)  }}</p>
            </template>
            <template #item.change="{ item }"> <!-- eslint-disable-line -->
              <ServiceAuditProductItem :value="item" />
            </template>
            <template #item.location="{ item }"> <!-- eslint-disable-line -->
              <p class="audit-table-item raampje">{{ item.location }}</p>
            </template>
            <template #item.actor="{ item }"> <!-- eslint-disable-line -->
              <p class="audit-table-item">{{ translateActor(item.actor) }}</p>
            </template>
          </PartouDataTable>
        </div>
      </template>
    </PartouCard>
  </div>
</template>

<script lang="ts">
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import ServicePicker from '@/components/ServicePicker'
import PartouSearchField from '@/components/PartouComponents/PartouSearchField.vue'
import PartouDataTable, { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import DataTableOptionsN from '@/components/PartouComponents/PartouDataTable/DataTableOptionsN'
import ServiceAuditProductItem from '@/features/ServiceAudit/components/AuditItemTemplates/ServiceAuditProductItem.vue'
import ServiceAuditTableFilters from '@/features/ServiceAudit/components/ServiceAuditTableFilters.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { namespace } from 'vuex-class'
import { GetServiceAuditQueryVariables, OrderByList, OrderDirection, Service, ServiceAuditUser } from '@/models'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import { Vue, Component } from 'vue-property-decorator'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { ServiceAuditItem, ServiceAuditFilterSettings } from '@/models/Audit'
import { IAuditService } from '@/features/ServiceAudit/services/IAuditService'
import i18n from '@/plugins/i18n'
import moment from 'moment'
import { AUDIT_SYSTEM_ACTOR } from '@/constants/constants'

const serviceModule = namespace(NAMESPACES.service)

const DEFAULT_ITEMS_PER_PAGE = 50
const COLUMN_SORT_MAPPING = {
  createdAt: 'created_at',
  category: 'category',
  location: 'service.name',
  actor: 'actor'
}

type ColumnSortKey = keyof typeof COLUMN_SORT_MAPPING

@Component({
  components: { PartouCard, ServicePicker, PartouSearchField, AuditTableFilters: ServiceAuditTableFilters, PartouDataTable, ServiceAuditProductItem }
})
export default class ServiceAudit extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  auditService: IAuditService = container.get<IAuditService>(SERVICE_IDENTIFIERS.IAuditService)

  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => Promise<void>
  @serviceModule.State(STATE.service.services) services!: Array<Service>

  tableHeaders: DataTableHeader[] = [
    { text: 'pages.audit.table.headers.date', width: '160px', value: 'createdAt', sortable: true },
    { text: 'pages.audit.table.headers.type', width: '160px', value: 'category', sortable: true },
    { text: 'pages.audit.table.headers.change', value: 'change', sortable: false },
    { text: 'pages.audit.table.headers.location', width: '160px', value: 'location', sortable: true },
    { text: 'pages.audit.table.headers.actor', width: '160px', value: 'actor', sortable: true, sortBy: 'item.actor' }
  ]

  tableOptions: DataTableOptionsN = {
    sortBy: ['createdAt'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    itemsPerPageOptions: [5, 10, 25, 50, 100]
  }

  filters: ServiceAuditFilterSettings = {
    service: null,
    category: null,
    actor: null,
    startDate: null,
    endDate: null
  }

  searchTerms = ''
  auditUsers: ServiceAuditUser[] = []
  auditItems: ServiceAuditItem[] = []
  totalCount = 0
  isLoading = false

  get sortedServices () : Array<Service> {
    return this.services.sort((a, b) => a.name && b.name && a.name > b.name ? 1 : -1)
  }

  translateActor (userId: string): string {
    if (userId === AUDIT_SYSTEM_ACTOR) {
      return i18n.t('pages.audit.table.auditDetails.actors.system').toString()
    }

    return this.auditUsers.find(user => user.userId === userId)?.userName ??
      i18n.t('pages.audit.table.auditDetails.actors.deleted').toString()
  }

  async mounted (): Promise<void> {
    this.isLoading = true
    await this.getServices()
    this.auditUsers = await this.auditService.getServiceAuditUsersAsync()
    await this.getAuditItemsAsync()
    this.isLoading = false
  }

  async getAuditItemsAsync (): Promise<void> {
    this.isLoading = true

    const pageSize = this.tableOptions.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE

    const sortColumn = this.tableOptions.sortBy[0] as ColumnSortKey
    const sortOptions: OrderByList = {
      field: COLUMN_SORT_MAPPING[sortColumn] || COLUMN_SORT_MAPPING.createdAt,
      direction: this.tableOptions.sortDesc[0] ? OrderDirection.Desc : OrderDirection.Asc
    }

    const startDate = this.filters.startDate ? moment(this.filters.startDate).format('YYYY-MM-DD') : null
    const endDate = this.filters.endDate ? moment(this.filters.endDate).format('YYYY-MM-DD') : null

    const queryVariables: GetServiceAuditQueryVariables = {
      serviceId: this.filters.service?.id,
      category: this.filters.category,
      actor: this.filters.actor,
      productId: this.filters.categorySubFilter?.product?.id,
      serviceVariety: this.filters.categorySubFilter?.serviceVariety,
      productType: this.filters.categorySubFilter?.changeType,
      minChangeDate: startDate,
      maxChangeDate: endDate,
      searchOn: this.searchTerms,
      orderByList: sortOptions,
      offset: (this.tableOptions.page - 1) * pageSize,
      limit: pageSize
    }

    const tableData = await this.auditService.getServiceAuditAsync(queryVariables)
    this.auditItems = tableData.items
    this.totalCount = tableData.totalCount
    this.isLoading = false
  }

  onServiceChanged (service: Service): Service {
    this.filters.service = service
    this.getAuditItemsAsync()
    return service
  }

  onSearchChanged (searchTerms: string) : string {
    this.searchTerms = searchTerms
    this.getAuditItemsAsync()
    return searchTerms
  }

  onFilterChanged (filters: ServiceAuditFilterSettings): ServiceAuditFilterSettings {
    this.filters = { ...filters }
    this.getAuditItemsAsync()
    return filters
  }

  onTableOptionsChanged (tableOptions: DataTableOptionsN): DataTableOptionsN {
    this.tableOptions = tableOptions
    this.getAuditItemsAsync()
    return tableOptions
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';
.audit-table {
  padding: 16px;
  margin-top: 16px;

  td {
    padding: 12px 16px !important;
  }
}

.audit-table-item {
  min-width: 160px;
  max-width: 160px;
  color: $partou-primary-black-eighty;
}
</style>
