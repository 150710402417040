<template>
    <div>
      <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.orderProductSettings.title')}}</h2>
      <div class="action-container">
        <div class="text-container">
          <p>{{$t('services.controlVariables.orderProductSettings.description')}}</p>
        </div>
        <div class="controls-container d-flex flex-column ml-8">
          <div class="d-flex flex-column fill-width">
            <h2>{{$t('services.controlVariables.orderProductSettings.showLinkToPartouOffers')}}</h2>
          <div class="d-flex flex-row">
            <v-switch ref="show-link-to-partou-offers-switch" class="switch mt-0 mb-0" v-model="showLinkToPartouOffers" :disabled="!editEnabled"/>
            <div class="d-flex flex-row mt-1">
              <p class='d-flex justify-center'>{{$t('services.controlVariables.orderProductSettings.showLinkToPartouOffersDescription')}}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class OrderProductSettings extends Vue {
  @VModel({ required: true })
  showLinkToPartouOffers! : boolean

  @Prop({ required: true })
  editEnabled! : boolean
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';
@import '@/styles/variables/variables.scss';

.action-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-container {
    flex: 1;
  }

  .controls-container {
    width: 50%;
    display: flex;
    gap: 0px;

    h2 {
      color: $partou-secondary-bordeaux !important;
    }
  }
}
</style>
