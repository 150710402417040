<template v-slot:card-body>
  <div class="container pt-0 pr-4 pb-0 pl-0">
    <span class="pb-1 pl-6">{{$t('pages.ServiceOffer.dateSelection')}}</span>
    <DateInputField
      :use-single-line="false"
      :label="$t('pages.ServiceOffer.dateSelection')"
      :value="date"
      class="date-input"
      @input="onDateChanged">
    </DateInputField>
  </div>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'

@Component({
  components: { PartouCard, DateInputField },
  methods: { moment: () => { return moment() } }
})
export default class ServiceOfferDatePicker extends Vue {
  @Prop({ required: true })
  date?: Date

  onDateChanged (date : Date) : void {
    // If saturday or sunday is selected, select monday instead
    const weekDay = moment(date).isoWeekday() // 1: Monday, ..., 7: Sunday
    if (weekDay > 5) {
      date = moment(date).startOf('week').toDate()
    }

    const midnightDate = moment(date).startOf('day').toDate()
    this.$emit('datePicked', midnightDate)
  }
}
</script>

<style lang="scss" scoped>
  .container {
    .date-input::v-deep {
      .v-input__slot {
        margin: 0;
      }

      .v-text-field__details {
        min-height: 0 !important;

        .v-messages {
          min-height: 0 !important;
        }
      }
    }
  }
</style>
