import ISnackbarState, { IShowSnackbarPayload, STATE } from './ISnackbarState'
import Vue from 'vue'
import { MutationTree } from 'vuex'

export const MUTATIONS = {
  showSnackbar: 'showSnackbar',
  hideSnackbar: 'hideSnackbar'
}

export const snackbarMutations: MutationTree<ISnackbarState> = {
  showSnackbar (state: ISnackbarState, payload: IShowSnackbarPayload) {
    Vue.set(state, STATE.content, payload.content)
    Vue.set(state, STATE.timeout, payload.timeout)
    Vue.set(state, STATE.targetPath, payload.targetPath)
    Vue.set(state, STATE.visible, true)
  },

  hideSnackbar (state: ISnackbarState) {
    Vue.set(state, STATE.visible, false)
    Vue.set(state, STATE.targetPath, undefined)
  }
}
