<template>
  <v-row>
    <v-col class="pt-1"><p class="snackbar-content">{{ $t('services.serviceActivations.backNavigation.text') }}</p></v-col>
    <v-col class="flex-grow-0 pt-0">
      <router-link :to="activationsPath"><v-icon class="back-icon" @click="hideSnackbarAction">$vuetify.icons.partouArrowLeft</v-icon></router-link>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { findRouteByName, ROUTES } from '@/router/routes'
import { NAMESPACES } from '@/store'
import { STATE } from '@/store/serviceModule'
import { ACTIONS } from '@/store/snackbarModule'
import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const serviceModule = namespace(NAMESPACES.service)
const snackbarModule = namespace(NAMESPACES.snackbar)

@Component
export default class BackToActivationsSnackbarContent extends Vue {
  @serviceModule.State(STATE.activeServiceId) activeServiceId!: string
  @snackbarModule.Action(ACTIONS.hideSnackbar) hideSnackbarAction!: () => void

  get activationsPath () : string {
    const route = findRouteByName(ROUTES.serviceActivations)
    if (!route || !this.activeServiceId) {
      return '/'
    }

    return route.path.replace(':entityId', this.activeServiceId)
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.snackbar-content {
  color: #FFFFFF;
  margin-block-end: 0;
}

.back-icon::v-deep {
  path {
    fill: #FFFFFF;
  }
}
</style>
