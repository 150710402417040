<template v-if="showExtendDialog">
  <PartouDialog
    :width="600"
    :showDialog="showExtendDialog"
    :showLoaderOnConfirm="true"
    :onNotifyAcceptedAsync="onNotifyAcceptedAsync"
    :cancelTranslationString="$t('waitingListPropositions.table.expanded.cancelHandling')"
    :confirmTranslationString="$t('waitingListPropositions.table.expanded.confirmExtendButton')"
    @canceled="onPropositionActionCancelled"
  >
    <h2 class="mb-4">
        {{ $t('waitingListPropositions.table.expanded.extendDialogTitle') }}
    </h2>
    <p v-html="$t('waitingListPropositions.table.expanded.extendDescription', { name: `${currentProposition.locationInquiry.child.firstName} ${currentProposition.locationInquiry.child.lastName}`})" />
    <p>{{ $t('waitingListPropositions.table.expanded.extendDateLabel') }}</p>

    <DateInputField
      class="date"
      label="extendDateLabel"
      :allowWeekends="true"
      :use-single-line="false"
      :placeholder="$t('waitingListPropositions.table.expanded.expireDate')"
      v-model="extendedDate"
      :minDate="getMinDate"
      :startAtMinDate="true"
      @input="OnExtendedDateChanged"
      />
    <div class="button-error-container">
      <span v-if="plannerActionError" class="error-message">
        {{ $t('services.controlVariables.triggerCalculateCapacityError') }}
      </span>
    </div>
  </PartouDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import { WaitingListProposition } from '@/models'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import moment from 'moment'

@Component({
  components: {
    PartouDialog,
    DateInputField
  }
})
export default class ExtendPropositionDialog extends Vue {
  @Prop({ required: true })
  showExtendDialog!: boolean

  @Prop({ required: true })
  currentProposition!: WaitingListProposition

  @Prop({ required: false, default: false })
  plannerActionError!: boolean

  @Prop({ required: true })
  OnWaitinglistPropositionExtendedAsync? : () => Promise<void>

  get getMinDate () : Date {
    // expire date should be at lease 7 days after current expire on
    const minDate = this.currentProposition.expiresOn ? moment(this.currentProposition.expiresOn).add(7, 'days').format('YYYY-MM-DD') : new Date()
    return new Date(minDate)
  }

  extendedDate : Date | null = null

  @Emit('extendedDateChanged')
  OnExtendedDateChanged (extendedDate: Date) : Date {
    return extendedDate
  }

    @Emit('canceled')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPropositionActionCancelled () {}

    async onNotifyAcceptedAsync () : Promise<void> {
      if (this.OnWaitinglistPropositionExtendedAsync) {
        await this.OnWaitinglistPropositionExtendedAsync()
      }
    }
}
</script>

<style scoped>
.date{
  max-width: 300px;
}

.button-error-container {
  display: flex;
  line-break: 16px;

  .error-message {
    padding-bottom: 5px;
  }
}
</style>
