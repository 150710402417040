<template>
  <v-textarea
    v-on="$listeners" v-bind="$attrs"
    v-model="textValue"
    ref="textarea"
    :clearable="this.clearable"
    clear-icon="$vuetify.icons.partouDelete"
    :placeholder="noDataText"
    :disabled="disabled"
    :label="label + (label && isRequired ? ' *': '')"
    :rules="[maxLengthRule]"
    @blur="onBlur"
    @input="onChanged"
    :error="!isComponentValid && internalErrorMessage !== ''"
    :error-messages="$t(internalErrorMessage)"
    :class="{ 'icon-left': iconName && iconPosition === 'left', 'icon-right': iconName && iconPosition === 'right', filled: filled && !disabled }">
  </v-textarea>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'

// function that's being called by vuetify rules
type ruleFunction = (arg0: string) => (Promise<boolean | string>) | boolean | string;

@Component({})
export default class PartouTextArea extends Vue {
  @Prop({ required: false, default: '' })
  label!: string

  @Prop({ required: false, default: 'none' })
  iconPosition!: 'none' | 'left' | 'right'

  @Prop({ required: false, default: '' })
  iconName!: string

  @Prop({ required: false, default: false })
  isRequired!: boolean

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ required: false, default: () => [] })
  rules! : ruleFunction[]

  @Prop({ required: false, default: () => '' })
  errorMessage! : string

  @Prop({ required: false, default: false })
  validateOnBlur?: boolean

  @Prop({ required: true })
  value! : string | null

  @Prop({ required: false })
  noDataText? : string

  @Prop({ required: false })
  clearable? : boolean

  @Prop({ required: false })
  maxLength? : number

  isComponentValid = false
  internalErrorMessage = this.errorMessage
  textValue: string | null = this.value
  initial = true
  filled = false

  @Watch('value', { immediate: true })
  onValueChange (newValue: string | null) {
    this.textValue = newValue
  }

  onBlur (value: string) : void {
    if (this.validateOnBlur) {
      this.isValidAsync(value)
    }
  }

  onChanged (value: string) : void {
    if (!this.validateOnBlur) {
      this.isValidAsync(value)
    }
  }

  maxLengthRule (value: string) : boolean | string {
    if (this.maxLength && value && value.length > this.maxLength) {
      return this.$t('form.tooLong').toString().replace('{maxLength}', this.maxLength.toString())
    }

    return true
  }

  async isValidAsync (value: string): Promise<boolean | string> {
    this.isComponentValid = false
    this.filled = !!value
    let errMsg = ''
    const ruleFunctions: ruleFunction[] = [...this.rules]

    if (this.isRequired) {
      ruleFunctions.unshift(this.checkRequiredRule)
    }

    for (const func of ruleFunctions) {
      const resp = await func(value)
      if (resp === false) {
        errMsg = 'Validation failed'
        break
      } else if (typeof resp === 'string') {
        errMsg = resp.toString()
        break
      }
    }

    this.internalErrorMessage = errMsg
    this.isComponentValid = !this.internalErrorMessage
    return this.isComponentValid
  }

  checkRequiredRule (value: string): boolean | string {
    const errorMsgIfNotValid = 'form.isRequired'
    return !!value || errorMsgIfNotValid
  }

  @Watch('errorMessage', { immediate: true })
  onErrorMessageChange () : void {
    this.internalErrorMessage = this.errorMessage
  }

  @Emit('onIconClicked')
  onIconClicked () : void {
    // emits click event
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/variables/variables.scss';

  .v-textarea::v-deep {
    opacity: 1;
    margin-top: 0;

    textarea::placeholder {
      color: $partou-primary-black-eighty;
    }

    &.filled{
      input
      {
        color: $partou-primary-black !important;
      }
    }

    :not(.filled) {
      .v-label
      {
        color: $partou-primary-black-fourty !important;
      }
    }

    .v-input__icon {
      margin-top: 32px;
    }

    .v-messages__wrapper {
      display: flex;
      justify-content: center;
    }

    .v-input__slot {
      min-height: 60px;
      border-radius: 30px;
      background-color: $partou-primary-white;
      box-shadow: $partou-elevation-large-subtle;

      &:before {
        border: 0 !important;
      }
      &:after {
        border: 0 !important;
      }

      .v-text-field__slot {
        padding: 12px 0px 12px 24px;
        height: 120px;
      }

      .v-input__prepend-inner {
        margin-left: 24px;
        margin-top: 18px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .v-input__append-inner {
        margin-right: 20px;
        margin-top: 18px;
        height: 86px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .v-icon {
        path {
          fill: $partou-primary-salmon !important;
        }
      }

      .v-progress-linear.v-progress-linear--absolute{
        left: 24px !important;
        right: 24px !important;
        width: auto !important;
        bottom: 1px;
        .v-progress-linear__background {
          background-color: transparent !important;
        }
        .v-progress-linear__indeterminate {
          background-color: $partou-primary-salmon !important;
        }
      }
    }

    &.v-input--is-disabled {
      background-color: transparent;

      .v-label {
        color: $partou-primary-black-thirty !important;
        -webkit-text-fill-color: $partou-primary-black-thirty !important;
      }

      .v-input__slot {
        textarea {
          color: $partou-primary-black-thirty !important;
        }

        .v-icon {
          path {
            fill: $partou-primary-black-thirty !important;
          }
        }
      }
    }

    &.error--text {
      .v-input__slot {
        border: 1px solid $partou-primary-salmon;
      }
    }

    .v-messages__message {
      color: $partou-primary-salmon;
    }

    textarea {
      color: $partou-primary-black-ninety;
      font-size: 14px;
      line-height: 115%;
      resize: none;
    }

    &.small {
      padding-top: 0;
      margin-top: 0;

      input {
        height: 40px;
        max-height: 40px;
        min-height: 40px;
      }

      .v-label {
        padding-top: 5px;

        &.v-label--active {
          top: -3px;
        }
      }

      .error--text {
        margin-bottom: 8px !important;
      }
    }

    &.normal {
      .v-label {
        padding-top: 12px;

        &.v-label--active {
          top: -8px;
        }
      }

      .v-input__prepend-inner {
        margin-top: 21px;
      }

      .v-input__append-inner {
        margin-top: 21px;
        height: 128px;
      }

      .v-icon {
        margin-top: -2px;
      }
    }

    &.icon-left {
      input {
        padding-left: 8px;
      }

      .v-label {
        padding-left: 0;
      }
    }

    &.icon-right {
      input {
        padding-right: 10px;
      }
    }
  }
</style>
