<template>
  <div class="full-width">
    <v-data-table
      :headers="tableHeaders"
      :fixedHeader="fixedHeader"
      :items="items"
      :server-items-length="totalCount"
      :loading="isLoading"
      :options.sync="options"
      :single-expand="true"
      :expanded.sync="expandedEntities"
      :show-expand="showExpand"
      :must-sort="true"
      :loading-text="$t('table.loadingText')"
      :no-data-text="$t('table.noDataText')"
      :no-results-text="$t('table.noResultsText')"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageText: $t('table.itemsPerPageText'),
        itemsPerPageAllText: $t('table.itemsPerPageAllText'),
        pageText: $t('table.pageText'),
        itemsPerPageOptions: options.itemsPerPageOptions
      }"
      @update:options="onOptionsChanged"
      @update:expanded="onExpandedChanged"
      class="rounded-xl"
      :item-class="itemClass">
      <!-- Pass through slots from parent -->
      <!-- Note: allows the parent component to add column templates as if they were declared in this component (See: https://vuetifyjs.com/en/components/data-tables/#item) -->
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from './DataTableHeader'
import DataTableOptionsN from './DataTableOptionsN'

@Component({
  components: { }
})
export default class PartouDataTable<TEntity> extends Vue {
  @Prop({ required: true })
  items!: TEntity[]

  @Prop({ required: true })
  totalCount!: number

  @Prop({ required: true })
  headers!: DataTableHeader[]

  @Prop({ required: false, default: false })
  isLoading!: boolean

  @Prop({ required: false, default: () => { return undefined } })
  tableOptions!: DataTableOptionsN

  @Prop({ required: false, default: false })
  showExpand!: boolean

  @Prop({ required: false, default: false })
  allowEdit!: boolean

  @Prop({ required: false, default: false })
  allowDelete!: boolean

  @Prop({ required: false, default: false })
  fixedHeader!: boolean

  @Prop({ required: false, default: () => [5, 10, 50, 100, -1] })
  itemsPerPageOptions!: number[]

  @Prop({ required: false })
  itemClass!: (item: TEntity) => 'disabled-row'

  expandedEntities: TEntity[] = []
  tableHeaders: DataTableHeader[] = []
  options: DataTableOptionsN = this.tableOptions ?? { sortBy: [], sortDesc: [], page: 1, itemsPerPage: 5, itemsPerPageOptions: this.itemsPerPageOptions }

  @Watch('headers', { immediate: true })
  onHeadersChanged (): void {
    const tableHeaders = [...this.headers]
    // Translate headers
    this.tableHeaders = tableHeaders.map(header => { return { ...header, text: this.$t(header.text).toString() } })
  }

  @Emit('onFilterChanged')
  onFilterChanged () : void {
    this.setPaginationToFirstPage()
    this.collapseAll()
    this.onTableOptionsChanged()
    this.onOptionsChanged()
  }

  @Watch('tableOptions', { immediate: true })
  onTableOptionsChanged (): void {
    this.options = { ...this.options, ...this.tableOptions }
  }

  @Emit('expandedChanged')
  onExpandedChanged (): TEntity[] {
    return this.expandedEntities
  }

  @Emit('onOptionsChanged')
  onOptionsChanged (): DataTableOptionsN {
    const options: DataTableOptionsN = { ...this.options }
    if (options.itemsPerPage === -1) {
      options.itemsPerPage = undefined
    }

    options.sortBy = options.sortBy.map(sortBy => {
      const matchingHeader = this.tableHeaders.find(x => x.value === sortBy)
      return matchingHeader?.sortBy || sortBy
    })

    return options
  }

  collapseAll () {
    this.expandedEntities = []
  }

  setPaginationToFirstPage (): void {
    this.tableOptions.page = 1
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';
  th {
    font-weight: 500;
  }

  .disabled-row {
    background-color: rgba($partou-grey-lightened, 0.45 ) !important;
  }

  .disabled-row-opacity {
    opacity: 50% !important;
  }

  tbody {
    tr:nth-child(odd) {
      td {
        background-color: $partou-background;
      }
    }
    tr td {
      border-bottom: none !important;
    }
  }

  .v-data-table__expanded__row {
    height: 80px;
    box-shadow: 0px -20px 30px -20px rgba(0, 0, 0, 0.1);
    td {
      background-color: white !important;
    }
  }
  .v-data-table__expanded__content {
    box-shadow: none !important;
    td {
      background-color: white !important;
      box-shadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.1) !important;
      position: relative;
      z-index: 1;
    }
  }
  .v-data-footer {
    font-size: $body-font-size !important;
    .v-data-footer__select .v-select__selections .v-select__selection--comma {
      font-size: $body-font-size !important;
      margin-left: 8px;
    }
    .v-icon {
      color: $partou-primary-salmon;
    }
    .v-menu::after {
      border-color: $partou-primary-salmon;
    }
  }
</style>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
  .full-width {
    width: 100%;
  }
</style>
