export default interface ISnackbarState {
  content: string | null
  timeout: number
  targetPath?: string
  visible: boolean
}

export interface IShowSnackbarPayload {
  content: string
  timeout: number
  targetPath?: string
}

export const STATE = {
  content: 'content',
  timeout: 'timeout',
  targetPath: 'targetPath',
  visible: 'visible'
}

export const state: ISnackbarState = {
  content: null,
  timeout: 3000,
  targetPath: undefined,
  visible: false
}
