import { Module } from 'vuex'
import IState from '@/store/IState'
import { actions, ACTIONS } from './snackbarActions'
import { MUTATIONS, snackbarMutations } from './snackbarMutations'
import ISnackbarState, { STATE } from './ISnackbarState'

export const snackbarState: ISnackbarState = {
  content: null,
  timeout: 3000,
  visible: false
}

export function module (): Module<ISnackbarState, IState> {
  return {
    namespaced: true,
    state: snackbarState,
    mutations: snackbarMutations,
    actions: actions()
  }
}

export const NAMESPACE = 'snackbar'
export { ACTIONS }
export { MUTATIONS }
export { STATE }
