import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import ISnackbarState, { IShowSnackbarPayload } from './ISnackbarState'
import { MUTATIONS } from './snackbarMutations'

export const ACTIONS = {
  showSnackbar: 'showSnackbar',
  hideSnackbar: 'hideSnackbar'
}

export function actions (): ActionTree<ISnackbarState, IState> {
  return {
    showSnackbar ({ commit }, payload: IShowSnackbarPayload) {
      commit(MUTATIONS.showSnackbar, payload)
    },
    hideSnackbar ({ commit }) {
      commit(MUTATIONS.hideSnackbar)
    }
  }
}
