<template>
  <div class="button-row data-component">
    <PartouFloatingCircleButton
      ref="analysis-button"
      class="analysis-button"
      icon-name="$vuetify.icons.partouAnalysis"
      @click="onGoToAnalysisResultPageClick"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import { Booking } from '@/models'
import { ROUTES } from '@/router/routes'

@Component({
  components: {
    PartouFloatingCircleButton
  }
})
export default class ActionButtons extends Vue {
  @Prop({ required: true })
  booking!: Booking

  onGoToAnalysisResultPageClick () : void {
    if (this.booking.analysis_id == null) {
      throw new Error(`Booking with id ${this.booking.id} contains no service_id`)
    }

    const routeData = this.$router.resolve({ name: ROUTES.serviceAnalysisPreview, params: { analysisResultId: this.booking.analysis_id } })
    window.open(routeData.href, '_blank')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.analysis-button {
  width: 35px;
  height: 35px;
  button span svg {
    path {
      fill: $partou-primary-black-ninety !important;
      color: $partou-primary-black-ninety !important;
    }
  }
}

.button-row > * {
  margin-right: 16px;
}

.button-row > *:last-child {
  margin-right: 0;
}
</style>
