import { IsFeatureEnabledDocument, IsFeatureEnabledQuery } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import Permission from '@/models/enums/Permission'
import { IFeatureService } from './IFeatureService'

@injectable()
export default class FeatureService implements IFeatureService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async isFeatureEnabled (featureName: string) : Promise<boolean> {
    const result = await this.apollo.query<IsFeatureEnabledQuery>({
      query: IsFeatureEnabledDocument,
      variables: { featureName },
      context: { headers: { 'X-Hasura-Role': Permission.GetSetting } }
    })

    return result.data.isFeatureEnabled?.isEnabled ?? false
  }
}
